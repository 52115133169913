import React, {useEffect, useState} from "react";

const Error = (props: ErrorProps) => {

    const [serviceError, setServiceError] = useState(props.error)

    useEffect(() => {
        setServiceError(props.error);
    }, [props])

    const dismissError = () => {
        setServiceError("");
    }

    return (
        <React.Fragment>
            {
                serviceError.length > 0 &&
                <div className="error-position-wrapper fadeIn">
                    <div className="text-center h-100 loader-wrapper">
                        <div className="loader-position-wrapper">
                            <div className="loader">
                                <div className="loading-cube error"/>
                                <div className="loading-cube error"/>
                            </div>
                            <p className="uppercasify loader-text">
                                {serviceError}
                            </p>
                            <a
                                onClick={dismissError}
                                className="interactive-text green-text button-text loader-text pt-4"
                            >
                                Dismiss
                            </a>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Error;

export interface ErrorProps {
    error: string;
}
