import React, {useState} from "react";
import {RdmInfo} from "../../../api/x-control";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {DataManagerApiLevelType} from "../../Dropdown/dropdownUtils";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import {useModal} from "../../Modal/hooks/useModal";
import {useDispatch} from "react-redux";
import {deleteDataManagerOnService} from "../../../store/RdmInfo/actions/RdmInfoActions";

const SiteManagementSiteTableItem = (props: SiteManagementSiteTableItemProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    const viewDataManager = () => {
        const editDmRoute = `${history.location.pathname}${routeNames.siteManagementDataManagerEdit.path}/${props.rdm.id}`

        history.push({
            pathname: editDmRoute,
            state: {
                from: history.location.pathname
            }
        })
    }

    const deleteDataManager = () => {
        setModalProps({
            headerText: "Delete DM",
            messages: [`Are you sure you want to delete ${props.rdm.name}?`],
            onConfirm: () => {
                dispatch(deleteDataManagerOnService(props.rdm.id, props.siteId, emptySiteCallback))
            },
            cancelText: "No",
            confirmText: "Yes",
            modalSize: ModalSize.small
        })

        toggle();
    }

    const emptySiteCallback = () => {
        history.push(routeNames.siteManagementSite.path);
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.rdm.name}
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.rdm.hostname}
                    </p>
                </div>
                <div className="col-md-1 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.rdm.source ? props.rdm.source : "Source not defined"}
                    </p>
                </div>
                <div className="col-md-1 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        { Object.values(DataManagerApiLevelType)[props.rdm.apiLevel - 1] }
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.rdm.namespace}
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row ml-0 mr-0">
                                <a onClick={viewDataManager}>
                                    <span className="interactive-text">
                                        View Data Manager
                                    </span>
                                </a>
                            </span>
                            <span className="row mt-2 ml-0 mr-0">
                                <a onClick={deleteDataManager}>
                                    <span className="interactive-text">
                                        Delete Data Manager
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default SiteManagementSiteTableItem;

interface SiteManagementSiteTableItemProps {
    rdm: RdmInfo;
    siteId: string;
}
