import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {RdmSiteName} from "../../../api/x-control";
import {RDM_SITE_LIST_STORE_STATE} from "../actions/RdmSiteListActionTypes";

const defaultState: StoreServiceData<RdmSiteName[]> = {
    loading: false,
    error: null,
    data: []
}

const rdmSiteListReducer = (state: StoreServiceData<RdmSiteName[]> = defaultState, action: ServiceActionTypes<RdmSiteName[]>) => {
    switch (action.type) {
        case RDM_SITE_LIST_STORE_STATE.ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case RDM_SITE_LIST_STORE_STATE.LOADING:
             return {
                 ...state,
                 error: null,
                 loading: true
             }
        case RDM_SITE_LIST_STORE_STATE.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                data: action.data
            }
        default:
            return state;
    }
}

export default rdmSiteListReducer;
