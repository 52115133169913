import React, {useEffect, useState} from "react";
import {RdmSiteName} from "../../../api/x-control";
import {toArray} from "../../../utils/sortingUtils";
import SiteManagementTableItem from "./SiteManagementTableItem";
import {nanoid} from "nanoid";

const SiteManagementTableItemsWrapper = (props: RdmSiteName[]) => {
    const [siteNames, setSiteNames] = useState<RdmSiteName[]>(toArray(props));

    useEffect(() => {
        setSiteNames(toArray(props));
        if(props.length > 0) {
            setSiteNames(toArray(props));
        }
    }, [props])

    return (
        <React.Fragment>
            {
                siteNames.length > 0 && siteNames.map((el: RdmSiteName) => {
                    return <SiteManagementTableItem {...el} key={nanoid()}/>
                })
            }
            {
                siteNames.length === 0 &&
                <div className="align-middle">
                    <h5 className="d-flex justify-content-center pt-2">
                        No sites exist.
                    </h5>
                </div>
            }
        </React.Fragment>
    )
}

export default SiteManagementTableItemsWrapper;
