export const routeNames = {
    settingsGroup: {
        path: '/settings-group',
        name: 'Settings Groups',
    },
    settingsGroupCreate: {
        path: '/settings-group/create',
        name: 'Settings Groups',
    },
    settingsGroupEdit: {
        path: '/settings-group/edit',
        name: 'Settings Groups',
    },
    siteManagementList: {
        path: '/site-management',
        name: 'Site Management',
    },
    siteManagementSite: {
        path: '/site-management/site',
        name: 'Site',
    },
    siteManagementDataManagerEdit: {
        path: '/edit-dm',
        name: 'Data Manager',
    },
    siteManagementDataManagerCreate: {
        path: '/create',
        name: 'Data Manager',
    },
    versionSchedule: {
        path: '/version-schedule',
        name: 'Version Schedule'
    },
    deploymentsList: {
        path: '/deployments',
        name: 'Deployments'
    },
    deploymentEdit: {
        path: '/deployments/edit',
        name: 'Deployments'
    },
    deploymentCreate: {
        path: '/deployments/create',
        name: 'Deployments'
    },
    deploymentCopy: {
        path: '/deployments/copy',
        name: 'Deployments'
    },
    rolloutList: {
        path: '/rollouts',
        name: 'Rollouts'
    },
    rollbackList: {
        path: '/rollbacks',
        name: 'Rollbacks'
    },
    login: {
        path: '/login',
        name: 'Login'
    },
}
