import React, {useState} from "react";
import {DeploymentEntry, DeploymentState, Schedule, ScheduleDay} from "../../../../api/x-control";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import ReactTooltip from "react-tooltip";
import {nanoid} from "nanoid";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import DeploymentScheduleDay from "./DeploymentScheduleDay";
import DeploymentScheduleTime from "./DeploymentScheduleTime";

const DeploymentSchedule = (props: DeploymentEntry) => {
    const tooltipId = nanoid();
    const dayOptions = Object.keys(ScheduleDay);

    const [toggled, setToggled] = useState<boolean>(props.schedule != undefined);
    const dispatch = useDispatch();

    const toolTipContent =
        props.schedule != undefined ?
            `Schedule has been enabled for ${props.name}` :
            `Schedule has been disabled for ${props.name}`;

    const toggleSchedule = (value: boolean) => {
        setToggled(value = !value);
        let schedule: Schedule | undefined = undefined;

        if(!value) {
            schedule = undefined;
        }

        else {
            schedule = {
                hour: 0,
                min: 0,
                days: []
            }
        }

        const entry: DeploymentEntry = {
            ...props,
            schedule
        }

        dispatch(setDeployment(entry));
    }
    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col-sm-7">
                    <p className="details">
                        Schedule?
                    </p>
                </div>
                <div className="col-sm-4 pr-1">
                    <div
                        data-tip
                        data-for={tooltipId}
                        className="float-right"
                    >
                        <Toggle
                            checked={toggled}
                            onChange={() => toggleSchedule(toggled)}
                            size={ToggleSize.Small}
                            disabled={props.state !== DeploymentState.UnderConstruction}
                        />
                    </div>
                    <ReactTooltip
                        place="right"
                        id={tooltipId}
                        type="dark"
                        effect="solid"
                    >
                        <span>{toolTipContent}</span>
                    </ReactTooltip>
                </div>
            </div>
            <div className="mt-2">
                {
                    toggled && <DeploymentScheduleTime {...props} />
                }
            </div>
            <div className="mt-3">
                {
                    toggled && dayOptions.map((day: string, index: number) => {
                        return <DeploymentScheduleDay day={day} deployment={props} key={index}/>
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default DeploymentSchedule;
