import React, {useEffect, useState} from "react";
import {SettingsContainerType} from "./EditSettingsGroup/SettingsContainer";
import Toggle, {ToggleSize} from "../../Toggle/Toggle";
import {useDispatch} from "react-redux";
import {
    removeDeviceSetting,
    removeMetaMatch,
    removeSettingsMatch,
    setDeviceSettingName,
    setDeviceSettingValue,
    setMetaMatch,
    setMetaMatchName,
    setMetaMatchValue,
    setSettingsMatch, setSettingsMatchComparison,
    setSettingsMatchName,
    setSettingsMatchValue
} from "../../../store/SettingGroup/actions/SettingGroupActions";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import {useModal} from "../../Modal/hooks/useModal";
import Select from 'react-select';
import {NumericComparison} from "../../../api/x-control";
import {DDProps} from "../../../utils/sortingUtils";

const SettingRow = (props: any) => {

    const [setting, setSetting] = useState(props.data);
    const [toggled, setToggled] = useState<boolean>(props.data.inverse);
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });

    const [numericComparisonOptions, setNumericComparisonOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: "", label: ""});

    const dispatch = useDispatch();
    const { isShown, toggle } = useModal();

    useEffect(() => {
        setToggled(props.data.inverse);
        setSetting(props.data);

        const comparisonOptions = Object.values(NumericComparison);
        const options: DDProps[] = [];

        for(let i = 0; i < comparisonOptions.length; ++i) {
            options.push({
                value: comparisonOptions[i],
                label: comparisonOptions[i].split(/(?=[A-Z])+/g).join(" ")
            })
        }

        setNumericComparisonOptions(options);

        const index = options.findIndex((el: DDProps) => el.value === props.data.comparison);

        if (index < 0) {
            setSelectedOption(options[0]);
            return;
        }

        setSelectedOption(options[index]);
    }, [])

    useEffect(() => {
        setToggled(props.data.inverse);
        setSetting(props.data);

        if(isShown) {
            toggle()
        }
    }, [props])


    const changeSettingName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSetting({
            ...setting,
            name: event.target.value
        })

        switch (props.containerType) {
            case SettingsContainerType.SettingsMatch:
                dispatch(setSettingsMatchName(setting, event.target.value));
                break;
            case SettingsContainerType.MetaMatch:
                dispatch(setMetaMatchName(setting, event.target.value));
                break;
            case SettingsContainerType.DeviceSettings:
                dispatch(setDeviceSettingName(setting, event.target.value))
                break;
        }
    }

    const changeSettingValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSetting({
            ...setting,
            value: event.target.value
        })

        switch (props.containerType) {
            case SettingsContainerType.SettingsMatch:
                dispatch(setSettingsMatchValue(setting, event.target.value));
                break;
            case SettingsContainerType.MetaMatch:
                dispatch(setMetaMatchValue(setting, event.target.value));
                break;
            case SettingsContainerType.DeviceSettings:
                dispatch(setDeviceSettingValue(setting, event.target.value))
                break;
        }
    }

    const changeInverseValue = (value: boolean) => {
        setToggled(value = !value);

        setting.inverse = !toggled;

        setSetting({
            ...setting,
            inverse: setting.inverse
        })

        switch (props.containerType) {
            case SettingsContainerType.SettingsMatch:
                dispatch(setSettingsMatch(setting));
                break;
            case SettingsContainerType.MetaMatch:
                dispatch(setMetaMatch(setting));
                break;
        }
    }

    const removeSetting = () => {
        setModalProps({
            headerText: "Delete Setting",
            messages: [`Are you sure you want to delete ${props.data.name}?`],
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
                switch (props.containerType) {
                    case SettingsContainerType.SettingsMatch:
                        dispatch(removeSettingsMatch({
                            index: props.index
                        }));
                        break;
                    case SettingsContainerType.MetaMatch:
                        dispatch(removeMetaMatch({
                            index: props.index
                        }));
                        break;
                    case SettingsContainerType.DeviceSettings:
                        dispatch(removeDeviceSetting({
                            index: props.index
                        }));
                        break;
                }
            },
            modalSize: ModalSize.small
        })

        toggle();
    }

    const handleComparisonChange = (p: DDProps) => {
        setSelectedOption(p);

        setSetting({
            ...setting,
            comparison: p.value.toString()
        })

        dispatch(setSettingsMatchComparison(setting, p.value.toString()))
    }


    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 pt-2 pb-3">
                <div className="col-md-10 pr-0 pl-0">
                    <div className="row ml-0 mr-0">
                        <div className="col-md-6">
                            <div className="row ml-0 mr-0">
                                <p className="pl-2 pb-1">
                                    Name
                                </p>
                            </div>
                            <div className="row ml-0 mr-0">
                                <input
                                    type="text"
                                    className="input-field w-100 ml-2"
                                    value={setting.name}
                                    onChange={changeSettingName}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row ml-0 mr-0">
                                <p className="pl-2 pb-1">
                                    Value
                                </p>
                            </div>
                            <div className="row ml-0 mr-0">
                                <input
                                    type="text"
                                    className="input-field w-100 ml-2"
                                    value={setting.value}
                                    onChange={changeSettingValue}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        props.containerType !== SettingsContainerType.DeviceSettings &&
                        <div className="row ml-0 mr-0">
                            {
                                props.containerType === SettingsContainerType.SettingsMatch &&
                                    <React.Fragment>
                                        <div className="col-md-6">
                                            <div className="row ml-0 mr-0">
                                                <p className="pl-2 pb-1">
                                                    Inverse
                                                </p>
                                            </div>
                                            <div className="row ml-0 mr-0">
                                                <Toggle
                                                    checked={setting.inverse}
                                                    onChange={() => changeInverseValue(setting.inverse)}
                                                    size={ToggleSize.Large}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row ml-0 mr-0">
                                                <p className="pl-2 pb-1">
                                                    Comparison
                                                </p>
                                            </div>
                                            <div className="row ml-0 mr-0">
                                                <Select
                                                    className="ml-2 search-bar"
                                                    classNamePrefix="select-options"
                                                    options={numericComparisonOptions}
                                                    onChange={handleComparisonChange}
                                                    isSearchable={false}
                                                    value={selectedOption}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                props.containerType === SettingsContainerType.MetaMatch &&
                                    <React.Fragment>
                                        <div className="col-md-6">
                                            <div className="row ml-0 mr-0">
                                                <p className="pl-2 pb-1">
                                                    Inverse
                                                </p>
                                            </div>
                                            <div className="row ml-0 mr-0">
                                                <Toggle
                                                    checked={setting.inverse}
                                                    onChange={() => changeInverseValue(setting.inverse)}
                                                    size={ToggleSize.Large}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6" />
                                    </React.Fragment>
                            }
                        </div>
                    }
                </div>
                <div className="col-md-2 d-flex">
                    <div className="pt-1 align-self-center ml-auto justify-content-end pr-4">
                        <a onClick={removeSetting}>
                            <svg
                                className="bin-button button-base"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                x="0px"
                                y="0px"
                            >
                                <path
                                    fill="currentColor"
                                    d="M443,144.34H256v0q-94.5,0-189-.07c-6.17,0-8.86.83-7.58,8.44,3.74,22.11,6.52,
                                    44.39,9.61,66.6Q75.53,265.56,82,311.85c4.17,29.61,8.52,59.21,12.66,88.82,4.7,
                                    33.58,9.24,67.17,13.89,100.75,1,7.25,4.7,10.59,12.61,10.57q134.72-.31,269.43-0.14c8.8,
                                    0,11.62-2.39,12.86-11.07q4.65-32.57,9.12-65.16c6.12-44.45,12.11-88.91,18.31-133.35q10.34-74.05,
                                    20.84-148.07C453.1,144.34,452.89,144.34,443,144.34Z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M471.39,116.69c-0.5-9.48,0-19-.15-28.52-0.15-12.21-5.22-22.38-16-27.78a57.92,57.92,0,0,
                                    0-23.56-5.66c-23.75-.72-47.53-0.39-71.3-0.18-5.62,0-7.84-1.34-7.9-7.55C352.22,21,330.81.13,304.83,
                                    0.07q-49.17-.13-98.34,0c-25.07.07-46.78,21.06-47.11,46-0.09,6.61-2,8.59-8.59,8.51-24.52-.3-49-0.2-73.56,
                                    0-23,.2-36.64,14.27-36.61,37.21,0,7.51,0,15,0,22.52,0,10.09,0,10.11,10.32,10.12H255v0c69,0,138.71-.06,
                                    208,0.11C469,124.55,471.73,123.24,471.39,116.69Z"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        cancelText={modalProps.cancelText}
                        confirmText={modalProps.confirmText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default SettingRow;
