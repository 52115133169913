import React from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import {useModal} from "../../../Modal/hooks/useModal";
import Modal, {ModalSize} from "../../../Modal/components/Modal";
import EditDeviceForm from "./EditDeviceForm";

const EditDeviceAction = (props: RdmDeviceInfo) => {

    const { isShown, toggle } = useModal();

    return (
        <React.Fragment>
            <span className="row ml-0 mb-1 mr-0">
                <a onClick={toggle}>
                    <span className="interactive-text">
                        Edit Device
                    </span>
                </a>
            </span>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText="Edit Device"
                modalSize={ModalSize.medium}
                modalContent={
                    <EditDeviceForm
                        rdmDeviceInfo={props}
                        onClose={toggle}
                        isNewDevice={false}
                    />
                }
            />
        </React.Fragment>
    )
}

export default EditDeviceAction;
