import {DEPLOYMENT_STORE_STATE, DeploymentDispatchTypes} from "./DeploymentActionTypes";
import {DeploymentEntry, DeploymentState} from "../../../api/x-control";
import {Dispatch} from 'redux';
import {deleteData, fetchData, saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";
import Store from "../../Store";

/** Save deployment to the service. Save (id = 0) or update (id > 0) */
export const saveDeploymentOnService = (deployment: DeploymentEntry, callback: () => void) => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    try {
        const success = await saveData(
            DEPLOYMENT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().saveDeployment(deployment)
        )

        if(success) {
            callback();
        }
    }

    catch (e) {
        dispatch({
            type: DEPLOYMENT_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

/** Delete a specific deployment via ID from the service */
export const deleteDeploymentOnService = (id: number, successCallback: () => void) => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    try {
        const success = await deleteData(
            DEPLOYMENT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().deleteDeployment(id)
        )

        if(success) {
            successCallback();
        }
    }

    catch (e) {
        dispatch({
            type: DEPLOYMENT_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

/** Get a specific deployment via ID from the service */
export const getDeploymentOnService = (id: number) => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    try {
        await fetchData(
            DEPLOYMENT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getDeployment(id)
        )
    }
    catch (e) {
        dispatch({
            type: DEPLOYMENT_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

/** Copy an existing deployment */

export const copyDeploymentFromService = (id: number) => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    try {
        const success = await fetchData(
            DEPLOYMENT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getDeployment(id)
        )

        if(success) {
            const deployment = Store.getState().deployment.data;
            if(!deployment) return;

            const deploymentName = `${deployment.name} **COPY**`

            const entry: DeploymentEntry = {
                ...deployment,
                id: 0,
                name: deploymentName,
                approvalUsers: [],
                approved: false,
                comments: "",
                state: DeploymentState.UnderConstruction
            }

            dispatch({
                type: DEPLOYMENT_STORE_STATE.SUCCESS,
                loading: false,
                error: null,
                data: entry
            })
        }
    }
    catch (e) {
        dispatch({
            type: DEPLOYMENT_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

/** Create a blank deployment */
export const createNewDeployment = () => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    dispatch({
        type: DEPLOYMENT_STORE_STATE.LOADING,
        loading: true,
        error: null,
    })

    const deploymentEntry: DeploymentEntry = {
        id: 0,
        name: "New Deployment",
        settingsGroups: [],
        approvalUsers: [],
        comments: '',
        reusable: false,
        approved: false,
        schedule: undefined,
        delayExecution: undefined,
        state: DeploymentState.UnderConstruction
    }

    //Mimics api loading time
    setTimeout(() => {
        dispatch({
            type: DEPLOYMENT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: deploymentEntry
        })
    }, 125)
}

/** Updating deployment state locally (local manipulations) */
export const setDeployment = (deployment: DeploymentEntry) => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    dispatch({
        type: DEPLOYMENT_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: deployment
    })
}

/** Nullify the store. Used to help reset state */
export const nullifyDeploymentStore = () => async (dispatch: Dispatch<DeploymentDispatchTypes>) => {
    dispatch({
        type: DEPLOYMENT_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: null
    })
}
