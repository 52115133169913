import React, {useState} from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import DeviceMeta from "./DeviceMeta";
import DeleteDeviceAction from "./DeleteDeviceAction";
import EditDeviceAction from "./EditDeviceAction";

const DataManagerDeviceTableItem = (props: RdmDeviceInfo) => {

    const [controlText, setControlText] = useState("Expand...");
    const [toggled, setToggled] = useState(false);

    const toggleRowView = (value: boolean) => {
        setToggled(value = !value);

        const expandCollapse = toggled ? 'Expand...' : 'Collapse...';

        setControlText(expandCollapse);
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-md-3 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.deviceName}
                    </p>
                </div>
                <div className="col-md-6 d-flex">
                    {
                        toggled && <DeviceMeta {...props}/>
                    }
                    {
                        !toggled &&
                        <p className="table-values align-self-center">
                            (...)
                        </p>
                    }

                </div>
                <div className="col-md-3 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <EditDeviceAction {...props} />
                            <DeleteDeviceAction {...props} />
                            <span className="row ml-0 mr-0">
                                <a onClick={() => toggleRowView(toggled)}>
                                    <span className="interactive-text">
                                        {controlText}
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator"/>
        </React.Fragment>
    )
}

export default DataManagerDeviceTableItem;
