import {ROLLOUT_REPORT_STORE_STATE, RolloutReportDispatchTypes} from "./RolloutReportActionTypes";
import {Dispatch} from 'redux';
import ApiModel from "../../ApiModel/ApiModel";
import {saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import {LogReportRequest} from "../../../api/x-control";
import Store from "../../Store";

export const getReportForRollout = (request: LogReportRequest) => async (dispatch: Dispatch<RolloutReportDispatchTypes>) => {
    try {
        const success = await saveData(
            ROLLOUT_REPORT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getRolloutCsvLink(request)
        )

        if(success) {
            const reportLink = Store.getState().rolloutReport.data;
            if(!reportLink) return;

            return reportLink;
        }
    }

    catch (e) {
        dispatch({
            type: ROLLOUT_REPORT_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}
