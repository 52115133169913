import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {RdmInfo} from "../../../api/x-control";

export const RDM_INFO_STORE_STATE: StoreState = {
    LOADING: "RDM_INFO_LOADING",
    ERROR: "RDM_INFO_ERROR",
    SUCCESS: "RDM_INFO_SUCCESS"
}

interface RdmInfoLoading extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_STORE_STATE.LOADING;
}
interface RdmInfoError extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_STORE_STATE.LOADING;
}
interface RdmInfoSuccess extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_STORE_STATE.LOADING;
}

export type RdmInfoDispatchTypes = RdmInfoSuccess | RdmInfoError | RdmInfoLoading;
