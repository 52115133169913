import React, {useEffect} from "react";
import Modal, {ModalSize} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import EditSettingsGroupContainer from "../EditSettingsGroup/EditSettingsGroupContainer";
import WithServiceState from "../../../Helpers/HOCs/ServiceState/WithServiceState";
import {getSettingGroupById, nullifySettingGroup} from "../../../../store/SettingGroup/actions/SettingGroupActions";

const WrappedSettingsGroupContainer = WithServiceState(EditSettingsGroupContainer);


const EditSettingsGroupModal = (props: any) => {

    const {isShown, toggle} = useModal();
    const id = props.match.params.id;
    const dispatch = useDispatch();
    const settingsGroupStoreState = useSelector((state: RootStore) => state.settingGroup);
    const history = useHistory();

    useEffect(() => {
        dispatch(nullifySettingGroup());
        toggle();
        fetchData();
    }, [])


    const fetchData = () => {
        dispatch(getSettingGroupById(id));
    }

    const closeModal = () => {
        const routerState = history.location.state as any;
        history.replace(routerState.from);
    }

    return(
        <Modal
            isShown={isShown}
            hide={() => closeModal()}
            headerText="EDIT SETTINGS GROUP"
            modalSize={ModalSize.large}
            modalContent={
                <WrappedSettingsGroupContainer
                    {...settingsGroupStoreState}
                />
            }
        />
    )
}

export default EditSettingsGroupModal;
