import React, {useState} from "react";
import {DeploymentEntry, DeploymentState} from "../../../../api/x-control";
import {nanoid} from "nanoid";
import {useDispatch} from "react-redux";
import moment, {Moment} from "moment";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import ReactTooltip from "react-tooltip";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import DeploymentDelayExecutionDate from "./DeploymentDelayExecutionDate";

const DeploymentDelayExecution = (props: DeploymentEntry) => {
    const tooltipId = nanoid();

    const [toggled, setToggled] = useState<boolean>(props.delayExecution != undefined);
    const dispatch = useDispatch();

    const toolTipContent =
        props.delayExecution != undefined ?
            `${props.name} execution delay has been set to ${moment.unix(props.delayExecution).format('LLL')}` :
            `${props.name} execution will run as normal`;

    const toggleDelayExecution = (value: boolean) => {
        let executionDate: number | undefined = undefined;

        setToggled(value = !value);

        if(!value) {
            executionDate = undefined;
        }
        else {
            executionDate = 0
        }

        const entry: DeploymentEntry = {
            ...props,
            delayExecution: executionDate
        }

        dispatch(setDeployment(entry));
    }

    const roundToNearestXXTimeValue = (m: Moment, n: number, unit: any) => {
        const units: any[] = [
            'year',
            'month',
            'hour',
            'minute',
            'second',
            'millisecond',
        ];

        const pos = units.indexOf(unit);
        if (pos < 0) {
            throw new Error('Unsupported unit');
        }
        for (let i = pos + 1; i < units.length; i++) {
            m.set(units[i], units[i] === 'millisecond' ? 999 : 59);
        }
        m.set(unit, Math.floor(m.get(unit) / n) * n + n - 1);

        return m;
    }


    return(
        <React.Fragment>
            <div className="row mt-3">
                <div className="col-sm-7">
                    <p className="details">
                        Delay Execution?
                    </p>
                </div>
                <div className="col-sm-4 pr-1">
                    <div
                        data-tip
                        data-for={tooltipId}
                        className="float-right"
                    >
                        <Toggle
                            checked={toggled}
                            onChange={() => toggleDelayExecution(toggled)}
                            size={ToggleSize.Small}
                            disabled={props.state !== DeploymentState.UnderConstruction}
                        />
                    </div>
                    <ReactTooltip
                        place="right"
                        id={tooltipId}
                        type="dark"
                        effect="solid"
                    >
                        <span>{toolTipContent}</span>
                    </ReactTooltip>
                </div>
            </div>
            {
                toggled &&
                    <DeploymentDelayExecutionDate {...props}/>
            }
        </React.Fragment>
    )
}

export default DeploymentDelayExecution;
