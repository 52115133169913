import React, {useEffect, useState} from "react";
import {SettingsGroup} from "../../../../api/x-control";
import {useDispatch} from "react-redux";
import SettingsContainer, {SettingsContainerProps, SettingsContainerType} from "./SettingsContainer";
import {saveSettingGroup, setSettingsGroupName} from "../../../../store/SettingGroup/actions/SettingGroupActions";
import {useHistory} from 'react-router-dom'
import {useModal} from "../../../Modal/hooks/useModal";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";
import {getSettingsGroups} from "../../../../store/SettingsGroups/actions/SettingsGroupsActions";

const EditSettingsGroupContainer = (props: SettingsGroup) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [settingsGroup, setSettingsGroup] = useState<SettingsGroup>(props);
    const [settingsContainers, setSettingsContainer] = useState<SettingsContainerProps[]>([
        {
            containerType: SettingsContainerType.SettingsMatch,
            containerName: "Settings Matches",
            data: props.settingsMatches
        },
        {
            containerType: SettingsContainerType.MetaMatch,
            containerName: "Meta Matches",
            data: props.metaMatches
        },
        {
            containerType: SettingsContainerType.DeviceSettings,
            containerName: "Settings",
            data: props.settings
        }
    ])
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    //Update container based on props updating from store.
    useEffect(() => {
        setSettingsContainer([
            {
                containerType: SettingsContainerType.SettingsMatch,
                containerName: "Settings Matches",
                data: props.settingsMatches
            },
            {
                containerType: SettingsContainerType.MetaMatch,
                containerName: "Meta Matches",
                data: props.metaMatches
            },
            {
                containerType: SettingsContainerType.DeviceSettings,
                containerName: "Settings",
                data: props.settings
            }
        ])
    }, [props])

    const changeSettingsGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingsGroup({
            ...props,
            name: event.target.value,
        })

        dispatch(setSettingsGroupName(event.target.value));
    }

    const save = () => {
        dispatch(saveSettingGroup(() => {
            //On successful save, dispatch to get new settings
            setTimeout(() => {
                dispatch(getSettingsGroups());
                navigateToPreviousPage();
            }, 125)
        }))
    }

    const cancel = () => {
        setModalProps({
            headerText: "Settings Group",
            messages: [`There are unsaved changes. Are you sure you want to continue?`],
            onConfirm: () => navigateToPreviousPage(),
            confirmText: "Yes",
            cancelText: "No",
            modalSize: ModalSize.small
        })

        toggle();
    }

    //Making sure to navigate back to the correct page when pressing back as this page can be opened from 2 places.
    const navigateToPreviousPage = () => {
        const routerState = history.location.state as any;
        history.replace(routerState.from);
    }

    return(
        <div className="h-100">
            <div className="row ml-0">
                <p className="uppercasify table-keys pl-0 pb-0">
                    Settings Group Name
                </p>
            </div>
            <div className="row ml-0">
                <input
                    type="text"
                    className="input-field w-25 ml-0"
                    value={settingsGroup.name}
                    onChange={changeSettingsGroupName}
                />
            </div>
            <div className="row settings-container-contents-wrapper">
                {
                    settingsContainers.map((el: SettingsContainerProps, index: number) => {
                        return <SettingsContainer {...el} key={index} />
                    })
                }
            </div>
            <div className="float-right">
                <a onClick={cancel} className="interactive-text pr-3">
                    <span className="pl-2">Cancel</span>
                </a>
                <span className="red-text pr-3">
                    |
                </span>
                <a onClick={save} className="interactive-text">
                    <span className="pl-2">Save</span>
                </a>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        cancelText={modalProps.cancelText}
                        confirmText={modalProps.confirmText}
                    />
                }
            />
        </div>
    )
}

export default EditSettingsGroupContainer;
