import React, {useCallback, useEffect, useState} from "react";
import {RdmInfo} from "../../../../api/x-control";
import {useDispatch} from "react-redux";
import {setDataManager} from "../../../../store/RdmInfo/actions/RdmInfoActions";
import ApiLevelDropdown from "../../../Dropdown/GenericDropdown/ApiLevelDropdown";
import {DataManagerApiLevelType} from "../../../Dropdown/dropdownUtils";

const DataManagerDetails = (props: RdmInfo) => {
    const [apiLevel, setApiLevel] = useState<number>(props.apiLevel)
    const dispatch = useDispatch();

    /** Update DM Name */
    const changeDMName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            name: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Site Name */
    const changeSiteName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            siteName: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Host Name */
    const changeHostName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            hostname: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Namespace */
    const changeNamespace = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            namespace: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Api Level */
    const changeApiLevel = useCallback(
        (id: string | number) => {
            setApiLevel(+id);
        }, [apiLevel]
    )

    useEffect(() => {
        const dm: RdmInfo = {
            ...props,
            apiLevel
        }
        dispatch(setDataManager(dm));
    }, [apiLevel])

    /** Update Source */
    const changeSource = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            source: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Username */
    const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            username: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    /** Update Password */
    const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const dm: RdmInfo = {
            ...props,
            password: event.target.value
        }
        dispatch(setDataManager(dm));
    }

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        DM Name
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.name}
                        onChange={changeDMName}
                    />
                    {
                        props.name.length < 1 &&
                        <div className="error-text pl-3">
                            DM Name field is required
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Site Name
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.siteName}
                        onChange={changeSiteName}
                    />
                    {
                        props.siteName.length < 1 &&
                        <div className="error-text pl-3">
                            Site Name field is required
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Host Name
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.hostname}
                        onChange={changeHostName}
                    />
                    {
                        props.hostname.length < 1 &&
                        <div className="error-text pl-3">
                            Host Name field is required
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Namespace
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.namespace}
                        onChange={changeNamespace}
                    />
                    {
                        props.namespace.length < 1 &&
                        <div className="error-text pl-3">
                            Namespace field is required
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Api Level
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col pl-4">
                    <ApiLevelDropdown
                        enumOptions={DataManagerApiLevelType}
                        changeOption={changeApiLevel}
                        id={apiLevel}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Source
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.source}
                        onChange={changeSource}
                    />
                    {
                        !props.source &&
                        <div className="error-text pl-3">
                            Source field is required
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Username
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={props.username}
                        onChange={changeUsername}
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Password
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input
                        type="password"
                        className="input-field lg mr-4"
                        value={props.password}
                        onChange={changePassword}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DataManagerDetails;
