import React, {useEffect, useState} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useHistory} from 'react-router-dom'
import {useDrop} from "react-dnd";
import {DragAndDropContainerProps} from "../../DragAndDrop/DragAndDropUtils";
import {DeploymentEntry, DeploymentState, SettingsGroup} from "../../../api/x-control";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {useModal} from "../../Modal/hooks/useModal";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import {getSettingGroupById} from "../../../store/SettingGroup/actions/SettingGroupActions";
import {
    deleteDeploymentOnService,
    saveDeploymentOnService,
    setDeployment
} from "../../../store/Deployment/actions/DeploymentActions";
import DeploymentGroupSettingsOptions from "./DeploymentGroupSettingsOptions";
import {setCard} from "../../../store/Card/actions/CardActions";
import {SettingGroupCardActionEnum} from "../../../store/Card/actions/CardActionTypes";
import {deployDeploymentById} from "../../../store/Deploy/actions/DeployActions";

const DeploymentGroupSettings = (props: DragAndDropContainerProps) => {

    /** Redux */
    const dispatch = useDispatch();
    const deployStore = useSelector((state: RootStore) => state.deploy);

    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    /** Drag and Drop */
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: props.dustbin.accept,
        drop: props.dustbin.onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    /** State */
    const [cardSettings, setCardSettings] = useState<SettingsGroup[]>(props.deployment.settingsGroups);

    /** React Router */
    const history = useHistory();

    /** Card sorting */
    useEffect(() => {
        setCardSettings(props.deployment.settingsGroups);
    }, [props])

    /** Settings Group Drop */
    useEffect(() => {
        //IIFE - Immediately Invoked Function Expression
        //https://medium.com/javascript-in-plain-english/https-medium-com-javascript-in-plain-english-stop-feeling-iffy-about-using-an-iife-7b0292aba174
        (async function handleDrop() {
            const { lastDroppedItem } = props.dustbin;
            if (lastDroppedItem) {
                const index = cardSettings.findIndex((el: SettingsGroup) => lastDroppedItem.id === el.id);

                //Exists in settings groups fail silently.
                if (index > -1) {
                    return;
                }

                const id = lastDroppedItem.id.toString();

                //Get settings group by id.
                // @ts-ignore
                const settingsGroup: SettingsGroup = await dispatch(getSettingGroupById(id));

                if(!settingsGroup) {
                    setModalProps({
                        headerText: 'Settings Group Error',
                        messages: ['Could not get settings group, please try again'],
                        cancelText: "Dismiss",
                        confirmText: "",
                        onConfirm: () => null,
                        modalSize: ModalSize.small
                    })
                    toggle();

                    const sGroup: SettingsGroup = {
                        id,
                        name: "",
                        type: "",
                        settings: [],
                        settingsMatches: [],
                        metaMatches: []
                    }

                    //Return the card back to the side.
                    dispatch(setCard({
                        ...sGroup,
                        action: SettingGroupCardActionEnum.Removed
                    }))
                    return;
                }
                //Push to deployment
                const entry: DeploymentEntry = {
                    ...props.deployment,
                    settingsGroups: [...props.deployment.settingsGroups, settingsGroup]
                }
                dispatch(setDeployment(entry));
            }
        })();

    }, [props.dustbin.lastDroppedItem])

    const deleteDeployment = () => {
        toggle();
        setModalProps({
            headerText: "Delete Deployment?",
            messages: [`Are you sure you want to delete ${props.deployment.name}?`],
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
                toggle();
                dispatch(deleteDeploymentOnService(props.deployment.id, () => {
                    backToDeploymentsList()
                }))
            },
            modalSize: ModalSize.small
        })
    }

    const saveDeployment = () => {
        dispatch(saveDeploymentOnService(props.deployment,() => {
            setTimeout(() => {
                backToDeploymentsList()
            }, 125)
        }));
    }

    const cancel = () => {
        setModalProps({
            headerText: "Back To Deployment List",
            messages: [`There are unsaved changes. Are you sure you want to leave?`],
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => backToDeploymentsList(),
            modalSize: ModalSize.small
        })

        toggle()
    }

    const deployDeployment = () => {
        if (isShown) {
            toggle()
        }

        if(!props.deployment.approved) {
            setModalProps({
                headerText: "Deployment Denied",
                messages: [`${props.deployment.name} has not been approved! You cannot deploy this deployment!`],
                confirmText: "",
                cancelText: "Cancel",
                onConfirm: () => null,
                modalSize: ModalSize.small
            })

            toggle();
        }

        else {
            setModalProps({
                headerText: "Deploy?",
                messages: [`Are you sure you want to deploy ${props.deployment.name}?`],
                confirmText: "Yes",
                cancelText: "No",
                onConfirm: () => {
                    dispatch(saveDeploymentOnService(props.deployment, () => {
                        dispatch(deployDeploymentById(props.deployment.id, successfulDeployment, failedToDeploy))
                    }))
                },
                modalSize: ModalSize.small
            })

            toggle();
        }
    }

    const successfulDeployment = () => {
        if (isShown) {
            toggle()
        }

        setModalProps({
            headerText: "Success",
            messages: [`${props.deployment.name} was successfully deployed! Click 'View Rollouts' to see deployment progress`],
            confirmText: "View Rollouts",
            cancelText: "Cancel",
            onConfirm: () => history.push(`${routeNames.deploymentsList.path}/${props.deployment.id}${routeNames.rolloutList.path}`),
            modalSize: ModalSize.small
        })

        toggle()
    }

    const failedToDeploy = () => {
        if (isShown) {
            toggle()
        }

        setModalProps({
            headerText: "Failed",
            messages: [`${props.deployment.name} was not able to deploy. Reason:
            ${deployStore.error}`],
            confirmText: "Retry",
            cancelText: "Cancel",
            onConfirm: () => deployDeployment(),
            modalSize: ModalSize.small
        })

        toggle()
    }

    const backToDeploymentsList = () => {
        setTimeout(() => {
            history.replace(routeNames.deploymentsList.path);
        }, 125)
    }

    return (
        <div ref={drop} className="col-md-8 pl-0 pr-0">
            <p className="column-header">
                Deployment Settings Groups
            </p>
            <div className="table-contents mt-0">
                <div className="table-contents-key">
                    <div className="row">
                        <div className="col-sm-1">
                            <p className="uppercasify table-keys">
                                Order
                            </p>
                        </div>
                        <div className="col-md-1">
                            <p className="uppercasify table-keys">
                                Name
                            </p>
                        </div>
                        <div className="col-md-4">
                            <p className="uppercasify table-keys">
                                Settings Matches
                            </p>
                        </div>
                        <div className="col-md-2">
                            <p className="uppercasify table-keys">
                                Meta Matches
                            </p>
                        </div>
                        <div className="col-md-2">
                            <p className="uppercasify table-keys">
                                Settings
                            </p>
                        </div>
                        <div className="col-md-2">
                            <p className="uppercasify table-keys">
                                Controls
                            </p>
                        </div>
                    </div>
                    <DeploymentGroupSettingsOptions {...cardSettings} />
                </div>
            </div>
            <div className="float-right">
                <a onClick={cancel} className="interactive-text pr-3">
                    <span className="pl-2">Cancel</span>
                </a>
                <span className="red-text pr-3">
                                    |
                </span>
                {
                    props.deployment.id > 0 && (
                        <React.Fragment>
                            <a onClick={deleteDeployment} className="interactive-text pr-3">
                                <span className="pl-2">Delete Deployment </span>
                            </a>
                            <span className="red-text pr-3">
                                |
                            </span>
                            {
                                props.deployment.state === DeploymentState.Active &&
                                    <React.Fragment>
                                        <a onClick={deployDeployment} className="interactive-text pr-3">
                                            <span className="pl-2">Deploy</span>
                                        </a>
                                        <span className="red-text pr-3">
                                        |
                                        </span>
                                    </React.Fragment>

                            }

                        </React.Fragment>
                    )

                }
                <a onClick={saveDeployment} className="interactive-text pr-3">
                    <span className="pl-2">Save</span>
                </a>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </div>
    )
}

export default DeploymentGroupSettings;
