import {RolloutsPageResponse} from "../../../api/x-control";
import {StoreState, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

export const ROLLOUT_LIST_STORE_STATE: StoreState = {
    LOADING: "ROLLOUT_LIST_LOADING",
    ERROR: "ROLLOUT_LIST_ERROR",
    SUCCESS: "ROLLOUT_LIST_SUCCESS"
}

export interface RolloutListLoading extends StoreServiceData<RolloutsPageResponse>{
    type: typeof ROLLOUT_LIST_STORE_STATE.LOADING
}

export interface RolloutListError extends StoreServiceData<RolloutsPageResponse>{
    type: typeof ROLLOUT_LIST_STORE_STATE.ERROR
}

export interface RolloutListSuccess extends StoreServiceData<RolloutsPageResponse>{
    type: typeof ROLLOUT_LIST_STORE_STATE.SUCCESS
}

export type RolloutListDispatchTypes = RolloutListSuccess | RolloutListError | RolloutListLoading;
