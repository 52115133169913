// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * X-Control API
 * API to access X-Control functionality
 *
 * OpenAPI spec version: 0.1-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:8080/api/v1/xcontrol".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * A user that explicitly approved a deployment
 * @export
 * @interface ApproveUser
 */
export interface ApproveUser {
    /**
     * Name of the user, not their username
     * @type {string}
     * @memberof ApproveUser
     */
    name: string;
    /**
     * Linux epoch for when they approved the deployment
     * @type {number}
     * @memberof ApproveUser
     */
    date: number;
}

/**
 * A deployment ready to go into the system, this is a canonical deployment and once approved is immutable.
 * @export
 * @interface DeploymentEntry
 */
export interface DeploymentEntry {
    /**
     * 
     * @type {number}
     * @memberof DeploymentEntry
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEntry
     */
    name: string;
    /**
     * The settings groups within this deployment, can be an empty array. These are COPIES so will not reflect changes to the main SettingsGroups library.
     * @type {Array<SettingsGroup>}
     * @memberof DeploymentEntry
     */
    settingsGroups: Array<SettingsGroup>;
    /**
     * Users that have approved this deployment, can be an empty array
     * @type {Array<ApproveUser>}
     * @memberof DeploymentEntry
     */
    approvalUsers: Array<ApproveUser>;
    /**
     * Any additional comments the user might want to attach to the deployment
     * @type {string}
     * @memberof DeploymentEntry
     */
    comments?: string;
    /**
     * Whether this deployment can be copied with approvals in-place.
     * @type {boolean}
     * @memberof DeploymentEntry
     */
    reusable: boolean;
    /**
     * Whether this deployment is approved for execution. If so the state can be changed to 'Active'.
     * @type {boolean}
     * @memberof DeploymentEntry
     */
    approved: boolean;
    /**
     * 
     * @type {Schedule}
     * @memberof DeploymentEntry
     */
    schedule?: Schedule;
    /**
     * Linux epoch for when this deployment should next be executed explicitly
     * @type {number}
     * @memberof DeploymentEntry
     */
    delayExecution?: number;
    /**
     * 
     * @type {DeploymentState}
     * @memberof DeploymentEntry
     */
    state: DeploymentState;
}

/**
 * A cut-down version of DeploymentEntry for use in lists / summaries
 * @export
 * @interface DeploymentListEntry
 */
export interface DeploymentListEntry {
    /**
     * 
     * @type {number}
     * @memberof DeploymentListEntry
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentListEntry
     */
    name: string;
    /**
     * Users that have approved this deployment, can be an empty array
     * @type {Array<ApproveUser>}
     * @memberof DeploymentListEntry
     */
    approvalUsers: Array<ApproveUser>;
    /**
     * Any additional comments the user might want to attach to the deployment
     * @type {string}
     * @memberof DeploymentListEntry
     */
    comments?: string;
    /**
     * Whether this deployment can be copied with approvals in-place.
     * @type {boolean}
     * @memberof DeploymentListEntry
     */
    reusable: boolean;
    /**
     * Whether this deployment is approved for execution. If so the state can be changed to 'Active'.
     * @type {boolean}
     * @memberof DeploymentListEntry
     */
    approved: boolean;
    /**
     * 
     * @type {Schedule}
     * @memberof DeploymentListEntry
     */
    schedule?: Schedule;
    /**
     * Linux epoch for when this deployment should next be executed explicitly
     * @type {number}
     * @memberof DeploymentListEntry
     */
    delayExecution?: number;
    /**
     * 
     * @type {DeploymentState}
     * @memberof DeploymentListEntry
     */
    state: DeploymentState;
}

/**
 * The current state of the deployment
 * @export
 * @enum {string}
 */
export enum DeploymentState {
    Active = 'Active',
    UnderConstruction = 'UnderConstruction',
    Complete = 'Complete'
}

/**
 * Request a page of deployments listed, optionally filtered to criteria
 * @export
 * @interface DeploymentsPageRequest
 */
export interface DeploymentsPageRequest {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof DeploymentsPageRequest
     */
    pageNum?: number;
    /**
     * Number of deployments to return per page
     * @type {number}
     * @memberof DeploymentsPageRequest
     */
    numPerPage?: number;
    /**
     * Optional list of states to filter the deployments to
     * @type {Array<DeploymentState>}
     * @memberof DeploymentsPageRequest
     */
    states?: Array<DeploymentState>;
}

/**
 * Page of deployments as requested
 * @export
 * @interface DeploymentsPageResponse
 */
export interface DeploymentsPageResponse {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof DeploymentsPageResponse
     */
    pageNum: number;
    /**
     * Number of deployments to return per page
     * @type {number}
     * @memberof DeploymentsPageResponse
     */
    numPerPage: number;
    /**
     * Optional list of states to filter the deployments to
     * @type {Array<DeploymentState>}
     * @memberof DeploymentsPageResponse
     */
    states: Array<DeploymentState>;
    /**
     * Total number of deployments that meet the criteria. Can be used for calculating total page count.
     * @type {number}
     * @memberof DeploymentsPageResponse
     */
    totalDeployments: number;
    /**
     * Deployments in this page
     * @type {Array<DeploymentListEntry>}
     * @memberof DeploymentsPageResponse
     */
    entries: Array<DeploymentListEntry>;
}

/**
 * Base type for a meta value on a device. Overridden by device-specific implementations.
 * @export
 * @interface DeviceMeta
 */
export interface DeviceMeta extends Polymorphic {
    /**
     * Unique name for the meta
     * @type {string}
     * @memberof DeviceMeta
     */
    name: string;
    /**
     * Value for the meta
     * @type {string}
     * @memberof DeviceMeta
     */
    value: string;
}

/**
 * Base type for a setting on a device. Overridden by device-specific implementations.
 * @export
 * @interface DeviceSetting
 */
export interface DeviceSetting extends Polymorphic {
    /**
     * Unique name for the setting on the device
     * @type {string}
     * @memberof DeviceSetting
     */
    name: string;
    /**
     * Value to set on the device
     * @type {string}
     * @memberof DeviceSetting
     */
    value?: string;
}

/**
 * Base type of a result of setting a setting on a device. Overridden by device-specific implementations.
 * @export
 * @interface DeviceSettingResult
 */
export interface DeviceSettingResult extends Polymorphic {
    /**
     * Name of the setting
     * @type {string}
     * @memberof DeviceSettingResult
     */
    name: string;
    /**
     * Whether it was successful setting this specific setting
     * @type {boolean}
     * @memberof DeviceSettingResult
     */
    success: boolean;
}

/**
 * Base type for a single value on a device. Overridden by device-specific implementations.
 * @export
 * @interface DeviceValue
 */
export interface DeviceValue {
    /**
     * Name of the value
     * @type {string}
     * @memberof DeviceValue
     */
    name: string;
    /**
     * Raw value from the device
     * @type {string}
     * @memberof DeviceValue
     */
    value?: string;
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * Match on a key / value pair, with optional inverse flag
 * @export
 * @interface GenericMatch
 */
export interface GenericMatch extends Polymorphic {
    /**
     * Name of the item to match on
     * @type {string}
     * @memberof GenericMatch
     */
    name: string;
    /**
     * Value of the item to match on
     * @type {string}
     * @memberof GenericMatch
     */
    value: string;
    /**
     * Whether the value should match (true) or NOT match (false)
     * @type {boolean}
     * @memberof GenericMatch
     */
    inverse: boolean;
}

/**
 * Required data to get a rollout log report
 * @export
 * @interface LogReportRequest
 */
export interface LogReportRequest {
    /**
     * The ID of the deployment to get the log report for
     * @type {number}
     * @memberof LogReportRequest
     */
    deploymentId: number;
    /**
     * The ID of the rollout to get the log report for
     * @type {number}
     * @memberof LogReportRequest
     */
    rolloutId: number;
}

/**
 * Match on a specific meta value
 * @export
 * @interface MetaMatch
 */
export interface MetaMatch extends GenericMatch {
}

/**
 * Result of setting multiple settings in one request
 * @export
 * @interface MultiDeviceSettingResult
 */
export interface MultiDeviceSettingResult {
    /**
     * Name of the device the settings are for
     * @type {string}
     * @memberof MultiDeviceSettingResult
     */
    deviceId: string;
    /**
     * Results of each requested setting
     * @type {Array<DeviceSettingResult>}
     * @memberof MultiDeviceSettingResult
     */
    settings: Array<DeviceSettingResult>;
}

/**
 * Possible operators between numeric values
 * @export
 * @enum {string}
 */
export enum NumericComparison {
    LessThan = 'LessThan',
    LessThanEqual = 'LessThanEqual',
    Equal = 'Equal',
    GreaterThanEqual = 'GreaterThanEqual',
    GreaterThan = 'GreaterThan'
}

/**
 * Match specifically on numeric values
 * @export
 * @interface NumericMatch
 */
export interface NumericMatch extends GenericMatch {
    /**
     * 
     * @type {NumericComparison}
     * @memberof NumericMatch
     */
    comparison?: NumericComparison;
}

/**
 * This type is extended by specific implementations
 * @export
 * @interface Polymorphic
 */
export interface Polymorphic {
    /**
     * Unique type name, decided by device-specific implementation
     * @type {string}
     * @memberof Polymorphic
     */
    type: string;
}

/**
 * Meta information for a specific device
 * @export
 * @interface RdmDeviceInfo
 */
export interface RdmDeviceInfo {
    /**
     * The name of the device as it appears in the DM
     * @type {string}
     * @memberof RdmDeviceInfo
     */
    deviceName: string;
    /**
     * Meta data for the device, as string key/value pairs
     * @type {{ [key: string]: string; }}
     * @memberof RdmDeviceInfo
     */
    meta: { [key: string]: string; };
}

/**
 * RDM implementation of DeviceMeta. Type name is 'RdmDeviceMeta'
 * @export
 * @interface RdmDeviceMeta
 */
export interface RdmDeviceMeta extends DeviceMeta {
}

/**
 * RDM implementation of DeviceSetting. Type name is 'RdmDeviceSetting'
 * @export
 * @interface RdmDeviceSetting
 */
export interface RdmDeviceSetting extends DeviceSetting {
    /**
     * Optional units for this setting
     * @type {string}
     * @memberof RdmDeviceSetting
     */
    units?: string;
}

/**
 * RDM implementation of DeviceSettingResult. Type name is 'RdmDeviceSettingResult'
 * @export
 * @interface RdmDeviceSettingResult
 */
export interface RdmDeviceSettingResult extends DeviceSettingResult {
}

/**
 * RDM implementation of DeviceValue. Type name is 'RdmDeviceValue'
 * @export
 * @interface RdmDeviceValue
 */
export interface RdmDeviceValue extends DeviceValue {
    /**
     * Optional units for the value, if available
     * @type {string}
     * @memberof RdmDeviceValue
     */
    units?: string;
}

/**
 * Information for a specific data manager
 * @export
 * @interface RdmInfo
 */
export interface RdmInfo {
    /**
     * UUID for the DM, this should be uniquely generated for each new DM prior to insert.
     * @type {string}
     * @memberof RdmInfo
     */
    id: string;
    /**
     * Name of this DM, ideally matching the name the DM refers to itself as
     * @type {string}
     * @memberof RdmInfo
     */
    name: string;
    /**
     * Name of the site, this will be converted to the Site ID so must be accurate and consistent
     * @type {string}
     * @memberof RdmInfo
     */
    siteName: string;
    /**
     * URL path to the DM, without http, etc. etc. Normally this will just be an IP address
     * @type {string}
     * @memberof RdmInfo
     */
    hostname: string;
    /**
     * Namespace as specified by the DM's web services
     * @type {string}
     * @memberof RdmInfo
     */
    namespace: string;
    /**
     * 1 = Basic, 2 = Enhanced
     * @type {number}
     * @memberof RdmInfo
     */
    apiLevel: number;
    /**
     * Name of the source for data that comes from this device. Usually just 'RDM' but can be custom depending on customer. Must match the importer source.
     * @type {string}
     * @memberof RdmInfo
     */
    source?: string;
    /**
     * Username to log in to the DM and services, if required
     * @type {string}
     * @memberof RdmInfo
     */
    username?: string;
    /**
     * Password to log in to the DM and services, if required
     * @type {string}
     * @memberof RdmInfo
     */
    password?: string;
    /**
     * Devices within the DM. Even if no meta, they must be listed here to 'work'
     * @type {Array<RdmDeviceInfo>}
     * @memberof RdmInfo
     */
    devices?: Array<RdmDeviceInfo>;
}

/**
 * RDM implementation of SettingsGroup. Type name is 'RdmSettingsGroup'
 * @export
 * @interface RdmSettingsGroup
 */
export interface RdmSettingsGroup extends SettingsGroup {
}

/**
 * Naming for a site
 * @export
 * @interface RdmSiteName
 */
export interface RdmSiteName {
    /**
     * GenX ID for the site
     * @type {string}
     * @memberof RdmSiteName
     */
    siteId: string;
    /**
     * Name of the site
     * @type {string}
     * @memberof RdmSiteName
     */
    siteName: string;
}

/**
 * Instance of a rollback, reverting a prior deployment
 * @export
 * @interface RollbackEntry
 */
export interface RollbackEntry {
    /**
     * Unique ID for this rollback
     * @type {number}
     * @memberof RollbackEntry
     */
    id: number;
    /**
     * ID of the rollout this rollback reverted
     * @type {number}
     * @memberof RollbackEntry
     */
    rolloutId: number;
    /**
     * Linux epoch for when this rollback was triggered
     * @type {number}
     * @memberof RollbackEntry
     */
    date: number;
    /**
     * 
     * @type {RolloutEntryState}
     * @memberof RollbackEntry
     */
    state: RolloutEntryState;
}

/**
 * A specific rollout of a Deployment. Once created, the rollout to devices has been triggered.
 * @export
 * @interface RolloutEntry
 */
export interface RolloutEntry {
    /**
     * Unique ID for this rollout
     * @type {number}
     * @memberof RolloutEntry
     */
    id: number;
    /**
     * The ID of the deployment this rollout is for
     * @type {number}
     * @memberof RolloutEntry
     */
    deploymentId: number;
    /**
     * Linux epoch for when the rollout was first triggered
     * @type {number}
     * @memberof RolloutEntry
     */
    date?: number;
    /**
     * 
     * @type {RolloutEntryState}
     * @memberof RolloutEntry
     */
    state: RolloutEntryState;
}

/**
 * Current status of a rollout
 * @export
 * @enum {string}
 */
export enum RolloutEntryState {
    Pending = 'Pending',
    InProgress = 'InProgress',
    Deployed = 'Deployed',
    Error = 'Error'
}

/**
 * Listing view of a rollout entry
 * @export
 * @interface RolloutListEntry
 */
export interface RolloutListEntry {
    /**
     * Unique ID for this rollout
     * @type {number}
     * @memberof RolloutListEntry
     */
    id: number;
    /**
     * The ID of the deployment this rollout is for
     * @type {number}
     * @memberof RolloutListEntry
     */
    deploymentId: number;
    /**
     * Name of the deployment this rollout is for
     * @type {string}
     * @memberof RolloutListEntry
     */
    deploymentName: string;
    /**
     * Linux epoch for when the rollout was first triggered
     * @type {number}
     * @memberof RolloutListEntry
     */
    date?: number;
    /**
     * 
     * @type {RolloutEntryState}
     * @memberof RolloutListEntry
     */
    state: RolloutEntryState;
}

/**
 * Request a page of rollouts that match the provided criteria
 * @export
 * @interface RolloutsPageRequest
 */
export interface RolloutsPageRequest {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof RolloutsPageRequest
     */
    pageNum?: number;
    /**
     * Number of rollouts to return per page
     * @type {number}
     * @memberof RolloutsPageRequest
     */
    numPerPage?: number;
    /**
     * Optional restriction to return rollouts just for this deployment
     * @type {number}
     * @memberof RolloutsPageRequest
     */
    deploymentId?: number;
    /**
     * Optional restriction to return rollouts started after this date, inclusive
     * @type {number}
     * @memberof RolloutsPageRequest
     */
    minDateInclusive?: number;
    /**
     * Optional restriction to return rollouts that started prior to this date, exclusive
     * @type {number}
     * @memberof RolloutsPageRequest
     */
    maxDateExclusive?: number;
}

/**
 * Page of rollouts as requested
 * @export
 * @interface RolloutsPageResponse
 */
export interface RolloutsPageResponse {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    pageNum: number;
    /**
     * Number of rollouts to return per page
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    numPerPage: number;
    /**
     * Optional restriction to return rollouts just for this deployment
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    deploymentId?: number;
    /**
     * Optional restriction to return rollouts started after this date, inclusive
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    minDateInclusive?: number;
    /**
     * Optional restriction to return rollouts that started prior to this date, exclusive
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    maxDateExclusive?: number;
    /**
     * The total number of rollouts that matched the criteria. Can be used to calculate the total number of pages.
     * @type {number}
     * @memberof RolloutsPageResponse
     */
    totalRollouts: number;
    /**
     * The rollouts for this page
     * @type {Array<RolloutListEntry>}
     * @memberof RolloutsPageResponse
     */
    entries: Array<RolloutListEntry>;
}

/**
 * The schedule that a deployment can run on
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * The hour of the day that the deployment should execute
     * @type {number}
     * @memberof Schedule
     */
    hour: number;
    /**
     * The minutes in the day that the deployment should execute. Must be a multiple of 10 (nearest 10-minutes)
     * @type {number}
     * @memberof Schedule
     */
    min: number;
    /**
     * Days that the schedule should run, at least one day must be populated.
     * @type {Array<ScheduleDay>}
     * @memberof Schedule
     */
    days: Array<ScheduleDay>;
}

/**
 * Day of week that the schedule can run
 * @export
 * @enum {string}
 */
export enum ScheduleDay {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

/**
 * Match on a specific setting value
 * @export
 * @interface SettingMatch
 */
export interface SettingMatch extends NumericMatch {
}

/**
 * Group of settings optionally based on existing meta and settings
 * @export
 * @interface SettingsGroup
 */
export interface SettingsGroup extends Polymorphic {
    /**
     * Unique ID for this group, generate new random for a new group, or re-use to update or delete
     * @type {string}
     * @memberof SettingsGroup
     */
    id: string;
    /**
     * Unique name for the group, must be unique within the entire system
     * @type {string}
     * @memberof SettingsGroup
     */
    name: string;
    /**
     * Settings to set, can be empty for 'no settings to set' to nullify this group
     * @type {Array<DeviceSetting>}
     * @memberof SettingsGroup
     */
    settings: Array<DeviceSetting>;
    /**
     * Settings list to match on, empty for no settings to match on
     * @type {Array<SettingMatch>}
     * @memberof SettingsGroup
     */
    settingsMatches: Array<SettingMatch>;
    /**
     * Meta to match on, empty for no meta to match on
     * @type {Array<MetaMatch>}
     * @memberof SettingsGroup
     */
    metaMatches: Array<MetaMatch>;
}

/**
 * Basic naming info for a SettingsGroup
 * @export
 * @interface SettingsGroupName
 */
export interface SettingsGroupName {
    /**
     * Unique ID for this group
     * @type {string}
     * @memberof SettingsGroupName
     */
    id: string;
    /**
     * Unique name for this group
     * @type {string}
     * @memberof SettingsGroupName
     */
    name: string;
}


/**
 * DeploymentsApi - axios parameter creator
 * @export
 */
export const DeploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific deployment. Only possible if the state is 'UnderConstruction'
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeployment(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDeployment.');
            }
            const localVarPath = `/deployments/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger an immediate deployment of a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deploy.');
            }
            const localVarPath = `/deployments/byid/{id}/deploy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployment(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDeployment.');
            }
            const localVarPath = `/deployments/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page of deployments that meet the provided criteria
         * @param {DeploymentsPageRequest} deploymentsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsPaged(deploymentsPageRequest: DeploymentsPageRequest, options: any = {}): RequestArgs {
            // verify required parameter 'deploymentsPageRequest' is not null or undefined
            if (deploymentsPageRequest === null || deploymentsPageRequest === undefined) {
                throw new RequiredError('deploymentsPageRequest','Required parameter deploymentsPageRequest was null or undefined when calling getDeploymentsPaged.');
            }
            const localVarPath = `/deployments/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeploymentsPageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deploymentsPageRequest || {}) : (deploymentsPageRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a link to the rollback report
         * @param {number} id Unique ID for rollout
         * @param {number} rollbackid Unique ID for the rollback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbackReportCsvLink(id: number, rollbackid: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRollbackReportCsvLink.');
            }
            // verify required parameter 'rollbackid' is not null or undefined
            if (rollbackid === null || rollbackid === undefined) {
                throw new RequiredError('rollbackid','Required parameter rollbackid was null or undefined when calling getRollbackReportCsvLink.');
            }
            const localVarPath = `/rollouts/byid/{id}/rollbacks/{rollbackid}/csv`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"rollbackid"}}`, encodeURIComponent(String(rollbackid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the rollbacks (if any) for a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbacksForRollout(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRollbacksForRollout.');
            }
            const localVarPath = `/rollouts/byid/{id}/rollbacks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollout(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRollout.');
            }
            const localVarPath = `/rollouts/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a time-limited URL to download the report for a rollout
         * @param {LogReportRequest} logReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutCsvLink(logReportRequest: LogReportRequest, options: any = {}): RequestArgs {
            // verify required parameter 'logReportRequest' is not null or undefined
            if (logReportRequest === null || logReportRequest === undefined) {
                throw new RequiredError('logReportRequest','Required parameter logReportRequest was null or undefined when calling getRolloutCsvLink.');
            }
            const localVarPath = `/rollouts/report/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LogReportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(logReportRequest || {}) : (logReportRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page of rollouts that meet provided criteria
         * @param {RolloutsPageRequest} rolloutsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutsPaged(rolloutsPageRequest: RolloutsPageRequest, options: any = {}): RequestArgs {
            // verify required parameter 'rolloutsPageRequest' is not null or undefined
            if (rolloutsPageRequest === null || rolloutsPageRequest === undefined) {
                throw new RequiredError('rolloutsPageRequest','Required parameter rolloutsPageRequest was null or undefined when calling getRolloutsPaged.');
            }
            const localVarPath = `/rollouts/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RolloutsPageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rolloutsPageRequest || {}) : (rolloutsPageRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revert a rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackRollout(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rollbackRollout.');
            }
            const localVarPath = `/rollouts/byid/{id}/rollback`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save (ID 0) or update (ID > 0) an existing deployment
         * @param {DeploymentEntry} deploymentEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeployment(deploymentEntry: DeploymentEntry, options: any = {}): RequestArgs {
            // verify required parameter 'deploymentEntry' is not null or undefined
            if (deploymentEntry === null || deploymentEntry === undefined) {
                throw new RequiredError('deploymentEntry','Required parameter deploymentEntry was null or undefined when calling saveDeployment.');
            }
            const localVarPath = `/deployments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeploymentEntry" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deploymentEntry || {}) : (deploymentEntry || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentsApi - functional programming interface
 * @export
 */
export const DeploymentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific deployment. Only possible if the state is 'UnderConstruction'
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeployment(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).deleteDeployment(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Trigger an immediate deployment of a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).deploy(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployment(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentEntry> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getDeployment(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a page of deployments that meet the provided criteria
         * @param {DeploymentsPageRequest} deploymentsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsPaged(deploymentsPageRequest: DeploymentsPageRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentsPageResponse>> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getDeploymentsPaged(deploymentsPageRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a link to the rollback report
         * @param {number} id Unique ID for rollout
         * @param {number} rollbackid Unique ID for the rollback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbackReportCsvLink(id: number, rollbackid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getRollbackReportCsvLink(id, rollbackid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the rollbacks (if any) for a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbacksForRollout(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RollbackEntry>> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getRollbacksForRollout(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollout(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolloutEntry> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getRollout(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a time-limited URL to download the report for a rollout
         * @param {LogReportRequest} logReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutCsvLink(logReportRequest: LogReportRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getRolloutCsvLink(logReportRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a page of rollouts that meet provided criteria
         * @param {RolloutsPageRequest} rolloutsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutsPaged(rolloutsPageRequest: RolloutsPageRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolloutsPageResponse> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).getRolloutsPaged(rolloutsPageRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Revert a rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackRollout(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).rollbackRollout(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save (ID 0) or update (ID > 0) an existing deployment
         * @param {DeploymentEntry} deploymentEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeployment(deploymentEntry: DeploymentEntry, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentEntry> {
            const localVarAxiosArgs = DeploymentsApiAxiosParamCreator(configuration).saveDeployment(deploymentEntry, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * DeploymentsApi - factory interface
 * @export
 */
export const DeploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a specific deployment. Only possible if the state is 'UnderConstruction'
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeployment(id: number, options?: any) {
            return DeploymentsApiFp(configuration).deleteDeployment(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Trigger an immediate deployment of a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(id: number, options?: any) {
            return DeploymentsApiFp(configuration).deploy(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific deployment
         * @param {number} id Unique ID for deployment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployment(id: number, options?: any) {
            return DeploymentsApiFp(configuration).getDeployment(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a page of deployments that meet the provided criteria
         * @param {DeploymentsPageRequest} deploymentsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentsPaged(deploymentsPageRequest: DeploymentsPageRequest, options?: any) {
            return DeploymentsApiFp(configuration).getDeploymentsPaged(deploymentsPageRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a link to the rollback report
         * @param {number} id Unique ID for rollout
         * @param {number} rollbackid Unique ID for the rollback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbackReportCsvLink(id: number, rollbackid: number, options?: any) {
            return DeploymentsApiFp(configuration).getRollbackReportCsvLink(id, rollbackid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the rollbacks (if any) for a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollbacksForRollout(id: number, options?: any) {
            return DeploymentsApiFp(configuration).getRollbacksForRollout(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollout(id: number, options?: any) {
            return DeploymentsApiFp(configuration).getRollout(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a time-limited URL to download the report for a rollout
         * @param {LogReportRequest} logReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutCsvLink(logReportRequest: LogReportRequest, options?: any) {
            return DeploymentsApiFp(configuration).getRolloutCsvLink(logReportRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a page of rollouts that meet provided criteria
         * @param {RolloutsPageRequest} rolloutsPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolloutsPaged(rolloutsPageRequest: RolloutsPageRequest, options?: any) {
            return DeploymentsApiFp(configuration).getRolloutsPaged(rolloutsPageRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Revert a rollout
         * @param {number} id Unique ID for rollout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackRollout(id: number, options?: any) {
            return DeploymentsApiFp(configuration).rollbackRollout(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save (ID 0) or update (ID > 0) an existing deployment
         * @param {DeploymentEntry} deploymentEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDeployment(deploymentEntry: DeploymentEntry, options?: any) {
            return DeploymentsApiFp(configuration).saveDeployment(deploymentEntry, options)(axios, basePath);
        },
    };
};

/**
 * DeploymentsApi - object-oriented interface
 * @export
 * @class DeploymentsApi
 * @extends {BaseAPI}
 */
export class DeploymentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific deployment. Only possible if the state is 'UnderConstruction'
     * @param {number} id Unique ID for deployment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deleteDeployment(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).deleteDeployment(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Trigger an immediate deployment of a specific deployment
     * @param {number} id Unique ID for deployment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public deploy(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).deploy(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific deployment
     * @param {number} id Unique ID for deployment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDeployment(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).getDeployment(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a page of deployments that meet the provided criteria
     * @param {DeploymentsPageRequest} deploymentsPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getDeploymentsPaged(deploymentsPageRequest: DeploymentsPageRequest, options?: any) {
        return DeploymentsApiFp(this.configuration).getDeploymentsPaged(deploymentsPageRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a link to the rollback report
     * @param {number} id Unique ID for rollout
     * @param {number} rollbackid Unique ID for the rollback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getRollbackReportCsvLink(id: number, rollbackid: number, options?: any) {
        return DeploymentsApiFp(this.configuration).getRollbackReportCsvLink(id, rollbackid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the rollbacks (if any) for a specific rollout
     * @param {number} id Unique ID for rollout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getRollbacksForRollout(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).getRollbacksForRollout(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific rollout
     * @param {number} id Unique ID for rollout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getRollout(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).getRollout(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a time-limited URL to download the report for a rollout
     * @param {LogReportRequest} logReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getRolloutCsvLink(logReportRequest: LogReportRequest, options?: any) {
        return DeploymentsApiFp(this.configuration).getRolloutCsvLink(logReportRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a page of rollouts that meet provided criteria
     * @param {RolloutsPageRequest} rolloutsPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public getRolloutsPaged(rolloutsPageRequest: RolloutsPageRequest, options?: any) {
        return DeploymentsApiFp(this.configuration).getRolloutsPaged(rolloutsPageRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Revert a rollout
     * @param {number} id Unique ID for rollout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public rollbackRollout(id: number, options?: any) {
        return DeploymentsApiFp(this.configuration).rollbackRollout(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save (ID 0) or update (ID > 0) an existing deployment
     * @param {DeploymentEntry} deploymentEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentsApi
     */
    public saveDeployment(deploymentEntry: DeploymentEntry, options?: any) {
        return DeploymentsApiFp(this.configuration).saveDeployment(deploymentEntry, options)(this.axios, this.basePath);
    }

}

/**
 * RdmApi - axios parameter creator
 * @export
 */
export const RdmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a DM lookup
         * @param {string} id Lookup unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDmLookup(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDmLookup.');
            }
            const localVarPath = `/rdm/lookup/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the DMs within a single site, often this will just be the one
         * @param {string} siteid GenX Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDMsForSite(siteid: string, options: any = {}): RequestArgs {
            // verify required parameter 'siteid' is not null or undefined
            if (siteid === null || siteid === undefined) {
                throw new RequiredError('siteid','Required parameter siteid was null or undefined when calling getDMsForSite.');
            }
            const localVarPath = `/rdm/lookup/site/{siteid}`
                .replace(`{${"siteid"}}`, encodeURIComponent(String(siteid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ALL of the unique sites in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteNames(options: any = {}): RequestArgs {
            const localVarPath = `/rdm/lookup/sitenames`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a DM to the lookup table
         * @param {RdmInfo} rdmInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDmLookup(rdmInfo: RdmInfo, options: any = {}): RequestArgs {
            // verify required parameter 'rdmInfo' is not null or undefined
            if (rdmInfo === null || rdmInfo === undefined) {
                throw new RequiredError('rdmInfo','Required parameter rdmInfo was null or undefined when calling saveDmLookup.');
            }
            const localVarPath = `/rdm/lookup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RdmInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rdmInfo || {}) : (rdmInfo || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RdmApi - functional programming interface
 * @export
 */
export const RdmApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a DM lookup
         * @param {string} id Lookup unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDmLookup(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = RdmApiAxiosParamCreator(configuration).deleteDmLookup(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the DMs within a single site, often this will just be the one
         * @param {string} siteid GenX Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDMsForSite(siteid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RdmInfo>> {
            const localVarAxiosArgs = RdmApiAxiosParamCreator(configuration).getDMsForSite(siteid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get ALL of the unique sites in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteNames(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RdmSiteName>> {
            const localVarAxiosArgs = RdmApiAxiosParamCreator(configuration).getSiteNames(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a DM to the lookup table
         * @param {RdmInfo} rdmInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDmLookup(rdmInfo: RdmInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = RdmApiAxiosParamCreator(configuration).saveDmLookup(rdmInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * RdmApi - factory interface
 * @export
 */
export const RdmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a DM lookup
         * @param {string} id Lookup unique ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDmLookup(id: string, options?: any) {
            return RdmApiFp(configuration).deleteDmLookup(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the DMs within a single site, often this will just be the one
         * @param {string} siteid GenX Site ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDMsForSite(siteid: string, options?: any) {
            return RdmApiFp(configuration).getDMsForSite(siteid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get ALL of the unique sites in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteNames(options?: any) {
            return RdmApiFp(configuration).getSiteNames(options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a DM to the lookup table
         * @param {RdmInfo} rdmInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDmLookup(rdmInfo: RdmInfo, options?: any) {
            return RdmApiFp(configuration).saveDmLookup(rdmInfo, options)(axios, basePath);
        },
    };
};

/**
 * RdmApi - object-oriented interface
 * @export
 * @class RdmApi
 * @extends {BaseAPI}
 */
export class RdmApi extends BaseAPI {
    /**
     * 
     * @summary Delete a DM lookup
     * @param {string} id Lookup unique ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RdmApi
     */
    public deleteDmLookup(id: string, options?: any) {
        return RdmApiFp(this.configuration).deleteDmLookup(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the DMs within a single site, often this will just be the one
     * @param {string} siteid GenX Site ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RdmApi
     */
    public getDMsForSite(siteid: string, options?: any) {
        return RdmApiFp(this.configuration).getDMsForSite(siteid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get ALL of the unique sites in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RdmApi
     */
    public getSiteNames(options?: any) {
        return RdmApiFp(this.configuration).getSiteNames(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a DM to the lookup table
     * @param {RdmInfo} rdmInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RdmApi
     */
    public saveDmLookup(rdmInfo: RdmInfo, options?: any) {
        return RdmApiFp(this.configuration).saveDmLookup(rdmInfo, options)(this.axios, this.basePath);
    }

}

/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsGroup(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSettingsGroup.');
            }
            const localVarPath = `/settings/groups/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the unique names for SettingsGroups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSettingsGroupNames(options: any = {}): RequestArgs {
            const localVarPath = `/settings/groups/all/names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsGroup(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSettingsGroup.');
            }
            const localVarPath = `/settings/groups/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a SettingsGroup
         * @param {SettingsGroup} settingsGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettingsGroup(settingsGroup: SettingsGroup, options: any = {}): RequestArgs {
            // verify required parameter 'settingsGroup' is not null or undefined
            if (settingsGroup === null || settingsGroup === undefined) {
                throw new RequiredError('settingsGroup','Required parameter settingsGroup was null or undefined when calling saveSettingsGroup.');
            }
            const localVarPath = `/settings/groups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SettingsGroup" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(settingsGroup || {}) : (settingsGroup || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsGroup(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).deleteSettingsGroup(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the unique names for SettingsGroups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSettingsGroupNames(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettingsGroupName>> {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).getAllSettingsGroupNames(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsGroup(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsGroup> {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).getSettingsGroup(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a SettingsGroup
         * @param {SettingsGroup} settingsGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettingsGroup(settingsGroup: SettingsGroup, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = SettingsApiAxiosParamCreator(configuration).saveSettingsGroup(settingsGroup, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSettingsGroup(id: string, options?: any) {
            return SettingsApiFp(configuration).deleteSettingsGroup(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the unique names for SettingsGroups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSettingsGroupNames(options?: any) {
            return SettingsApiFp(configuration).getAllSettingsGroupNames(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific SettingsGroup
         * @param {string} id Unique ID for SettingsGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsGroup(id: string, options?: any) {
            return SettingsApiFp(configuration).getSettingsGroup(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a SettingsGroup
         * @param {SettingsGroup} settingsGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettingsGroup(settingsGroup: SettingsGroup, options?: any) {
            return SettingsApiFp(configuration).saveSettingsGroup(settingsGroup, options)(axios, basePath);
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific SettingsGroup
     * @param {string} id Unique ID for SettingsGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public deleteSettingsGroup(id: string, options?: any) {
        return SettingsApiFp(this.configuration).deleteSettingsGroup(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the unique names for SettingsGroups in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAllSettingsGroupNames(options?: any) {
        return SettingsApiFp(this.configuration).getAllSettingsGroupNames(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific SettingsGroup
     * @param {string} id Unique ID for SettingsGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettingsGroup(id: string, options?: any) {
        return SettingsApiFp(this.configuration).getSettingsGroup(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a SettingsGroup
     * @param {SettingsGroup} settingsGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public saveSettingsGroup(settingsGroup: SettingsGroup, options?: any) {
        return SettingsApiFp(this.configuration).saveSettingsGroup(settingsGroup, options)(this.axios, this.basePath);
    }

}

