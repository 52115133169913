import ApiModel from "../../ApiModel/ApiModel";
import {saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import {Dispatch} from 'redux';
import {RDM_INFO_LOOKUP_STORE_STATE, RdmInfoLookupDispatchTypes} from "./RdmInfoLookupActionTypes";
import {RdmInfo} from "../../../api/x-control";

export const saveDataManagerToSite = (rdmInfo: RdmInfo, successCallback: () => void, errorCallback: () => void) => async (dispatch: Dispatch<RdmInfoLookupDispatchTypes>) => {
    try {
        const success = await saveData(
            RDM_INFO_LOOKUP_STORE_STATE,
            dispatch,
            () => ApiModel.getRdmApi().saveDmLookup(rdmInfo)
        )

        if(success) {
            successCallback()
        }
        else {
            errorCallback()
        }

    }

    catch (e) {
        dispatch({
            type: RDM_INFO_LOOKUP_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}
