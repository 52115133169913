import {CARD_STORE_STATE, ISettingsGroupCard} from "../actions/CardActionTypes";
import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

const defaultState: StoreServiceData<ISettingsGroupCard> = {
    loading: false,
    error: null,
    data: null
}

const cardReducer = (state: StoreServiceData<ISettingsGroupCard> = defaultState, action: ServiceActionTypes<ISettingsGroupCard>) => {
    switch (action.type) {
        case CARD_STORE_STATE.SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null
            }

        default:
            return {
                ...state
            }
    }
}

export default cardReducer;
