import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import PageHeader from "../PageHeader";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {fetchDataManagersForSite, nullifyDataManagers} from "../../../store/RdmInfoList/actions/RdmInfoListActions";
import {RdmInfo, RdmSiteName} from "../../../api/x-control";
import {toArray} from "../../../utils/sortingUtils";
import SiteManagementSiteTableItem from "./SiteManagementSiteTableItem";

const SiteManagementSiteTable = (props: RdmInfo[]) => {
    const params: any = useParams();
    const [pageHeaderName, setPageHeaderName] = useState<string>('');
    const siteNameListStore = useSelector((state: RootStore) => state.rdmSiteList);
    const [rdmInfoList, setRdmInfoList] = useState<RdmInfo[]>(toArray(props))
    const history = useHistory();

    useEffect(() => {
        setRdmInfoList(toArray(props))
        if(props.length > 0) {
            setRdmInfoList(toArray(props))
        }
    }, [props])

    useEffect(() => {
        const sites = siteNameListStore.data;
        if(!sites) return;
        if(siteNameListStore.loading) return;

        const site = sites.find((el: RdmSiteName) => el.siteId === params.siteId)

        if(!site) return;

        setPageHeaderName(site.siteName);
    },[siteNameListStore.data, siteNameListStore.loading])

    //Back to list of sites
    const back = () => {
        history.push(routeNames.siteManagementList.path)
    }

    //Create a DM for site.
    const addDm = () => {
        const createDmRoute = `${history.location.pathname}${routeNames.siteManagementDataManagerCreate.path}`

        history.push({
            pathname: createDmRoute,
            state: {
                from: history.location.pathname
            }
        })
    }

    return (
        <div className="h-100">
            <PageHeader
                actionName="Viewing Site"
                customName={pageHeaderName}
            />
            <div className='table-background'>
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <h5 className="pt-4 pl-2 ml-3">
                                    Site Data Managers
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-contents">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Name
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Host Name
                                </p>
                            </div>
                            <div className="col-md-1">
                                <p className="uppercasify table-keys">
                                    Source
                                </p>
                            </div>
                            <div className="col-md-1">
                                <p className="uppercasify table-keys">
                                    Api Level
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Namespace
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                        {
                            rdmInfoList.length === 0 &&
                            <div className="align-middle">
                                <h5 className="d-flex justify-content-center pt-2">
                                    No data managers exist for this site.
                                </h5>
                            </div>
                        }
                        {
                            rdmInfoList.length > 0 && rdmInfoList.map((el: RdmInfo) => {
                                return <SiteManagementSiteTableItem rdm={el} siteId={params.siteId} key={el.id} />
                            })
                        }
                    </div>
                </div>
                <div className="float-right">
                    <a onClick={back} className="interactive-text pr-3">
                        <span className="pl-2">Back</span>
                    </a>
                    <span className="red-text pr-2">
                        |
                    </span>
                    <a onClick={addDm} className="interactive-text pr-3">
                        <span className="pl-2">+ Add Data Manager</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SiteManagementSiteTable;
