import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSiteNamesFromService} from "../../../store/RdmSiteNameList/actions/RdmSiteListActions";
import {RootStore} from "../../../store/Store";
import SiteManagementTableItemsWrapper from "./SiteManagementTableItemsWrapper";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";

const SiteManagementItemsWrapper = WithServiceState(SiteManagementTableItemsWrapper)

const SiteManagementListTable = () => {
    const dispatch = useDispatch()
    const siteListStore = useSelector((state: RootStore) => state.rdmSiteList);
    const history = useHistory();

    useEffect(() => {
        dispatch(getSiteNamesFromService());
    }, [])

    const addDm = () => {
        const createDmRoute = `${routeNames.siteManagementList.path}${routeNames.siteManagementDataManagerCreate.path}`
        history.push({
            pathname: createDmRoute,
            state: {
                from: history.location.pathname
            }
        })
    }

    const back = () => {
        //history.push(rou)
    }

    return (
        <React.Fragment>
            <div className="table-background">
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <h5 className="pt-4 pl-2 ml-3">
                                    Site List
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="ml-auto justify-content-end mt-4 pr-5 float-right">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-contents">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Name
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Site ID
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                        <SiteManagementItemsWrapper {...siteListStore}/>
                    </div>
                </div>
                <div className="float-right">
                    <a onClick={addDm} className="interactive-text pr-3">
                        <span className="pl-2">+ Add Data Manager</span>
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SiteManagementListTable;
