import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {SettingsGroup} from "../../../api/x-control";

export const CARD_STORE_STATE: StoreState = {
    LOADING: "CARD_LOADING",
    SUCCESS: "CARD_SUCCESS",
    ERROR: "CARD_ERROR"
}

export interface CardLoading extends StoreServiceData<ISettingsGroupCard>{
    type: typeof CARD_STORE_STATE.LOADING;
}

export interface CardError extends StoreServiceData<ISettingsGroupCard>{
    type: typeof CARD_STORE_STATE.ERROR;
}

export interface CardSuccess extends StoreServiceData<ISettingsGroupCard>{
    type: typeof CARD_STORE_STATE.SUCCESS;
}

export type CardDispatchTypes = CardError | CardLoading | CardSuccess;


export interface ISettingsGroupCard extends SettingsGroup{
    action: SettingGroupCardActionEnum;
}

export enum SettingGroupCardActionEnum {
    Added = "Added",
    Removed = "Removed"
}
