import React from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import {useModal} from "../../../Modal/hooks/useModal";
import Modal, {ModalSize} from "../../../Modal/components/Modal";
import EditDeviceForm from "./EditDeviceForm";

const AddDeviceAction = () => {
    const { isShown, toggle } = useModal();

    const newDevice: RdmDeviceInfo = {
        deviceName: "",
        meta: {}
    }

    return (
        <React.Fragment>
            <a onClick={toggle} className="interactive-text pr-3">
                <span className="pl-2">+ Add Device</span>
            </a>
            <span className="red-text pr-2">
                |
            </span>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText="Add Device"
                modalSize={ModalSize.medium}
                modalContent={
                    <EditDeviceForm
                        rdmDeviceInfo={newDevice}
                        onClose={toggle}
                        isNewDevice={true}
                    />
                }
            />
        </React.Fragment>
    )
}

export default AddDeviceAction
