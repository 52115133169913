import React, {useState} from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import {useModal} from "../../../Modal/hooks/useModal";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import {deleteDeviceFromDM} from "../../../../store/RdmInfo/actions/RdmInfoActions";
import {useDispatch} from "react-redux";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";

const DeleteDeviceAction = (props: RdmDeviceInfo) => {

    const dispatch = useDispatch();
    const { isShown, toggle } = useModal();
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });

    const deleteDevice = () => {
        setModalProps({
            headerText: "Delete Device",
            messages: [`Are you sure you want to delete ${props.deviceName}`],
            confirmText: "Yes",
            cancelText: "Cancel",
            onConfirm: () => {
                dispatch(deleteDeviceFromDM(props.deviceName))
            },
            modalSize: ModalSize.small
        })

        toggle();
    }

    return (
        <React.Fragment>
            <span className="row ml-0 mb-1 mr-0">
                <a onClick={deleteDevice}>
                    <span className="interactive-text">
                        Delete Device
                    </span>
                </a>
            </span>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default DeleteDeviceAction;
