import { IdToken } from '@auth0/auth0-react';
import axios from 'axios';
import moment from "moment";

class Auth {

    public setSession({access_token, expiresAt, id_token}: SetSessionArgs): void {
        // Set the time that the access token will expire at
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt));
        localStorage.setItem('id_token', id_token);
    }

    public isAuthenticated(): boolean {
        let isAuthenticated = false;
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAtStr = localStorage.getItem('expires_at');
        if (expiresAtStr) {
            const expiresAt = JSON.parse(expiresAtStr);
            const currentTime = moment.unix(new Date().getTime() / 1000).unix();
            isAuthenticated = currentTime < expiresAt;
        }

        if (!isAuthenticated) {
            localStorage.setItem('redirectionUrl', window.location.href);
        }

        return isAuthenticated;
    }

    public clearLocalSession(): void {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('id_token');
    }

    public getIdToken(): string | null {
        return localStorage.getItem('id_token');
    }

    public getAccessToken(): string | null {
        return localStorage.getItem('access_token');
    }
}

export default new Auth();

interface SetSessionArgs {
    access_token: string;
    id_token: string;
    expiresAt: number | undefined;
}
