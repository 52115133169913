import {SETTING_GROUP_STORE_STATE} from "../actions/SettingGroupActionTypes";
import {SettingsGroup} from "../../../api/x-control";
import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";
import {deepCopy} from "../../../utils/copyUtils";

const defaultState: StoreServiceData<SettingsGroup> = {
    loading: false,
    error: null,
    data: null
}

const settingGroupReducer = (state: StoreServiceData<SettingsGroup> = defaultState, action: ServiceActionTypes<SettingsGroup>) => {
    switch (action.type) {
        case SETTING_GROUP_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case SETTING_GROUP_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case SETTING_GROUP_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: processGroupIncoming(action.data)
            }

        default:
            return state;
    }
}

export default settingGroupReducer;

export function processGroupIncoming(group: SettingsGroup | null | undefined) {

    if(!group) return null;

    const copy: SettingsGroup = deepCopy(group);

    if(!copy.settings) {
        copy.settings = [];
    }
    if(!copy.settingsMatches) {
        copy.settingsMatches = [];
    }

    if(!copy.metaMatches) {
        copy.metaMatches = [];
    }

    return copy;

}
