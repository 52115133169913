import React, {useEffect, useState} from "react";
import Loading from "../../Helpers/HOCs/ServiceState/Loading";
import {useAuth0} from "@auth0/auth0-react";
import {fetchConfig} from "../../../config/config";
import Auth from "../../../store/Auth/Auth";
import { useHistory } from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
const Login = () => {
    const {loginWithRedirect, user, isLoading, isAuthenticated, getIdTokenClaims, getAccessTokenSilently} = useAuth0();
    const history = useHistory();
    const [token, setToken] = useState<string>();
    useEffect(() => {
        if (token && isAuthenticated && !isLoading) {
            setTimeout(() => {
                history.push(routeNames.deploymentsList.path);
            }, 250) //Slight delay allows for CORs error to disappear
        }
    }, [token, isAuthenticated, isLoading])

    useEffect(() => {
        if(user && !isLoading) {
            (async function getUserMetaData() {
                const config = await fetchConfig();

                if(!config) return;
                const {audience, scope} = config.auth0config;
                try {
                    const idToken = await getIdTokenClaims({
                        audience,
                        scope
                    })

                    const accessToken = await getAccessTokenSilently({
                        audience,
                        scope
                    })

                    Auth.setSession({
                        access_token: accessToken,
                        id_token: idToken.__raw,
                        expiresAt: idToken.exp,
                    });

                    setToken(accessToken);
                }
                catch (e) {
                    console.log(e.message);
                }
            })();
        }

    }, [isLoading])

    return (
        <div className="min-vw-100">
            <div className="background min-vh-100">
                <div className="loader-position-wrapper">
                    <Loading
                        loadingText={"Logging In..."}
                    />
                </div>
            </div>
        </div>
    )
}

export default Login;
