import React from "react";
import PageHeader from "../PageHeader";
import {routeNames} from "../../Navigation/routeNames";
import SiteManagementListTable from "./SiteManagementListTable";

const SiteManagementList = () => {
    return (
        <div className="page-wrapper">
            <div className="background">
                <PageHeader actionName={routeNames.siteManagementList.name} />
                <SiteManagementListTable/>
            </div>
        </div>
    )
}

export default SiteManagementList;
