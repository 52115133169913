import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {MODAL_STORE_STATE} from "../actions/ModalActionTypes";

const defaultState: StoreServiceData<boolean> = {
    loading: false,
    error: null,
    data: false
}

const modalReducer = (state: StoreServiceData<boolean> = defaultState, action: ServiceActionTypes<boolean>) => {
    switch (action.type) {
        case MODAL_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case MODAL_STORE_STATE.ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case MODAL_STORE_STATE.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                data: action.data
            }
        default:
            return state;
    }
}

export default modalReducer;
