import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {RollbackEntry} from "../../../api/x-control";
import {ROLLBACK_STORE_STATE} from "../actions/RollbackActionTypes";

const defaultState: StoreServiceData<RollbackEntry> = {
    loading: false,
    error: null,
    data: null
}

const rollbackReducer = (state: StoreServiceData<RollbackEntry> = defaultState, action: ServiceActionTypes<RollbackEntry>) => {
    switch (action.type) {
        case ROLLBACK_STORE_STATE.ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case ROLLBACK_STORE_STATE.LOADING:
            return {
                ...state,
                error: null,
                loading: true
            }
        case ROLLBACK_STORE_STATE.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                data: action.data
            }
        default:
            return state;
    }
}

export default rollbackReducer;
