import React, {useEffect, useState} from "react";
import {DeploymentEntry, DeploymentState, Schedule} from "../../../../api/x-control";
import Select from "react-select";
import {DDProps} from "../../../../utils/sortingUtils";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";

const DeploymentScheduleTime = (props: DeploymentEntry) => {
    const [minuteDropDownProps, setMinuteDropDownProps] = useState<DDProps[]>([])
    const [hourDropDownProps, setHourDropDownProps] = useState<DDProps[]>([])

    const [selectedHour, setSelectedHour] = useState<DDProps>({value: 0, label: ""})
    const [selectedMinute, setSelectedMinute] = useState<DDProps>({value: 0, label: ""})

    const dispatch = useDispatch();

    useEffect(() => {
        //Getting Hours
        const hourDropdownOptions: DDProps[] = [];
        const minuteDropdownOptions: DDProps[] = [];

        for (let i = 0; i < 24; ++i) {
            let label = "";
            //append 0 to single digit numbers
            if(i < 10) {
                label = `0${i.toString()}` //00, 01 -> 08, 09
            }
            else{
                label = i.toString()
            }

            hourDropdownOptions.push({
                value: i,
                label
            })
        }
        //We generate the minute options in increments of 5 minutes. Each time we iterate through the loop, we increment i by 5.
        for (let i = 0; i < 60; i+=5){
            const label = i < 10 ? `0${i.toString()}` : i.toString()
            minuteDropdownOptions.push({
                value: i,
                label
            })
        }

        setHourDropDownProps(hourDropdownOptions);
        setMinuteDropDownProps(minuteDropdownOptions);

        const hourIndex = hourDropdownOptions.findIndex((el: DDProps) => +el.value === props.schedule?.hour);

        if(hourIndex < 0) {
            setSelectedHour(hourDropdownOptions[0]);
        }
        else {
            setSelectedHour(hourDropdownOptions[hourIndex]);
        }

        const minuteIndex = minuteDropdownOptions.findIndex((el: DDProps) => +el.value === props.schedule?.min);

        if(minuteIndex < 0) {
            setSelectedMinute(minuteDropdownOptions[0]);
        }
        else {
            setSelectedMinute(minuteDropdownOptions[minuteIndex]);
        }
    }, [props])

    const onHourChange = (p: DDProps) => {
        if(!props.schedule) return;
        const schedule = props.schedule;

        setSelectedHour(p)
        schedule.hour = +p.value;

        setDeploymentSchedule(schedule);
    }

    const onMinuteChange = (p: DDProps) => {
        if(!props.schedule) return;
        const schedule = props.schedule;

        setSelectedMinute(p)
        schedule.min = +p.value;

        setDeploymentSchedule(schedule);
    }

    const setDeploymentSchedule = (schedule: Schedule) => {
        const entry: DeploymentEntry = {
            ...props,
            schedule
        }

        dispatch(setDeployment(entry))
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    <p className="details">
                        Hour
                    </p>
                </div>
                <div className="col">
                    <p className="details">
                        Minute
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Select
                        className="search-bar-sm pl-2"
                        classNamePrefix="select-options"
                        options={hourDropDownProps}
                        onChange={onHourChange}
                        isSearchable={false}
                        value={selectedHour}
                        maxMenuHeight={150}
                        noOptionsMessage={() => "No Hour Options Found"}
                        isDisabled={props.state !== DeploymentState.UnderConstruction}
                    />
                </div>
                <div className="col">
                    <Select
                        className="search-bar-sm pl-2"
                        classNamePrefix="select-options"
                        options={minuteDropDownProps}
                        onChange={onMinuteChange}
                        isSearchable={false}
                        value={selectedMinute}
                        maxMenuHeight={150}
                        noOptionsMessage={() => "No Minute Options Found"}
                        isDisabled={props.state !== DeploymentState.UnderConstruction}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeploymentScheduleTime;
