import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {RolloutEntry} from "../../../api/x-control";
import {ROLLOUT_STORE_STATE} from "../actions/RolloutActionTypes";

const defaultState: StoreServiceData<RolloutEntry> = {
    loading: false,
    error: null,
    data: null
}

const rolloutReducer = (state: StoreServiceData<RolloutEntry> = defaultState, action: ServiceActionTypes<RolloutEntry>) => {
    switch (action.type) {
        case ROLLOUT_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case ROLLOUT_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ROLLOUT_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default rolloutReducer;
