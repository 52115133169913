import React, {useEffect} from "react";
import SiteManagementSiteTable from "./SiteManagementSiteTable";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {fetchDataManagersForSite, nullifyDataManagers} from "../../../store/RdmInfoList/actions/RdmInfoListActions";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import {getSiteNamesFromService} from "../../../store/RdmSiteNameList/actions/RdmSiteListActions";

const SiteManagementSiteServiceWrapper = WithServiceState(SiteManagementSiteTable)

const SiteManagementSite = () => {
    const dispatch = useDispatch();
    const params: any = useParams();

    const rdmInfoListStore = useSelector((state: RootStore) => state.rdmInfoList);

    // @ts-ignore
    useEffect(() => {
        const siteId = params.siteId;
        dispatch(fetchDataManagersForSite(siteId));
        dispatch(getSiteNamesFromService())

        return () => dispatch(nullifyDataManagers())
    }, [])

    return (
        <div className="page-wrapper">
            <div className="background">
                <SiteManagementSiteServiceWrapper {...rdmInfoListStore}/>
            </div>
        </div>
    )
}

export default SiteManagementSite;
