import {ROLLBACK_LIST_STORE_STATE, RollbackListDispatchTypes} from "./RollbackListActionTypes";
import {Dispatch} from 'redux';
import {fetchData} from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";
import Store from "../../Store";

export const fetchRollbacksByRolloutId = (id: number) => async (dispatch: Dispatch<RollbackListDispatchTypes>) => {
    try {
        const success = await fetchData(
            ROLLBACK_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getRollbacksForRollout(id)
        )

        if(success) {
            const rollbacks = Store.getState().rollbackList.data;
            if(!rollbacks) {
                return [];
            }

            return rollbacks;
        }

        return [];
    }
    catch (e) {
        dispatch({
            type: ROLLBACK_LIST_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const nullifyRollbacksListStore = () => async (dispatch: Dispatch<RollbackListDispatchTypes>) => {
    dispatch({
        type: ROLLBACK_LIST_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}
