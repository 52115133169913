import React from "react";
import {ApproveUser, DeploymentListEntry} from "../../../api/x-control";

const DeploymentListApprovalsDetails = (props: DeploymentListEntry) => {
    return(
        <div className="align-self-center table-values">
            <p className="ml-auto">
                <span className="row ml-0 mr-0">
                    {
                        props.approved &&
                        <React.Fragment>
                            <span> Yes </span> <span className="green-dot mt-1"/>
                        </React.Fragment>
                    }
                </span>
                <span className="row ml-0 mr-0">
                    {
                        !props.approved &&
                        <React.Fragment>
                            <span> No </span> <span className="red-dot mt-1"/>
                        </React.Fragment>
                    }
                </span>
                <span className="row ml-0 mr-0">
                    {
                        props.approvalUsers.length > 0 &&
                        <React.Fragment>
                            {props.approvalUsers.map((el: ApproveUser, index: number) => {
                                return (
                                    <span className="align-self-center" key={index}>
                                        <b>{el.name} :</b> <span className="green-dot mr-3 mt-1"/>
                                    </span>
                                )
                            })}
                        </React.Fragment>
                    }
                    {
                        props.approvalUsers.length === 0 &&
                        <span> No approvals </span>
                    }
                </span>
            </p>
        </div>
    )
}

export default DeploymentListApprovalsDetails;
