import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";
import {DEPLOYMENT_LIST_STORE_STATE} from "../actions/DeploymentListActionTypes";
import {DeploymentsPageResponse} from "../../../api/x-control";

const defaultState: StoreServiceData<DeploymentsPageResponse> = {
    loading: false,
    error: null,
    data: null
}

const deploymentListReducer = (state: StoreServiceData<DeploymentsPageResponse> = defaultState, action: ServiceActionTypes<DeploymentsPageResponse>) => {
    switch (action.type) {
        case DEPLOYMENT_LIST_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case DEPLOYMENT_LIST_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case DEPLOYMENT_LIST_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default deploymentListReducer;
