import React, {useEffect, useState} from "react";
import {RolloutEntryState, RolloutListEntry} from "../../../api/x-control";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getReportForRollout} from "../../../store/RolloutReport/actions/RolloutReportActions";
import {useModal} from "../../Modal/hooks/useModal";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import {useHistory} from "react-router-dom";
import {rollbackRolloutByRolloutId} from "../../../store/Rollback/actions/RollbackActions";
import {RootStore} from "../../../store/Store";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import {
    fetchRollbacksByRolloutId,
    nullifyRollbacksListStore
} from "../../../store/RollbackList/actions/RollbackListActions";
import {routeNames} from "../../Navigation/routeNames";
import {getQueries} from "../../../utils/queryUtils";

const RolloutTableItem = (props: RolloutListEntry) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const rollbackStore = useSelector((state: RootStore) => state.rollback);
    const rollbackListStore = useSelector((state: RootStore) => state.rollbackList);

    const { isShown, toggle } = useModal();
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });

    const downloadReport = async () => {
        // @ts-ignore
        const reportLink: string = await dispatch(getReportForRollout({
            deploymentId: props.deploymentId,
            rolloutId: props.id
        }))

        const link = document.createElement('a');

        if (link.download !== undefined && props.date !== undefined) {
            link.setAttribute('href', reportLink);
            const fileName = `${props.deploymentName}`;
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        }
    }

    const getRollouts = async () => {
        await dispatch(nullifyRollbacksListStore())
        const rollbacks = await dispatch(fetchRollbacksByRolloutId(props.id))

        if(!rollbacks) return;

        if(isShown) {
            toggle();
        }

        //Error for getting rollbacks for rollout
        if(rollbackListStore.error && rollbackListStore.error.length > 0) {
            setModalProps({
                headerText: "Get rollbacks",
                messages: [`Error getting rollbacks for rollout. Error: ${rollbackListStore.error}`],
                confirmText: "Retry",
                cancelText: "Cancel",
                onConfirm: () => getRollouts(),
                modalSize: ModalSize.small
            })
            toggle();
            return;
        }

        rollbackListPage();
    }

    useEffect(() => {
        const locationPathName = history.location.pathname;
        const actualPathName = `${routeNames.deploymentsList.path}/${props.deploymentId}${routeNames.rolloutList.path}`;
        //Check if we aren't in a child modal route (Seeing rollback list). If we are, and the modal is visible, we hide the modal
        if(locationPathName !== actualPathName && isShown) {
            toggle();
        }

    }, [history.location.pathname, isShown])

    const rollbackRollout = () => {
        if (isShown) {
            toggle()
        }

        setModalProps({
            headerText: "Rollback Rollout",
            messages: ["Are you sure you want to rollback this rollout?"],
            confirmText: "Yes",
            cancelText: "Cancel",
            onConfirm: () => dispatch(rollbackRolloutByRolloutId(props.id, rollbackSuccess, rollbackFailed)),
            modalSize: ModalSize.small
        })
        toggle();
    }

    const rollbackSuccess = () => {
        if (isShown) {
            toggle()
        }

        setModalProps({
            headerText: "Success",
            messages: ["Rollout was successfully rolled back. Click 'View Rollbacks' to see rollback progress"],
            confirmText: "View Rollbacks",
            cancelText: "Cancel",
            onConfirm: () => rollbackListPage(),
            modalSize: ModalSize.small
        })
    }

    const rollbackFailed = () => {
        if (isShown) {
            toggle()
        }

        setModalProps({
            headerText: "Failed",
            messages: [`Rollout was not able to be rolled back. Reason:
            ${rollbackStore.error}`],
            confirmText: "Retry",
            cancelText: "Cancel",
            onConfirm: () => rollbackRollout(),
            modalSize: ModalSize.small
        })

        toggle()
    }

    const rollbackListPage = () => {
        const queries = window.location.href.split("?")[1];
        const query = getQueries();
        const rollbackListPagePath = `${history.location.pathname}/${props.id}${routeNames.rollbackList.path}`;
        //Push where we are navigating to to state so we can navigate back here when done!
        history.push({
            pathname: rollbackListPagePath,
            state: {
                from: window.location.pathname
            },
            search: query
        })
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-sm-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        { props.id }
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        { props.deploymentName }
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    {
                        props.date &&
                        <p className="table-values align-self-center fadeIn">
                            { moment.unix(props.date).format('LLLL') }
                        </p>
                    }
                    {
                        !props.date &&
                        <p className="table-values align-self-center fadeIn">
                            N / A
                        </p>
                    }
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.state.split(/(?=[A-Z])+/g).join(" ")}
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            {
                                props.state === RolloutEntryState.Error &&
                                <span className="row ml-0 mr-0">
                                    <a onClick={downloadReport}>
                                        <span className="interactive-text">
                                            Download Report
                                        </span>
                                    </a>
                                </span>
                            }
                            {
                                props.state === RolloutEntryState.Deployed &&
                                <span className="row ml-0 mr-0">
                                    <a onClick={downloadReport}>
                                        <span className="interactive-text">
                                            Download Report
                                        </span>
                                    </a>
                                </span>
                            }
                            {
                                props.state === RolloutEntryState.InProgress &&
                                <span className="row ml-0 mr-0">
                                    N/A
                                </span>

                            }
                            {
                                props.state === RolloutEntryState.Pending &&
                                <span className="row ml-0 mr-0">
                                    N/A
                                </span>
                            }
                        </p>
                    </div>
                    {
                        props.state === RolloutEntryState.Deployed &&
                        <div className="align-self-center">
                            <p className="ml-auto table-values">
                                <span className="row ml-0 mr-0 red-text">
                                    |
                                </span>
                            </p>
                        </div>
                    }
                    {
                        props.state === RolloutEntryState.Error &&
                        <div className="align-self-center">
                            <p className="ml-auto table-values">
                                <span className="row ml-0 mr-0 red-text">
                                    |
                                </span>
                            </p>
                        </div>
                    }
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row ml-0 mr-0">
                                <a onClick={rollbackRollout}>
                                    <span className="interactive-text">
                                        Rollback Rollout
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row ml-0 mr-0 red-text">
                                |
                            </span>
                        </p>
                    </div>
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row ml-0 mr-0">
                                <a onClick={getRollouts}>
                                    <span className="interactive-text">
                                        Get Rollbacks
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator"/>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default RolloutTableItem;
