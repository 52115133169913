import React, {useEffect, useRef, useState} from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";
import {routeNames} from "../../../Navigation/routeNames";
import {findDuplicateStrings} from "../../../../utils/sortingUtils";

const DeviceMetaRow = (props: DeviceMetaRowProps) => {

    const previousName = useRef(props.name);
    const previousValue = useRef(props.value);

    const [isNameUnique, setIsNameUnique] = useState<boolean>(false);

    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    useEffect(() => {
        checkAndValidate()
    }, [])

    useEffect(() => {
        if(isShown) {
            toggle()
        }
        if(previousName.current !== props.name){
            previousName.current = props.name
        }
        if(previousValue.current !== props.value){
            previousValue.current = props.value;
        }

        checkAndValidate()
    }, [props])

    const checkAndValidate = () => {
        const names: string[] = [];
        for(const name of props.metaList) {
            names.push(name[0])
        }

        const uniqueDuplicates = Array.from(new Set(findDuplicateStrings(names)))

        const isADuplicate = uniqueDuplicates.indexOf(props.name);

        if(isADuplicate < 0) {
            setIsNameUnique(true)
        }
        else{
            setIsNameUnique(false)
        }
    }

    const deleteDeviceMeta = () => {
        setModalProps({
            headerText: "Delete Meta",
            messages: [`Are you sure you want to delete ${props.name}`],
            confirmText: "Yes",
            cancelText: "Cancel",
            onConfirm: () => props.onRemove(previousName.current),
            modalSize: ModalSize.small
        })

        toggle();
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        const deviceMeta: DeviceMetaRowProps = {
            ...props,
            name: input
        }

        props.onChange(deviceMeta, previousName.current, previousValue.current)
    }

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        const deviceMeta: DeviceMetaRowProps = {
            ...props,
            value: input
        }

        props.onChange(deviceMeta, previousName.current, previousValue.current);
    }

    return (
        <React.Fragment>
            <div className="row pt-4">
                <div className="col-md-5">
                    <input
                        type="text"
                        value={props.name}
                        onChange={onNameChange}
                        className="input-field w-100"
                    />
                    {
                        props.name.length < 1 &&
                            <div className="error-text pl-3 mt-2">
                                Name field is required
                            </div>
                    }
                    {
                        !isNameUnique &&
                            <div className="error-text pl-3 mt-2">
                                Field must be unique
                            </div>
                    }
                </div>
                <div className="col-md-5">
                    <input
                        type="text"
                        value={props.value}
                        onChange={onValueChange}
                        className="input-field w-100"
                    />
                    {
                        props.value.length < 1 &&
                            <div className="error-text pl-3 mt-2">
                                Value field is required
                            </div>
                    }
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center">
                        <span className="row ml-0 mr-0">
                            <a onClick={deleteDeviceMeta}>
                                <span className="interactive-text">
                                    Delete Meta
                                </span>
                            </a>
                        </span>
                    </p>
                </div>q
            </div>
            <div className="row-separator" />
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default DeviceMetaRow;

export interface DeviceMetaRowProps {
    name: string;
    value: string;
    metaList: [string, string][];
    onChange: (deviceMetaRow: DeviceMetaRowProps, previousName: string, previousValue: string) => void;
    onRemove: (previousName: string) => void;
}
