import {ROLLBACK_STORE_STATE, RollbackDispatchTypes} from "./RollbackActionTypes";
import {Dispatch} from 'redux';
import {fetchData, saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";

export const rollbackRolloutByRolloutId = (id: number, successCallback: () => void, errorCallback: () => void) => async (dispatch: Dispatch<RollbackDispatchTypes>) => {

    try {
        const success = await saveData(
            ROLLBACK_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().rollbackRollout(id)
        )

        if(success) {
            successCallback();
        }
        else {
            errorCallback()
        }
    }
    catch (e) {
        dispatch({
            type: ROLLBACK_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}
