import React, {useRef} from "react";
import Sidebar from "../Navigation/Sidebar/Sidebar";
import Header from "../Helpers/HOCs/PageRunners/Header";
import Footer from "../Helpers/HOCs/PageRunners/Footer";
import { Helmet } from 'react-helmet';
import getConfig from "../../config/config";

const DefaultLayout = ({children}: LayoutProps) => {
    //UseRef because we don't need this to change on every refresh. It is essentially static
    const config = useRef(getConfig())

    return(
        <React.Fragment>
            <Helmet>
                <link rel="shortcut icon" href={config.current.favIcon} />
                <title>{config.current.brandName ? `${config.current.brandName} ` : ''}X-Control</title>
            </Helmet>
            <Sidebar/>
            <div className="main-panel" id="main-panel">
                <Header/>
                {children}
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default DefaultLayout;


export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: JSX.Element;
}
