import React, {useEffect, useState} from "react";
import {DeploymentEntry} from "../../../api/x-control";
import PageHeader from "../PageHeader";
import {useParams} from 'react-router-dom';
import DeploymentDetails from "./DeploymentDetails";
import DeploymentGroupSettingsWrapper from "./DeploymentGroupSettingsWrapper";
import SettingsGroupContainer from "./SettingsGroupContainer";

const DeploymentTable = (props: DeploymentEntry) => {
    const [headerActionName, setHeaderActionName] = useState<string>('');
    const params: any = useParams();

    useEffect(() => {
        const id = params.id;

        if(!id) {
            setHeaderActionName('Creating Deployment');
            return;
        }

        setHeaderActionName('Editing Deployment')
    }, [])

    return (
        <div className="h-100">
            <PageHeader
                actionName={headerActionName}
                customName={props.name}
            />
            <div className='table-background'>
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <h5 className="pt-4 pl-2 ml-3">
                                    {headerActionName}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row table-contents-wrapper">
                    <div className="col-md-2 pr-0">
                        <p className="column-header">
                            Deployment Details
                        </p>
                        <div className="table-contents mt-0">
                            <DeploymentDetails {...props} />
                        </div>
                    </div>
                    <DeploymentGroupSettingsWrapper {...props}/>
                    <SettingsGroupContainer />
                </div>
            </div>
        </div>
    )
}

export default DeploymentTable;
