import { StoreServiceData, StoreState } from "../../Helpers/Types/StoreServiceData";
import {SettingsGroupName} from "../../../api/x-control";

export const SETTINGS_GROUPS_STORE_STATE: StoreState = {
    SUCCESS: "SETTINGS_GROUP_SUCCESS",
    LOADING: "SETTINGS_GROUP_LOADING",
    ERROR: "SETTINGS_GROUP_ERROR"
}

export interface SettingsGroupsLoading extends StoreServiceData<SettingsGroupName[]>{
    type: typeof SETTINGS_GROUPS_STORE_STATE.LOADING;
}

export interface SettingsGroupsError extends StoreServiceData<SettingsGroupName[]>{
    type: typeof SETTINGS_GROUPS_STORE_STATE.ERROR;
}

export interface SettingsGroupsSuccess extends StoreServiceData<SettingsGroupName[]>{
    type: typeof SETTINGS_GROUPS_STORE_STATE.SUCCESS;
}

export type SettingsGroupsDispatchTypes = SettingsGroupsSuccess | SettingsGroupsLoading | SettingsGroupsError;
