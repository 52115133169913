import React, {useRef} from "react";
import {NavLink} from "react-router-dom";
import {routeNames} from "../routeNames";
import {useAuth0} from "@auth0/auth0-react";
import getConfig from '../../../config/config'
import Auth from "../../../store/Auth/Auth";


const Sidebar = () => {
    const {logout} = useAuth0();
    const config = useRef(getConfig());

    const logoutFromApp = () => {
        //Clear any local storage we have in the browser
        Auth.clearLocalSession();
        logout({
            returnTo: config.current.auth0config.logoutRedirectUri,
            client_id: config.current.auth0config.clientID,
            federated: true
        })
    }

    return (
        <nav className="navbar drop-shadow-x">
            <ul className="navbar-nav">
                <li className="logo">
                    <img src={config.current.logo} alt="" />
                </li>
                <li className="navbar-item mt-5">
                    <NavLink to={routeNames.deploymentsList.path} className="navbar-link" activeClassName="is-active-link">
                        <div className="active-bar" />
                        <div className="d-flex flex-column align-items-center nav-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 128 128"
                            >
                                <path
                                    fill="currentColor"
                                    d="M6.429,124.726c-0.535,0-1.071-0.199-1.487-0.598c-0.85-0.817-0.874-2.166-0.052-3.013l29.311-30.203
                                            c0.823-0.845,2.176-0.869,3.027-0.052c0.85,0.817,0.874,2.166,0.052,3.013L7.969,124.076
                                            C7.549,124.508,6.99,124.726,6.429,124.726z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M27.054,126c-0.506,0-1.013-0.177-1.42-0.538c-0.884-0.782-0.964-2.128-0.179-3.008l18.997-21.279
                                        c0.787-0.878,2.137-0.959,3.022-0.177c0.884,0.782,0.964,2.128,0.179,3.008l-18.997,21.279C28.232,125.758,27.645,126,27.054,126 z"
                                />
                                                            <path
                                                                fill="currentColor"
                                                                d="M5.139,105.605c-0.506,0-1.013-0.177-1.42-0.538c-0.884-0.782-0.964-2.128-0.179-3.008L22.538,80.78
                                          c0.786-0.878,2.137-0.959,3.022-0.177c0.884,0.782,0.964,2.128,0.179,3.008L6.741,104.89
                                           C6.317,105.363,5.73,105.605,5.139,105.605z"
                                                            />

                                                            <path
                                                                fill="currentColor"
                                                                d="M76.384,82.589c-3.622,1.677-7.28,3.246-10.963,4.707l1.358,17.566c5.524-2.506,18.341-10.283,28.504-31.399
                                           c-4.387,2.329-8.775,4.424-12.522,6.212C80.66,80.682,78.528,81.647,76.384,82.589z"
                                                            />
                                                            <path
                                                                fill="currentColor"
                                                                d="M86.461,32.591c-1.963,0-3.925,0.739-5.419,2.216c-1.442,1.427-2.237,3.323-2.237,5.339c0,2.016,0.794,3.912,2.238,5.339
                                           c2.988,2.955,7.851,2.954,10.837,0c0-0.001,0.001-0.001,0.001-0.001c1.442-1.427,2.238-3.323,2.238-5.339
                                           c0-2.016-0.795-3.912-2.238-5.339C90.387,33.329,88.424,32.591,86.461,32.591z"
                                                            />
                                                            <path
                                                                fill="currentColor"
                                                                d="M49.156,39.02c1.19-2.386,2.461-4.88,3.822-7.387C31.645,41.667,23.764,54.321,21.217,59.797l17.628,1.332
                                                       c1.844-4.555,3.855-9.076,6.041-13.535C46.213,44.877,47.632,41.975,49.156,39.02z"
                                                            />
                                                            <path
                                                                fill="currentColor"
                                                                d="M94.322,4.716l27.95,27.638c1.368-5.402,2.112-10.817,2.464-15.889c0.029-0.425,0.059-0.849,0.083-1.278
                                                     c0.037-0.642,0.067-1.276,0.092-1.905c0.028-0.722,0.05-1.449,0.064-2.179c0.01-0.502,0.018-0.999,0.021-1.49
                                                     c0.006-1.004,0.004-2.013-0.015-3.03c-0.004-0.23-0.01-0.455-0.016-0.682c-0.031-1.222-0.074-2.45-0.143-3.688
                                                     C116.675,1.723,105.533,1.896,94.322,4.716z"
                                                            />
                                                            <path
                                                                fill="currentColor"
                                                                d="M109.709,56.969c0.564-0.621,1.116-1.256,1.65-1.911c0.07-0.086,0.146-0.17,0.215-0.257
                                                       c0.563-0.701,1.102-1.428,1.631-2.167c0.183-0.256,0.363-0.514,0.541-0.773c0.41-0.596,0.808-1.205,1.196-1.825
                                                       c0.25-0.4,0.492-0.804,0.73-1.209c0.283-0.48,0.559-0.966,0.83-1.459c0.236-0.433,0.471-0.866,0.695-1.304
                                                       c0.167-0.326,0.325-0.661,0.487-0.992c0.429-0.881,0.841-1.769,1.224-2.666c0.06-0.141,0.119-0.283,0.179-0.425
                                                       c0.683-1.633,1.29-3.288,1.833-4.955L89.611,6.064c-7.978,2.575-14.739,6.353-20.17,11.245c-0.14,0.126-0.28,0.253-0.419,0.381
                                                       c-0.71,0.655-1.403,1.325-2.065,2.019c-0.235,0.248-0.462,0.511-0.693,0.767c-5.23,5.781-9.608,13.326-13.346,20.581
                                                       c-0.833,1.672-1.625,3.291-2.36,4.797c-2.914,5.943-5.566,12.097-7.884,18.293c-0.163,0.437-0.463,0.788-0.835,1.031
                                                       c-1.639,4.206-3.132,8.435-4.453,12.67l11.028,10.906c4.401-1.342,8.796-2.862,13.164-4.535c0.227-0.25,0.495-0.467,0.83-0.589
                                                       c4.129-1.509,8.236-3.177,12.284-4.956c0.819-0.38,1.639-0.758,2.454-1.149c10.344-4.892,22.058-10.432,30.082-18.013
                                                       c0.681-0.641,1.336-1.313,1.979-1.998C109.377,57.333,109.543,57.151,109.709,56.969z M94.898,48.507
                                                       c-2.326,2.301-5.382,3.451-8.437,3.451c-3.055,0-6.11-1.15-8.436-3.45c-2.258-2.233-3.501-5.202-3.501-8.362
                                                       c0-3.16,1.244-6.129,3.5-8.362c4.653-4.601,12.222-4.599,16.873-0.001c2.259,2.233,3.502,5.202,3.502,8.362
                                                       C98.399,43.304,97.156,46.274,94.898,48.507z"
                                                            />
                            </svg>

                            <div className="link-text">
                                { routeNames.deploymentsList.name }
                            </div>
                        </div>
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink to={routeNames.settingsGroup.path} className="navbar-link" activeClassName="is-active-link">
                        <div className="active-bar" />
                        <div className="d-flex flex-column align-items-center nav-icon">
                            <svg
                                viewBox="0 0 32 32"
                                version="1.1"
                                x="0px"
                                y="0px"
                            >
                                <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                    type="MSPage"
                                >
                                    <path
                                        d="M32,11 L32,28 C32,29 30.9993054,29 30.9993054,29 L1.00069463,29 C1.00069463,29 0,29 0,28 L0,11 L32,11 Z M1.00069463,3 L9.5,3 C10.5574951,
                                            3 11,4 11,4 L12,6 L30.9993054,6 C30.9993054,6 32,6 32,7 L32,10 L0,10 L0,4 C-1.99840144e-15,3 1.00069463,3 1.00069463,3 Z M14.6614782,16.7685226
                                            L14.9174352,15.495389 C14.9630345,15.2217932 15.214035,15 15.5046844,15 L16.4953156,15 C16.7740451,15 17.0359588,15.2157526 17.0825648,15.495389
                                            L17.3385218,16.7685226 L18.4197521,16.0492701 C18.6454571,15.8880522 18.9797727,15.9087049 19.1852929,16.1142251 L19.8857749,16.8147071
                                            C20.0828664,17.0117986 20.1155072,17.3495597 19.9507299,17.5802479 L19.2314774,18.6614782 L20.504611,18.9174352 C20.7782068,18.9630345 21,19.214035
                                            21,19.5046844 L21,20.4953156 C21,20.7740451 20.7842474,21.0359588 20.504611,21.0825648 L19.2314774,21.3385218 L19.2314774,21.3385218 L19.9507299,22.4197521
                                            C20.1119478,22.6454571 20.0912951,22.9797727 19.8857749,23.1852929 L19.1852929,23.8857749 C18.9882014,24.0828664 18.6504403,24.1155072 18.4197521,23.9507299
                                            L17.3385218,23.2314774 L17.0825648,24.504611 C17.0369655,24.7782068 16.785965,25 16.4953156,25 L15.5046844,25 C15.2259549,
                                            25 14.9640412,24.7842474 14.9174352,24.504611 L14.6614782,23.2314774 L13.5802479,23.9507299 C13.3545429,24.1119478 13.0202273,24.0912951
                                            12.8147071,23.8857749 L12.1142251,23.1852929 C11.9171336,22.9882014 11.8844928,22.6504403 12.0492701,22.4197521 L12.7685226,21.3385218 L11.495389,21.0825648
                                             C11.2217932,21.0369655 11,20.785965 11,20.4953156 L11,19.5046844 C11,19.2259549 11.2157526,18.9640412 11.495389,18.9174352 L12.7685226,18.6614782
                                             L12.0492701,17.5802479 C11.8880522,17.3545429 11.9087049,17.0202273 12.1142251,16.8147071 L12.8147071,16.1142251 C13.0117986,15.9171336 13.3495597,15.8844928
                                             13.5802479,16.0492701 L14.6614782,16.7685226 Z M16,22.5 C17.3807119,22.5 18.5,21.3807119 18.5,20 C18.5,18.6192881 17.3807119,17.5 16,17.5 C14.6192881,17.5
                                             13.5,18.6192881 13.5,20 C13.5,21.3807119 14.6192881,22.5 16,22.5 Z"
                                        fill="currentColor"
                                        type="MSShapeGroup"/>
                                </g>
                            </svg>

                            <div className="link-text">
                                {routeNames.settingsGroup.name}
                            </div>
                        </div>
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink to={routeNames.siteManagementList.path} className="navbar-link" activeClassName="is-active-link">
                        <div className="active-bar" />
                        <div className="d-flex flex-column align-items-center nav-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 40 40"
                                x="0px"
                                y="0px"
                            >
                                <path
                                    fill="currentColor"
                                    d="M32.0087557,4 C33.1084896,4 34,4.90195036 34,6.0085302 L34,13.9914698 C34,15.1007504 33.1064574,16
                                     31.9972399,16 L21,16 L21,19 L28.0026083,19 C29.1057373,19 30,19.8938998 30,21.0048815
                                     L30,24 L34.0020869,24 C35.1055038,24 36,24.9019504 36,26.0085302 L36,33.9914698 C36,35.1007504
                                     35.1029399,36 33.9941413,36 L24.0058587,36 C22.8980535,36 22,35.0980496 22,33.9914698 L22,26.0085302
                                     C22,24.8992496 22.8982606,24 23.9979131,24 L28,24 L28,21.495389 C28,21.2217932 27.773819,21 27.5036068,21
                                     L12.4963932,21 C12.2222428,21 12,21.2157526 12,21.495389 L12,24 L16.0020869,24 C17.1055038,24 18,24.9019504
                                     18,26.0085302 L18,33.9914698 C18,35.1007504 17.1029399,36 15.9941413,36 L6.00585866,36 C4.89805351,36 4,
                                     35.0980496 4,33.9914698 L4,26.0085302 C4,24.8992496 4.89826062,24 5.99791312,24 L10,24 L10,21.0048815
                                     C10,19.897616 10.8958578,19 11.9973917,19 L19,19 L19,16 L8.00276013,16 C6.89666625,16 6,15.0980496
                                     6,13.9914698 L6,6.0085302 C6,4.8992496 6.89989752,4 7.99124431,4 L32.0087557,4 Z"
                                />
                            </svg>
                            <div className="link-text">
                                {routeNames.siteManagementList.name}
                            </div>
                        </div>
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <a onClick={logoutFromApp} className="navbar-link">
                        <div className="d-flex flex-column align-items-center nav-icon">
                            <svg
                                version="1.1" x="0px" y="0px"
                                viewBox="0 0 29 29"
                            >
                                <g
                                    transform="translate(-330 -500)">
                                    <g>
                                        <path
                                            fill="currentColor"
                                            d="M348,501.85v3.135c4.075,1.443,7,5.32,7,9.891c0,5.799-4.701,10.5-10.5,10.5s-10.5-4.701-10.5-10.5
                                                c0-4.57,2.924-8.447,7-9.891v-3.135c-5.756,1.543-10,6.781-10,13.025c0,7.455,6.044,13.5,13.5,13.5c7.456,0,13.5-6.045,13.5-13.5
                                                C358,508.631,353.756,503.393,348,501.85z"
                                        />
                                        <polygon
                                            fill="currentColor"
                                            points="343,515 346,515 346,500 343.047,500.063"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <div className="link-text">
                                Logout
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar;
