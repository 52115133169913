import React, {useEffect, useState} from "react";
import {ApproveUser, DeploymentEntry} from "../../../../api/x-control";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import jwt_decode from "jwt-decode";
import Auth from "../../../../store/Auth/Auth";


const DeploymentAddApproval = (props: DeploymentEntry) => {

    const [approver, setApprover] = useState<string>('');

    useEffect(() => {
        const id = Auth.getIdToken();
        if(!id) return;
        const decoded: any = jwt_decode(id);
        setApprover(decoded.email);
    }, [props])


    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();
    const dispatch = useDispatch();


    /** Methods */
    const addApproval = () => {
        setModalProps({
            headerText: 'Approve Deployment',
            messages: [`Are you sure you want to approve this deployment?`],
            onConfirm: () => null,
            confirmText: "",
            cancelText: "",
            modalSize: ModalSize.small
        })

        toggle();
    }

    const addApprovalToDeployment = () => {

        const userApproval: ApproveUser = {
            name: approver,
            date: moment.unix(new Date().getTime() / 1000).unix()
        }

        const entry: DeploymentEntry = {
            ...props,
            approvalUsers: [...props.approvalUsers, userApproval]
        }

        dispatch(setDeployment(entry));

        closeModal();
    }

    const closeModal = () => {
        setApprover('');
        toggle();
    }

    return (
        <div className="col">
            <div className="col">
                <a onClick={addApproval} className="interactive-text float-right">
                    + Add Approval
                </a>
            </div>
            <Modal
                isShown={isShown}
                hide={closeModal}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <React.Fragment>
                        <div className="pt-3 pb-2">
                            <p className="message">
                                {modalProps.messages}
                            </p>
                            <div className="button-wrapper pt-5">
                                <a
                                    onClick={addApprovalToDeployment}
                                    className="interactive-text green-text button-text"
                                >
                                    Yes
                                </a>
                                <a
                                    onClick={closeModal}
                                    className="interactive-text button-text"
                                >
                                    No
                                </a>
                            </div>
                        </div>
                    </React.Fragment>
                }
            />
        </div>
    )
}

export default DeploymentAddApproval;
