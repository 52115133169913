import React, {useRef, useState} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";
import {DragItem, DropResult, ItemTypes, MoveableSettingsGroupCards} from "../../DragAndDrop/DragAndDropUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {DeploymentState} from "../../../api/x-control";

const SettingsGroupCard = ({name, index, moveCard, id, removeCard}: MoveableSettingsGroupCards) => {

    const[groupType, setGroupType] = useState(ItemTypes.MOVING_GROUP_SETTING);
    const deploymentStore = useSelector((state: RootStore) => state.deployment);


    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: ItemTypes.GROUP_SETTING,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if(!ref.current) {
                return;
            }

            if(groupType !== ItemTypes.MOVING_GROUP_SETTING) {
                setGroupType(ItemTypes.MOVING_GROUP_SETTING);
            }

            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            const clientOffset = monitor.getClientOffset()

            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.MOVING_GROUP_SETTING, id, index, name },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end(item: DragItem | undefined, monitor: DragSourceMonitor) {
            const dropResult: DropResult = monitor.getDropResult()

            if (item && dropResult && removeCard) {
                removeCard(item.index);
            }
        },
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <React.Fragment>
            {
                deploymentStore.data && deploymentStore.data.state !== DeploymentState.UnderConstruction &&
                <div className="disabled">
                    <div className="row ml-2 mt-2 mr-2 settings-group-card">
                        <div className="col-md-9 d-flex">
                            <p className="align-self-center pl-2">
                                { name }
                            </p>
                        </div>
                        <div className="col-md-3 d-flex" />
                    </div>
                </div>
            }
            {
                deploymentStore.data && deploymentStore.data.state === DeploymentState.UnderConstruction &&
                <div ref={ref} style={{opacity}} className="moveable">
                    <div className="row ml-2 mt-2 mr-2 settings-group-card">
                        <div className="col-md-9 d-flex">
                            <p className="align-self-center pl-2">
                                { name }
                            </p>
                        </div>
                        <div className="col-md-3 d-flex">
                            <div className="align-self-center">
                                <svg
                                    className="card-handle"
                                    viewBox="0 0 24 24"
                                    version="1.1" x="0px" y="0px"
                                >
                                    <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                    >
                                        <path
                                            d="M13,14 L13,18 L17,18 L12,23 L7,18 L11,18 L11,14 L13,14 Z M18,11 L18,7 L23,12 L18,17 L18,13 L14,13 L14,11
                                        L18,11 Z M6,13 L6,17 L1,12 L6,7 L6,11 L10,11 L10,13 L6,13 Z M17,6 L13,6 L13,10 L11,10 L11,6 L7,6 L12,1 L17,6 Z"
                                            fill="currentColor"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default SettingsGroupCard;
