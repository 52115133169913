import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {ApproveUser, DeploymentListEntry, DeploymentState} from "../../../api/x-control";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {copyDeploymentFromService, nullifyDeploymentStore} from "../../../store/Deployment/actions/DeploymentActions";
import DeploymentListApprovalsDetails from "./DeploymentListApprovalsDetails";

const DeploymentListTableItem = (props: DeploymentListEntry) => {

    const [scheduleText, setScheduleText] = useState<string>("");
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!props.schedule) {
            setScheduleText('N/A');
            return;
        }

        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const filteredDays = daysOfWeek.filter((val: any) => props.schedule?.days.includes(val));

        let hourText = "";
        let minuteText = "";
        if(props.schedule.hour < 10) {
            hourText = `0${props.schedule.hour}`;
        }
        else {
            hourText = props.schedule.hour.toString();
        }

        if(props.schedule.min === 0) {
            minuteText = `0${props.schedule.min}`
        }
        else {
            minuteText = props.schedule.min.toString();
        }

        const scheduleInfo = `${filteredDays.join('/')} @ ${hourText}:${minuteText}`;

        setScheduleText(scheduleInfo);

    }, [props])

    const editDeployment = () => {
        dispatch(nullifyDeploymentStore())
        history.push({
            pathname: `${routeNames.deploymentEdit.path}/${props.id}`,
            state: {
                from: window.location.pathname
            }
        })
    }

    const copyDeployment = () => {
        dispatch(nullifyDeploymentStore())
        dispatch(copyDeploymentFromService(props.id))
        history.push({
            pathname: routeNames.deploymentCopy.path,
            state: {
                from: window.location.pathname
            }
        })
    }

    const viewRollouts = () => {
        history.push({
            pathname: `${routeNames.deploymentsList.path}/${props.id}${routeNames.rolloutList.path}`,
            state: {
                from: window.location.pathname
            }
        })
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-sm-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.name}
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {scheduleText}
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <DeploymentListApprovalsDetails {...props}/>
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {
                            props.reusable &&
                            <React.Fragment>
                                <span> Yes </span> <span className="green-dot"/>
                            </React.Fragment>
                        }
                        {
                            !props.reusable &&
                            <React.Fragment>
                                <span> No </span> <span className="red-dot"/>
                            </React.Fragment>
                        }
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.state.split(/(?=[A-Z])+/g).join(" ")}
                    </p>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            {
                                props.state !== DeploymentState.UnderConstruction &&
                                <span className="row mb-2 ml-0 mr-0">
                                    <a onClick={viewRollouts}>
                                        <span className="interactive-text">
                                            View Rollouts
                                        </span>
                                    </a>
                                </span>
                            }
                            {
                                props.reusable &&
                                <span className="row mb-2 ml-0 mr-0">
                                    <a onClick={copyDeployment}>
                                        <span className="interactive-text">
                                            Copy
                                        </span>
                                    </a>
                                </span>
                            }
                            <span className="row ml-0 mr-0">
                                <a onClick={editDeployment}>
                                    <span className="interactive-text">
                                        Edit
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
        </React.Fragment>
    )
}

export default DeploymentListTableItem;
