import {combineReducers} from 'redux';
import settingsGroupsReducer from "./SettingsGroups/reducers/SettingsGroupsReducer";
import settingGroupReducer from "./SettingGroup/reducers/SettingGroupReducer";
import cardReducer from "./Card/reducers/CardReducer";
import deployReducer from "./Deploy/reducer/DeployReducer";
import deploymentReducer from "./Deployment/reducer/DeploymentReducer";
import deploymentListReducer from "./DeploymentList/reducer/DeploymentListReducer";
import rolloutListReducer from "./RolloutList/reducer/RolloutListReducer";
import rolloutReducer from "./Rollout/reducer/RolloutReducer";
import rolloutReportReducer from "./RolloutReport/reducer/RolloutReportReducer";
import rollbackReducer from "./Rollback/reducer/RollbackReducer";
import rollbackListReducer from "./RollbackList/reducer/RollbackListReducer";
import rollbackReportReducer from "./RollbackReport/reducer/RollbackReportReducer";
import rdmSiteListReducer from "./RdmSiteNameList/reducer/RdmSiteListReducer";
import rdmInfoListReducer from "./RdmInfoList/reducer/RdmInfoListReducer";
import rdmInfoReducer from "./RdmInfo/reducer/RdmInfoReducer";
import rdmInfoLookupReducer from "./RdmInfoLookup/reducer/RdmInfoLookupReducer";
import modalReducer from "./Modal/reducer/ModalReducer";

const RootReducer = combineReducers({
    settingsGroups: settingsGroupsReducer,
    settingGroup: settingGroupReducer,
    card: cardReducer,
    deploy: deployReducer,
    deployment: deploymentReducer,
    deploymentList: deploymentListReducer,
    rolloutList: rolloutListReducer,
    rollout: rolloutReducer,
    rolloutReport: rolloutReportReducer,
    rollback: rollbackReducer,
    rollbackList: rollbackListReducer,
    rollbackReport: rollbackReportReducer,
    rdmSiteList: rdmSiteListReducer,
    rdmInfoList: rdmInfoListReducer,
    rdmInfoLookup: rdmInfoLookupReducer,
    rdmInfo: rdmInfoReducer,
    modal: modalReducer
});

export default RootReducer;
