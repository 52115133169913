import {CARD_STORE_STATE, CardDispatchTypes, ISettingsGroupCard} from "./CardActionTypes";
import { Dispatch } from 'redux';

export const setCard = (item: ISettingsGroupCard) => async (dispatch: Dispatch<CardDispatchTypes>) => {
    dispatch({
        type: CARD_STORE_STATE.SUCCESS,
        loading: false,
        data: item,
        error: null
    })
}
