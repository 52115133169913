import React from "react";
import {DeploymentEntry, DeploymentState} from "../../../../api/x-control";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";

const DeploymentComments = (props: DeploymentEntry) => {

    const dispatch = useDispatch()

    const onCommentsAdded = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const comments = event.target.value;

        const entry: DeploymentEntry = {
            ...props,
            comments
        }

        dispatch(setDeployment(entry));
    }

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col">
                    <p className='details'>
                        Comments?
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col mr-3 ml-2">
                    <textarea
                        className="w-100 comments-field"
                        value={props.comments}
                        onChange={onCommentsAdded}
                        disabled={props.state !== DeploymentState.UnderConstruction}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DeploymentComments;
