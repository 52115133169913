import React from "react";
import {RollbackEntry, RolloutEntryState} from "../../../api/x-control";
import moment from "moment";
import {useDispatch} from "react-redux";
import {
    getReportForRollback,
    nullifyRollbackReportLink
} from "../../../store/RollbackReport/actions/RollbackReportActions";

const RollbackListTableItem = (props: RollbackEntry) => {

    const dispatch = useDispatch();

    const downloadReport = async () => {
        dispatch(nullifyRollbackReportLink())
        // @ts-ignore
        const reportLink: string = await dispatch(getReportForRollback({
            id: props.rolloutId,
            rollbackId: props.id
        }))
        const link = document.createElement('a');

        if (link.download !== undefined && props.date !== undefined) {
            link.setAttribute('href', reportLink);
            const fileName = `Rollback: ${props.id}`;
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
        }
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-md-2">
                    <p className="table-values align-self-center fadeIn">
                        {props.id}
                    </p>
                </div>
                <div className="col-md-4">
                    <p className="table-values align-self-center fadeIn">
                        {moment.unix(props.date).format('LLLL')}
                    </p>
                </div>
                <div className="col-md-3">
                    <p className="table-values align-self-center fadeIn">
                        {props.state.split(/(?=[A-Z])+/g).join(" ")}
                    </p>
                </div>
                <div className="col-md-3">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            {
                                props.state === RolloutEntryState.Error &&
                                <span className="row ml-0 mr-0">
                                    <a onClick={downloadReport}>
                                        <span className="interactive-text">
                                            Download Report
                                        </span>
                                    </a>
                                </span>
                            }
                            {
                                props.state === RolloutEntryState.Deployed &&
                                <span className="row ml-0 mr-0">
                                    <a onClick={downloadReport}>
                                        <span className="interactive-text">
                                            Download Report
                                        </span>
                                    </a>
                                </span>
                            }
                            {
                                props.state === RolloutEntryState.InProgress &&
                                <span className="row ml-0 mr-0">
                                    N/A
                                </span>

                            }
                            {
                                props.state === RolloutEntryState.Pending &&
                                <span className="row ml-0 mr-0">
                                    N/A
                                </span>
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator"/>
        </React.Fragment>
    )
}

export default RollbackListTableItem;
