import {DeploymentsApi, RdmApi, SettingsApi} from "../../api/x-control";

export default class ApiModel {

    private static instance: ApiModel;
    private static settingsApi: SettingsApi;
    private static deploymentsApi: DeploymentsApi;
    private static rdmApi: RdmApi;
    private static options: ApiPluginOptions | undefined;

    public static initialise(options: ApiPluginOptions | undefined) {
        ApiModel.options = options;

        ApiModel.getSettingsApi();
        ApiModel.getDeploymentsApi();
        ApiModel.getRdmApi();
    }

    public static getInstance(): ApiModel {
        if(!ApiModel.instance) {
            ApiModel.instance = new ApiModel();
        }
        return ApiModel.instance;
    }

    public static getSettingsApi(): SettingsApi {
        if (!ApiModel.settingsApi) {
            ApiModel.settingsApi = new SettingsApi({
                basePath: ApiModel.options?.basePath
            });
        }

        return ApiModel.settingsApi;
    }

    public static getRdmApi(): RdmApi {
        if (!ApiModel.rdmApi) {
            ApiModel.rdmApi = new RdmApi({
                basePath: ApiModel.options?.basePath
            });
        }

        return ApiModel.rdmApi;
    }

    public static getDeploymentsApi(): DeploymentsApi {
        if (!ApiModel.deploymentsApi) {
            ApiModel.deploymentsApi = new DeploymentsApi({
                basePath: ApiModel.options?.basePath
            })
        }

        return ApiModel.deploymentsApi;
    }

    public static getBasePath(): string | undefined {
        return ApiModel.options?.basePath;
    }
}

interface ApiPluginOptions {
    basePath: string | undefined;
}
