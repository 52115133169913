/** React */
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
/** Pages */
import SiteManagementList from "../../Pages/SiteManagementList/SiteManagementList";
import SettingsGroup from "../../Pages/SettingsGroup/ViewSettingsGroup/SettingsGroup";
import CreateSettingsGroupModal from "../../Pages/SettingsGroup/CreateSettingsGroup/CreateSettingsGroupModal";
import EditSettingsGroupModal from "../../Pages/SettingsGroup/EditSettingsGroup/EditSettingsGroupModal";
import Deployment from "../../Pages/Deployment/Deployment";
import DeploymentList from "../../Pages/DeploymentList/DeploymentList";
import RolloutList from "../../Pages/RolloutList/RolloutList";
import RollbackListModal from "../../Pages/RollbackList/RollbackListModal";
import EditSiteManagementDataManagerModal
    from "../../Pages/SiteManagementDataManager/EditDataManager/EditSiteManagementDataManagerModal";
/** Custom Routes and Layouts */
import DefaultLayout from "../../Layout/DefaultLayout";
import CustomRoute from "./CustomRoute";
/** Misc imports */
import {routeNames} from "../routeNames";
import SiteManagementSite from "../../Pages/SiteManagementSite/SiteManagementSite";
import CreateSiteManagementDataManagerModal
    from "../../Pages/SiteManagementDataManager/CreateDataManager/CreateSiteManagementDataManagerModal";
import Login from "../../Pages/Login/Login";
import NoSideBarLayout from "../../Layout/NoSideBarLayout";

//ToDo: Add 404 page.
const Routes = () => {
    const [renderRoutes, setRenderRoutes] = useState<boolean>(false);
    const [gotAccessToken, setGotAccessToken] = useState<boolean>(false);
    let autoLoginTimeout: any | undefined = undefined;
    const timer = useRef(autoLoginTimeout);
    const history = useHistory();
    const [seconds, setSeconds] = useState(0);

    /** Start a timer to get the expiry string.
     *  The custom route wrapper for the route checks if the expiry time exists. If it doesn't
     *  it redirects the user to log back into the app. On initial login, it will fire the auth middleware
     *  to see if the user is authenticated before the expiry string is set in local storage. (So they will never
     *  be authenticated even when they are until this exists.
     *  Hack way to get around this as we can async local storage is to start a timer, and wait for the string
     *  in local storage to not be null. Once it is not null, we render the routes and auth middleware will always find
     *  the expiry string.*/
    useEffect(() => {
        timer.current = window.setInterval(() => {
            setSeconds(seconds => seconds + 1);
            getExpiryString()
        }, 250);
        return () => { // Return callback to run on unmount.
            window.clearInterval(timer.current);
        };
    }, [])

    const getExpiryString = () => {
        const expiry = localStorage.getItem('expires_at');

        if(expiry) {
            setSeconds(0);
            clearInterval(timer.current);
            setRenderRoutes(true)
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");

        if (accessToken) {
            setGotAccessToken(true);
        }
    }, [localStorage.getItem("access_token")])

    return (
        <div>
            {
                renderRoutes && gotAccessToken &&
                <Switch>
                    <CustomRoute
                        exact
                        path={routeNames.login.path}
                        component={Login}
                        layout={NoSideBarLayout}
                    />
                    <CustomRoute
                        path={`${routeNames.siteManagementSite.path}/:siteId`}
                        render={(): JSX.Element => (
                            <React.Fragment>
                                <CustomRoute
                                    path={`${routeNames.siteManagementSite.path}/:siteId`}
                                    component={SiteManagementSite}
                                />
                                <Switch>
                                    <CustomRoute
                                        path={`${routeNames.siteManagementSite.path}/:siteId${routeNames.siteManagementDataManagerCreate.path}`}
                                        component={CreateSiteManagementDataManagerModal}
                                    />
                                    <CustomRoute
                                        path={`${routeNames.siteManagementSite.path}/:siteId${routeNames.siteManagementDataManagerEdit.path}/:dmId`}
                                        component={EditSiteManagementDataManagerModal}
                                    />
                                </Switch>
                            </React.Fragment>
                        )}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        path={routeNames.siteManagementList.path}
                        render={(): JSX.Element => (
                            <React.Fragment>
                                <CustomRoute
                                    path={routeNames.siteManagementList.path}
                                    component={SiteManagementList}
                                />
                                <Switch>
                                    <CustomRoute
                                        path={`${routeNames.siteManagementList.path}${routeNames.siteManagementDataManagerCreate.path}`}
                                        component={CreateSiteManagementDataManagerModal}
                                    />
                                </Switch>
                            </React.Fragment>
                        )}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        path={`${routeNames.deploymentsList.path}/:id${routeNames.rolloutList.path}`}
                        render={(): JSX.Element => (
                            <React.Fragment>
                                <CustomRoute
                                    path={`${routeNames.deploymentsList.path}/:id${routeNames.rolloutList.path}`}
                                    component={RolloutList}
                                />
                                <Switch>
                                    <CustomRoute
                                        path={`${routeNames.deploymentsList.path}/:id${routeNames.rolloutList.path}/:rolloutId${routeNames.rollbackList.path}`}
                                        component={RollbackListModal}
                                    />
                                </Switch>
                            </React.Fragment>
                        )}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        path={routeNames.settingsGroup.path}
                        render={(): JSX.Element => (
                            <React.Fragment>
                                <CustomRoute
                                    path={routeNames.settingsGroup.path}
                                    component={SettingsGroup}
                                />
                                <Switch>
                                    <CustomRoute
                                        path={routeNames.settingsGroupCreate.path}
                                        component={CreateSettingsGroupModal}
                                    />
                                    <CustomRoute
                                        path={`${routeNames.settingsGroupEdit.path}/:id`}
                                        component={EditSettingsGroupModal}
                                    />
                                </Switch>
                            </React.Fragment>
                        )}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        exact
                        path={routeNames.deploymentCopy.path}
                        component={Deployment}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        exact
                        path={routeNames.deploymentCreate.path}
                        component={Deployment}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        exact
                        path={`${routeNames.deploymentEdit.path}/:id`}
                        component={Deployment}
                        layout={DefaultLayout}
                    />
                    <CustomRoute
                        exact
                        path={routeNames.deploymentsList.path}
                        component={DeploymentList}
                        layout={DefaultLayout}
                    />
                    <Redirect
                        to={routeNames.login.path}
                    />
                </Switch>
            }
            {
                !renderRoutes &&
                    <Login />
            }
        </div>
    )
}

export default Routes;

window.addEventListener('beforeunload', () => {
    if(window.location.pathname === routeNames.login.path) return;
    if(window.location.pathname === "/auth0_logout") return;
    const queries = window.location.href.split("?")[1];
    const query = queries ? `?${queries}` : undefined;
    const savedPath = `${window.location.pathname}${query}`;
    localStorage.setItem("redirection_url", savedPath);
})
