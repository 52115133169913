import React, {useEffect} from "react";
import WithServiceState from "../../../Helpers/HOCs/ServiceState/WithServiceState";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getSettingsGroups} from "../../../../store/SettingsGroups/actions/SettingsGroupsActions";
import SettingsGroupTable from "./SettingsGroupTable";

const WrappedTable = WithServiceState(SettingsGroupTable);

const SettingsGroup = () => {

    const dispatch = useDispatch();
    const settingsGroupsState = useSelector((state: RootStore) => state.settingsGroups);


    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        dispatch(getSettingsGroups());
    }

    return (
        <div className="page-wrapper">
            <div className="background">
                <WrappedTable
                    {...settingsGroupsState}
                />
            </div>
        </div>
    )
}

export default SettingsGroup;
