import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {
    DeploymentEntry,
    DeploymentState,
    DeviceSetting,
    MetaMatch,
    SettingMatch,
    SettingsGroup
} from "../../../api/x-control";
import {setCard} from "../../../store/Card/actions/CardActions";
import {SettingGroupCardActionEnum} from "../../../store/Card/actions/CardActionTypes";
import {setDeployment} from "../../../store/Deployment/actions/DeploymentActions";
import MetaMatchContent from "./MetaMatchContent";
import SettingsMatchContent from "./SettingsMatchContent";
import DeviceSettingContent from "./DeviceSettingsContent";

const SettingsGroupDetailRow = (props: IMoveableCard) => {
    const [controlText, setControlText] = useState("Expand...");
    const [toggled, setToggled] = useState(false);

    const dispatch = useDispatch();
    const deploymentStore = useSelector((state: RootStore) => state.deployment);

    const toggleRowView = (value: boolean) => {
        setToggled(value = !value);

        const expandCollapse = toggled ? 'Expand...' : 'Collapse...';

        setControlText(expandCollapse);
    }

    const removeSettingCard = () => {
        const deployment = deploymentStore.data;
        if (!deployment) return;

        dispatch(setCard({
            ...props.settingsGroup,
            action: SettingGroupCardActionEnum.Removed
        }))

        const index = deployment.settingsGroups.findIndex((el: SettingsGroup) => el.id === props.settingsGroup.id);

        //Doesn't exist anyway
        if (index < 0) return;

        deployment.settingsGroups.splice(index, 1);
        const newSettings = deployment.settingsGroups;

        const entry: DeploymentEntry = {
            ...deployment,
            settingsGroups: newSettings
        }

        // Update it locally
        dispatch(setDeployment(entry));
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-sm-1 d-flex">
                    {
                        props.moveable &&
                        <div className="align-self-center pl-3">
                            <svg
                                className="card-handle"
                                viewBox="0 0 24 24"
                                version="1.1" x="0px" y="0px"
                            >
                                <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <path
                                        d="M13,14 L13,18 L17,18 L12,23 L7,18 L11,18 L11,14 L13,14 Z M18,11 L18,7 L23,12 L18,17 L18,13 L14,13 L14,11
                                        L18,11 Z M6,13 L6,17 L1,12 L6,7 L6,11 L10,11 L10,13 L6,13 Z M17,6 L13,6 L13,10 L11,10 L11,6 L7,6 L12,1 L17,6 Z"
                                        fill="currentColor"
                                    />
                                </g>
                            </svg>
                        </div>
                    }
                    {
                        !props.moveable &&
                        <p className="table-values align-self-center">
                            -
                        </p>
                    }

                </div>
                <div className="col-md-1 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.settingsGroup.name}
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            props.settingsGroup.settingsMatches.length === 0 &&
                            <p className="table-values align-self-center">
                                No Settings Matches Exist
                            </p>
                        }
                        {
                            toggled &&
                            props.settingsGroup.settingsMatches.length > 0 &&
                            props.settingsGroup.settingsMatches.map((el: SettingMatch, index: number) => {
                                return <SettingsMatchContent {...el} key={index} />
                            })
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            props.settingsGroup.metaMatches.length === 0 &&
                            <p className="table-values align-self-center">
                                No Meta Matches Exist
                            </p>
                        }
                        {
                            toggled &&
                            props.settingsGroup.metaMatches.length > 0 &&
                            props.settingsGroup.metaMatches.map((el: MetaMatch, index: number) => {
                                return <MetaMatchContent {...el} key={index} />
                            })
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            props.settingsGroup.settings.length === 0 &&
                            <p className="table-values align-self-center fadeIn">
                                No Settings Exist
                            </p>
                        }
                        {
                            toggled &&
                            props.settingsGroup.settings.length > 0 &&
                            props.settingsGroup.settings.map((el: DeviceSetting, index: number) => {
                                return <DeviceSettingContent {...el} key={index} />
                            })
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row mb-2 ml-0 mr-0">
                                <a onClick={() => toggleRowView(toggled)}>
                                    <span className="interactive-text">
                                        {controlText}
                                    </span>
                                </a>
                            </span>
                            {
                                props.moveable &&
                                <span className="row ml-0 mr-0">
                                    <a onClick={removeSettingCard}>
                                        <span className="interactive-text">
                                            Remove
                                        </span>
                                    </a>
                                </span>
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
        </React.Fragment>
    )
}

export default SettingsGroupDetailRow;

export interface IMoveableCard {
    settingsGroup: SettingsGroup;
    moveable: boolean;
}
