import {Dispatch} from 'redux';
import {SETTING_GROUP_STORE_STATE, SettingGroupDispatchType} from "./SettingGroupActionTypes";
import {deleteData, fetchData, saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import {DeviceSetting, MetaMatch, NumericComparison, SettingMatch, SettingsGroup} from "../../../api/x-control";
import ApiModel from "../../ApiModel/ApiModel";
import {v4 as uuidv4} from 'uuid';
import Store from "../../Store";

export const nullifySettingGroup = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: null
    })
}

export const getSettingGroupById = (id: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    try {
        const success = await fetchData(
            SETTING_GROUP_STORE_STATE,
            dispatch,
            () => ApiModel.getSettingsApi().getSettingsGroup(id)
        )

        if(success) {
            const settingsGroup = Store.getState().settingGroup.data;
            if (!settingsGroup) return;

            return settingsGroup;
        }
    }
    catch (e) {
        dispatch({
            type: SETTING_GROUP_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

export const deleteSettingsGroupOnService = ({id, callback}: DeleteSettingsGroupArgs) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {

    try {
        const success = await deleteData(
            SETTING_GROUP_STORE_STATE,
            dispatch,
            () => ApiModel.getSettingsApi().deleteSettingsGroup(id)
        )

        if (success && callback) {
            callback();
        }
    }

    catch (e) {
        dispatch({
            type: SETTING_GROUP_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

export const saveSettingGroup = (callback?: () => void) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    try {
        const success = await saveData(
            SETTING_GROUP_STORE_STATE,
            dispatch,
            () => ApiModel.getSettingsApi().saveSettingsGroup(settingsGroup),
        )

        if(success && callback) {
            callback();
        }
    }
    catch (e) {
        dispatch({
            type: SETTING_GROUP_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}

export const createNewSettingsGroup = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    dispatch({
        type: SETTING_GROUP_STORE_STATE.LOADING,
        error: null,
        loading: true
    })

    setTimeout(() => {
        const settingsGroup: SettingsGroup = {
            name: "New Settings Group",
            type: SettingsGroupType.RDMSettingsGroup,
            id: uuidv4(),
            settings: [],
            settingsMatches: [],
            metaMatches: []
        }

        dispatch({
            type: SETTING_GROUP_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: settingsGroup
        })
    }, 125)
}


//When getting each individual group when the expand button is clicked.
export const setGroupDataToNull = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: null
    })
}


export const setSettingsGroupName = (name: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;

    if(!settingsGroup) return;

    settingsGroup.name = name;

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: settingsGroup
    })
}

/** Device Setting Logic */
//Add new device setting
export const addDeviceSetting = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const deviceSetting: DeviceSetting = {
        type: "RdmDeviceSetting",
        name: `New Device Setting ${settingsGroup.settings.length}`,
        value: `Device Value ${settingsGroup.settings.length}`
    }

    settingsGroup.settings.push(deviceSetting);

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

//Remove device setting
export const removeDeviceSetting = (args: RemoveSettingArgs) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    //Remove the device setting
    settingsGroup.settings.splice(args.index, 1);
    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });

}

//Set device setting
export const setDeviceSetting = (setting: DeviceSetting) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settings.findIndex((el: DeviceSetting) => el.value === setting.value && el.name === setting.name);
    if(index < 0) return;

    //Update setting at index
    settingsGroup.settings[index] = setting;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setDeviceSettingName = (setting: DeviceSetting, name: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settings.findIndex((el: DeviceSetting) => el.value === setting.value && el.name === setting.name);
    if(index < 0) return;

    //Update setting at index
    settingsGroup.settings[index].name = name;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setDeviceSettingValue = (setting: DeviceSetting, value: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settings.findIndex((el: DeviceSetting) => el.value === setting.value && el.name === setting.name);
    if(index < 0) return;

    //Update setting at index
    settingsGroup.settings[index].value = value;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

/** Settings Match Logic */
//Add Settings Match
export const addSettingsMatch = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const settingsMatch: SettingMatch = {
        type: "RdmSettingMatch",
        name: `New Settings Match ${settingsGroup.settingsMatches.length}`,
        value: `Settings Match Value ${settingsGroup.settingsMatches.length}`,
        comparison: NumericComparison.Equal,
        inverse: false
    };

    settingsGroup.settingsMatches.push(settingsMatch);

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

//Remove Settings Match
export const removeSettingsMatch = (args: RemoveSettingArgs) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    //Remove the device setting
    settingsGroup.settingsMatches.splice(args.index, 1);

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

//Set Settings Match
export const setSettingsMatch = (setting: SettingMatch) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settingsMatches.findIndex(
        (el: SettingMatch) => el.value === setting.value && el.name === setting.name
    );

    if(index < 0) return;

    //Update setting at index
    settingsGroup.settingsMatches[index] = setting;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}


export const setSettingsMatchName = (setting: SettingMatch, name: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settingsMatches.findIndex(
        (el: SettingMatch) => el.value === setting.value && el.name === setting.name && el.inverse === setting.inverse
    );
    if(index < 0) return;

    //Update setting at index
    settingsGroup.settingsMatches[index].name = name;

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setSettingsMatchValue = (setting: SettingMatch, value: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settingsMatches.findIndex(
        (el: SettingMatch) => el.value === setting.value && el.name === setting.name && el.inverse === setting.inverse
    );
    if(index < 0) return;

    //Update setting at index
    settingsGroup.settingsMatches[index].value = value;

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setSettingsMatchComparison = (setting: SettingMatch, value: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.settingsMatches.findIndex(
        (el: SettingMatch) => el.value === setting.value && el.name === setting.name && el.inverse === setting.inverse
    );
    if(index < 0) return;

    //Update setting at index
    switch (value) {
        case NumericComparison.GreaterThanEqual:
            settingsGroup.settingsMatches[index].comparison = NumericComparison.GreaterThanEqual;
            break;
        case NumericComparison.GreaterThan:
            settingsGroup.settingsMatches[index].comparison = NumericComparison.GreaterThan;
            break;
        case NumericComparison.Equal:
            settingsGroup.settingsMatches[index].comparison = NumericComparison.Equal;
            break;
        case NumericComparison.LessThanEqual:
            settingsGroup.settingsMatches[index].comparison = NumericComparison.LessThanEqual;
            break;
        case NumericComparison.LessThan:
            settingsGroup.settingsMatches[index].comparison = NumericComparison.LessThan;
            break;
    }


    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}


/** Meta Match Logic */
//Add Meta Match
export const addMetaMatch = () => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const metaMatch: MetaMatch = {
        type: "RdmMetaMatch",
        name: `New Meta Match ${settingsGroup.metaMatches.length}`,
        value: `Meta Match Value ${settingsGroup.metaMatches.length}`,
        inverse: false
    };

    settingsGroup.metaMatches.push(metaMatch);

    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

//Remove Meta Match
export const removeMetaMatch = (args: RemoveSettingArgs) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    //Remove the device setting
    settingsGroup.metaMatches.splice(args.index, 1);

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

//Set Meta Match
export const setMetaMatch = (setting: MetaMatch) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.metaMatches.findIndex(
        (el: MetaMatch) => el.value === setting.value && el.name === setting.name
    );
    if(index < 0) return;

    //Update setting at index
    settingsGroup.metaMatches[index] = setting;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setMetaMatchName = (setting: MetaMatch, name: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.metaMatches.findIndex(
        (el: MetaMatch) => el.value === setting.value && el.name === setting.name && el.inverse === setting.inverse
    );
    if(index < 0) return;

    //Update setting at index
    settingsGroup.metaMatches[index].name = name;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export const setMetaMatchValue = (setting: MetaMatch, value: string) => async (dispatch: Dispatch<SettingGroupDispatchType>) => {
    const settingsGroup = Store.getState().settingGroup.data;
    if (!settingsGroup) return;

    const index = settingsGroup.metaMatches.findIndex(
        (el: MetaMatch) => el.value === setting.value && el.name === setting.name && el.inverse === setting.inverse
    );
    if(index < 0) return;

    //Update setting at index
    settingsGroup.metaMatches[index].value = value;

    //Update state
    dispatch({
        type: SETTING_GROUP_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: settingsGroup
    });
}

export enum SettingsGroupType {
    RDMSettingsGroup = "RdmSettingsGroup"
}

export interface RemoveSettingArgs {
    index: number;
}

export interface DeleteSettingsGroupArgs {
    id: string;
    callback?: () => void;
}
