import React from "react";


const Loading = ({loadingText}: LoadingProps) => {
    return (
        <div className="text-center h-100 loader-wrapper">
            <div className="loader-position-wrapper">
                <div className="loader">
                    <div className="loading-cube loading"/>
                    <div className="loading-cube loading"/>
                </div>
                <p className="uppercasify loader-text">
                    { loadingText }
                </p>
            </div>
        </div>
    )
}

export default Loading;

export interface LoadingProps {
    loadingText: string;
}
