import React, {useCallback, useState} from 'react';
import {useDispatch} from "react-redux";
import {DustbinState, ItemTypes} from "../../DragAndDrop/DragAndDropUtils";
import {DeploymentEntry} from "../../../api/x-control";
import update from "immutability-helper";
import DeploymentGroupSettings from "./DeploymentGroupSettings";

const DeploymentGroupSettingsWrapper = (props: DeploymentEntry) => {
    const dispatch = useDispatch();

    const [droppedBoxNames, setDroppedBoxNames] = useState<string[]>([]);
    const [dustbin, setDustbin] = useState<DustbinState[]>([
        {
            accepts: [ItemTypes.MOVING_GROUP_SETTING],
            lastDroppedItem: null
        }
    ]);

    const handleDrop = useCallback (
        (index: number, item: {name: string}) => {
            const { name } = item;

            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            )
            setDustbin(
                update(dustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item
                        }
                    }
                })
            );
        }, [droppedBoxNames, dustbin]
    )

    return (
        <React.Fragment>
            {
                dustbin.map((el: DustbinState, index: number) => (
                    <DeploymentGroupSettings
                        dustbin={{accept: el.accepts, lastDroppedItem: el.lastDroppedItem, onDrop: (item => handleDrop(index, item))}}
                        deployment={props}
                        key={index}
                    />
                ))
            }
        </React.Fragment>
    )
}

export default DeploymentGroupSettingsWrapper;
