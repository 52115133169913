import React from "react";
import {Route} from 'react-router-dom'
import Auth from "../../../store/Auth/Auth";
import {useAuth0} from "@auth0/auth0-react";

const CustomRoute = ({layout, component, render, ...props}: CustomRouteProps) => {

    const {loginWithRedirect} = useAuth0();
    let componentToUse = component;

    return (
        <Route
            {...props}
            render={(nestedProps): JSX.Element => {
                if(!Auth.isAuthenticated()) {
                    loginWithRedirect();
                }

                let renderComponent: any;

                if(componentToUse) {
                    renderComponent = React.createElement(componentToUse, nestedProps);
                }
                else if (render) {
                    renderComponent = React.createElement(render, nestedProps);
                }
                if(layout && renderComponent) {
                    return React.createElement(layout, nestedProps, renderComponent)
                }

                return renderComponent;
            }}
        />
    )
}

export default CustomRoute;

export interface CustomRouteProps {
    exact?: any;
    path?: string;
    layout?: any;
    component?: any;
    render?: any;
}
