import {DeploymentEntry} from "../../../api/x-control";
import {StoreState, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

export const DEPLOY_STORE_STATE: StoreState = {
    LOADING: "DEPLOY_STORE_LOADING",
    ERROR: "DEPLOY_STORE_ERROR",
    SUCCESS: "DEPLOY_STORE_SUCCESS"
}

interface DeployLoading extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOY_STORE_STATE.LOADING
}

interface DeployError extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOY_STORE_STATE.ERROR
}

interface DeploySuccess extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOY_STORE_STATE.SUCCESS
}

export type DeployDispatchTypes = DeploySuccess | DeployError | DeployLoading;
