import {saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import {RolloutsPageRequest} from "../../../api/x-control";
import ApiModel from "../../ApiModel/ApiModel";
import {Dispatch} from 'redux';
import {ROLLOUT_LIST_STORE_STATE, RolloutListDispatchTypes} from "./RolloutListActionTypes";

export const getPagedRollouts = (request: RolloutsPageRequest) => async (dispatch: Dispatch<RolloutListDispatchTypes>) => {
    try {
        await saveData(
            ROLLOUT_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getRolloutsPaged(request)
        )
    }

    catch (e) {
        dispatch({
            type: ROLLOUT_LIST_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const nullifyRollouts = () => async (dispatch: Dispatch<RolloutListDispatchTypes>) => {
    dispatch({
        type: ROLLOUT_LIST_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}
