import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {routeNames} from "../../Navigation/routeNames";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import {useModal} from "../../Modal/hooks/useModal";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import {getSettingsGroups} from "../../../store/SettingsGroups/actions/SettingsGroupsActions";
import {deleteSettingsGroupOnService} from "../../../store/SettingGroup/actions/SettingGroupActions";
import {SettingsGroupName} from "../../../api/x-control";
import {useDispatch} from "react-redux";

const SettingsGroupRowDetailActions = (props: SettingsGroupName) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { isShown, toggle } = useModal();
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });

    const editSettingGroup = () => {
        history.push({
            pathname: `${routeNames.settingsGroupEdit.path}/${props.id}`,
            state: {
                from: history.location.pathname
            }
        })
    }

    const deleteSettingGroup = () => {
        setModalProps({
            headerText: "Delete Settings Group",
            messages: [`Are you sure you want to delete ${props.name}`],
            onConfirm: () => {
                dispatch(deleteSettingsGroupOnService({
                    id: props.id,
                    callback: () => {
                        dispatch(getSettingsGroups())
                    }}))
            },
            confirmText: "Yes",
            cancelText: "No",
            modalSize: ModalSize.small
        })

        toggle();
    }
    return(
        <React.Fragment>
            <span className="pl-3 red-text pr-3">
                |
            </span>
            <a
                className="interactive-text"
                onClick={editSettingGroup}
            >
                <span>
                    Edit
                </span>
            </a>
            <span className="pl-3 red-text pr-3">
                |
            </span>
            <a
                className="interactive-text"
                onClick={deleteSettingGroup}
            >
                <span>
                    Delete
                </span>
            </a>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default SettingsGroupRowDetailActions;
