import {RdmSiteName} from "../../../api/x-control";
import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";

export const RDM_SITE_LIST_STORE_STATE: StoreState = {
    LOADING: "RDM_SITE_LIST_LOADING",
    ERROR: "RDM_SITE_LIST_ERROR",
    SUCCESS: "RDM_SITE_LIST_SUCCESS"
}

export interface RdmSiteListLoading extends StoreServiceData<RdmSiteName[]> {
    type: typeof RDM_SITE_LIST_STORE_STATE.LOADING;
}

export interface RdmSiteListError extends StoreServiceData<RdmSiteName[]> {
    type: typeof RDM_SITE_LIST_STORE_STATE.ERROR;
}

export interface RdmSiteListSuccess extends StoreServiceData<RdmSiteName[]> {
    type: typeof RDM_SITE_LIST_STORE_STATE.SUCCESS;
}

export type RdmSiteListDispatchTypes = RdmSiteListSuccess | RdmSiteListError | RdmSiteListLoading;
