import React, {useEffect, useState} from "react";
import {ApproveUser, DeploymentEntry, DeploymentState} from "../../../api/x-control";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../store/Deployment/actions/DeploymentActions";
import DeploymentApprovalSection from "./DeploymentDetailSections/DeploymentApprovalSection";
import DeploymentApprovalList from "./DeploymentDetailSections/DeploymentApprovalList";
import DeploymentAddApproval from "./DeploymentDetailSections/DeploymentAddApproval";
import DeploymentReusableToggle from "./DeploymentDetailSections/DeploymentReusableToggle";
import DeploymentSchedule from "./DeploymentDetailSections/DeploymentSchedule";
import DeploymentDelayExecution from "./DeploymentDetailSections/DeploymentDelayExecution";
import DeploymentStateInfo from "./DeploymentDetailSections/DeploymentStateInfo";
import DeploymentComments from "./DeploymentDetailSections/DeploymentComments";
import Auth from "../../../store/Auth/Auth";
import jwt_decode from "jwt-decode";

const DeploymentDetails = (props: DeploymentEntry) => {
    const dispatch = useDispatch();

    const [showAddApproval, setShowAddApproval] = useState<boolean>(true);

    useEffect(() => {
        const id = Auth.getIdToken();
        if(!id) return;
        const decoded: any = jwt_decode(id);

        const index = props.approvalUsers.findIndex((el: ApproveUser) => el.name === decoded.email);

        //This user doesn't exist in the approval. So don't hide the button
        if(index < 0)  {
            setShowAddApproval(true);
            return;
        }

        //Otherwise, we hide it
        setShowAddApproval(false);

    }, [props])


    const changeDeploymentName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const entry: DeploymentEntry = {
            ...props,
            name: event.target.value
        }

        dispatch(setDeployment(entry))
    }

    return (
        <React.Fragment>
            <div className="h-100 pb-3">
                <div className="row mt-1">
                    <div className="col">
                        <p className="details">
                            Name
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input
                            type="text"
                            className="input-field mr-4"
                            value={props.name}
                            onChange={changeDeploymentName}
                            disabled={props.state !== DeploymentState.UnderConstruction}
                        />
                    </div>
                </div>
                <div className="row mt-1">
                    <DeploymentApprovalSection {...props} />
                </div>
                <DeploymentApprovalList {...props} />
                {
                    props.id > 0 && !props.approved && showAddApproval &&
                    <div className="row mt-3">
                        <DeploymentAddApproval {...props} />
                    </div>
                }
                <div className="row mt-3">
                    <DeploymentReusableToggle {...props} />
                </div>
                <DeploymentSchedule {...props} />
                <DeploymentDelayExecution {...props} />
                <DeploymentStateInfo {...props} />
                <DeploymentComments {...props} />
            </div>
        </React.Fragment>
    )
}

export default DeploymentDetails;
