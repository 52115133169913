import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid";

const Toggle = ({checked, onChange, size, disabled}: ToggleProps) => {
    const buttonId = nanoid();

    const [toggleClassName, setToggleClassName] = useState('')

    useEffect(() => {
        switch (size) {
            case ToggleSize.Large:
                setToggleClassName('dmcheck')
                break;
            case ToggleSize.Small:
                setToggleClassName('dmcheck-sm')
                break;
        }
    }, [])



    return (
        <span className="toggle-control">
            <input
                className={toggleClassName}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                id={buttonId}
                disabled={disabled}
            />
            <label htmlFor={buttonId} />
        </span>
    )
}

export default Toggle;

export interface ToggleProps {
    checked: any;
    onChange: any;
    size: ToggleSize;
    disabled?: boolean;
}

export enum ToggleSize {
    Small = 'Small',
    Large = 'Large'
}
