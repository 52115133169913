import React from 'react';

const PageHeader = ({actionName, customName, isDeployedVersion}: MainPanelHeaderProps) => {

    return (
        <div className="page-header drop-shadow-positive-y-weak">
            <h4 className="d-flex justify-content-center pt-3 uppercasify mb-0">
                {actionName}
            </h4>
            { customName &&
                <h5 className="d-flex justify-content-center fadeIn">
                    {customName}&nbsp;
                    {
                        isDeployedVersion &&
                            <React.Fragment>
                                <span>
                                    -
                                </span>
                                <span className="red-text">
                                   &nbsp;[Deployed Version]
                                </span>
                            </React.Fragment>

                    }
                </h5>
            }
        </div>
    )
}

export default PageHeader;

export interface MainPanelHeaderProps {
    actionName: string;
    customName?: string;
    isDeployedVersion?: boolean;
}
