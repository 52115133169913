import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {RollbackEntry} from "../../../api/x-control";

export const ROLLBACK_LIST_STORE_STATE: StoreState = {
    LOADING: "ROLLBACK_LIST_LOADING",
    ERROR: "ROLLBACK_LIST_ERROR",
    SUCCESS: "ROLLBACK_LIST_SUCCESS"
}

interface RollbackListLoading extends StoreServiceData<RollbackEntry[]>{
    type: typeof ROLLBACK_LIST_STORE_STATE.LOADING;
}
interface RollbackListError extends StoreServiceData<RollbackEntry[]>{
    type: typeof ROLLBACK_LIST_STORE_STATE.ERROR;
}
interface RollbackListSuccess extends StoreServiceData<RollbackEntry[]>{
    type: typeof ROLLBACK_LIST_STORE_STATE.SUCCESS;
}

export type RollbackListDispatchTypes = RollbackListError | RollbackListLoading | RollbackListSuccess;
