import React, {useCallback, useEffect, useState} from "react";
import SettingRow from "../SettingRow";
import {useDispatch} from "react-redux";
import {
    addDeviceSetting,
    addMetaMatch,
    addSettingsMatch,
} from "../../../../store/SettingGroup/actions/SettingGroupActions";

const SettingsContainer = (props: SettingsContainerProps) => {

    const dispatch = useDispatch();
    const [localData, setLocalData] = useState(props.data);

    const addRow = () => {
        switch (props.containerType) {
            case SettingsContainerType.DeviceSettings:
                dispatch(addDeviceSetting())
                break;
            case SettingsContainerType.MetaMatch:
                dispatch(addMetaMatch())
                break;
            case SettingsContainerType.SettingsMatch:
                dispatch(addSettingsMatch())
                break;
        }
    }

    useEffect(() => {
        setLocalData(props.data)
    }, [props])

    return(
        <div className="pl-1 col-md-4 pr-0">
            <div className="row ml-0">
                 <h5 className="table-keys uppercasify pb-0">
                     { props.containerName }
                 </h5>
             </div>
            <div className="settings-container-contents mt-0">
                {
                    localData.length > 0 &&
                    localData.map((el: any, index: number) => {
                        return (
                            <SettingRow
                                containerType={props.containerType}
                                data={el}
                                index={index}
                                key={index}
                            />
                        )
                    })
                }
                {
                    localData.length === 0 &&
                    <div className="align-middle mt-5">
                        <h5 className="d-flex justify-content-center pt-2">
                            Click "Add Row" to create a new
                        </h5>
                        <h5 className="d-flex justify-content-center pt-2">
                           {props.containerType.split(/(?=[A-Z])+/g).join(" ")}
                        </h5>
                    </div>
                }
            </div>
            <div className="float-right">
                <a onClick={addRow} className="interactive-text pr-3">
                    <b>+</b><span className="pl-2">Add Row</span>
                </a>
            </div>
        </div>
    )
}

export default SettingsContainer;

export interface SettingsContainerProps {
    containerName: string,
    containerType: SettingsContainerType;
    data: any[]
}

export enum SettingsContainerType {
    DeviceSettings = "DeviceSetting",
    SettingsMatch = "SettingsMatch",
    MetaMatch = "MetaMatch"
}

export interface SettingRowProp<T> {
    data: T;
    containerType: SettingsContainerType;
    index: number;
    deleteSetting: () => void;
}
