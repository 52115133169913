import {RolloutEntry} from "../../../api/x-control";
import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";

export const ROLLOUT_STORE_STATE: StoreState = {
    LOADING: "ROLLOUT_LOADING",
    ERROR: "ROLLOUT_ERROR",
    SUCCESS: "ROLLOUT_SUCCESS"
}

export interface RolloutLoading extends StoreServiceData<RolloutEntry>{
    type: typeof ROLLOUT_STORE_STATE.LOADING;
}

export interface RolloutError extends StoreServiceData<RolloutEntry>{
    type: typeof ROLLOUT_STORE_STATE.ERROR;
}

export interface RolloutSuccess extends StoreServiceData<RolloutEntry>{
    type: typeof ROLLOUT_STORE_STATE.SUCCESS;
}

export type RolloutDispatchTypes = RolloutSuccess | RolloutError | RolloutLoading;
