import {Dispatch} from 'redux';
import {RDM_SITE_LIST_STORE_STATE, RdmSiteListDispatchTypes} from "./RdmSiteListActionTypes";
import {fetchData} from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";

export const getSiteNamesFromService = () => async (dispatch: Dispatch<RdmSiteListDispatchTypes>) => {
    try {
        await fetchData(
            RDM_SITE_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getRdmApi().getSiteNames()
        )
    }
    catch (e) {
        dispatch({
            type: RDM_SITE_LIST_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}
