import React, {useEffect, useState} from "react";
import PageHeader from "../PageHeader";
import {routeNames} from "../../Navigation/routeNames";
import {DeploymentListEntry, DeploymentState} from "../../../api/x-control";
import {DDProps} from "../../../utils/sortingUtils";
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {
    getPagedDeployments,
    nullifyPagedDeployments
} from "../../../store/DeploymentList/actions/DeploymentListActions";
import DeploymentListTableItemsWrapper from "./DeploymentListTableItemsWrapper";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import {useHistory} from "react-router-dom";
import {nullifyDeploymentStore} from "../../../store/Deployment/actions/DeploymentActions";

const DeploymentItemsWrapper = WithServiceState(DeploymentListTableItemsWrapper);

const DeploymentListTable = () => {

    const [deploymentStateOptions, setDeploymentStateOptions] = useState<DDProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<DDProps[]>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [deploymentEntries, setDeploymentEntries] = useState<DeploymentListEntry[]>([]);

    const itemsPerPage = 30;
    const deploymentListStore = useSelector((state: RootStore) => state.deploymentList);

    const history = useHistory()
    const dispatch = useDispatch();

    // @ts-ignore
    useEffect(() => {
        const stateOptions = Object.values(DeploymentState);
        const options: DDProps[] = [];

        for(const opt of stateOptions) {
            options.push({
                value: opt,
                label: opt.split(/(?=[A-Z])+/g).join(" ")
            })
        }

        setDeploymentStateOptions(options);

        setSelectedOptions([]);

        //Once component is destroyed, remove state from store
        return () => dispatch(nullifyPagedDeployments())
    }, [])

    useEffect(() => {
        if(!deploymentListStore.data) return;
        if (deploymentListStore.loading) return;

        const deploymentList = deploymentListStore.data;

        const pageTotal = Math.ceil(deploymentList.totalDeployments / itemsPerPage);


        setDeploymentEntries(deploymentList.entries);
        if(deploymentList.entries.length > 0) {
            setDeploymentEntries(deploymentList.entries);
        }

        setPageCount(pageTotal);
    },[deploymentListStore.data, deploymentListStore.loading])

    const onFilterChange = (p: DDProps[]) => {
        if (p == null) {
            setSelectedOptions([])
            return;
        }

        //Guarantee that there is always one state always selected
        if(p.length === 0) {
            setSelectedOptions([])
            return;
        }
        setSelectedOptions(p)
    }

    useEffect(() => {
        changePage(0);
    }, [selectedOptions])

    const changePage = (data: any) => {
        let selected = data.selected !== undefined ? data.selected : data;
        setPageNumber(selected);
        fetchData(selected);
    }

    const fetchData = (page: number) => {

        const states: DeploymentState[] = [];

        for (const option of selectedOptions) {
            switch (option.value) {
                case DeploymentState.UnderConstruction:
                    states.push(DeploymentState.UnderConstruction)
                    break;
                case DeploymentState.Active:
                    states.push(DeploymentState.Active)
                    break;
                case DeploymentState.Complete:
                    states.push(DeploymentState.Complete)
                    break;
            }
        }

        dispatch(getPagedDeployments({
            pageNum: page,
            numPerPage: itemsPerPage,
            states
        }))
    }

    const createDeployment = () => {
        dispatch(nullifyDeploymentStore())
        history.push({
            pathname: routeNames.deploymentCreate.path,
            state: {
                from: window.location.pathname
            }
        })
    }

    return (
        <div className="h-100">
            <PageHeader
                actionName={routeNames.deploymentsList.name}
            />
            <div className='table-background'>
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <h5 className="pt-4 pl-2 ml-3">
                                    Deployment List
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="ml-auto justify-content-end mt-4 pr-5 float-right">
                                <Select
                                    className="ml-2 search-bar-lg"
                                    classNamePrefix="select-options"
                                    options={deploymentStateOptions}
                                    onChange={onFilterChange}
                                    isSearchable={false}
                                    value={selectedOptions}
                                    isMulti={true}
                                    placeholder={"Filter By Deployment State"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-contents">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-sm-2">
                                <p className="uppercasify table-keys">
                                    Name
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Schedule
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Approved?
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Reusable
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Deployment State
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                        <DeploymentItemsWrapper {...deploymentListStore}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4">
                        {
                            deploymentEntries.length > 0 &&
                            <ReactPaginate
                                pageCount={pageCount}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination"}
                                activeClassName={"active-page"}
                                onPageChange={changePage}
                                forcePage={pageNumber}
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageClassName={"other-pages"}
                                breakClassName={"other-pages"}
                                nextClassName={"next-page"}
                                previousClassName={"previous-page"}
                            />
                        }
                    </div>
                    <div className="col-md-4">
                        <div className="ml-auto justify-content-end float-right">
                            <a onClick={createDeployment} className="interactive-text pr-3">
                                <b>+</b> <span className="pl-2">Create Deployment</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DeploymentListTable;
