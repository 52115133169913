import {RDM_INFO_LOOKUP_STORE_STATE} from "../actions/RdmInfoLookupActionTypes";
import {RdmInfo} from "../../../api/x-control";
import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";

const defaultState: StoreServiceData<RdmInfo> = {
    loading: false,
    error: null,
    data: null
}

const rdmInfoLookupReducer = (state: StoreServiceData<RdmInfo> = defaultState, action: ServiceActionTypes<RdmInfo>) => {
    switch (action.type) {
        case RDM_INFO_LOOKUP_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case RDM_INFO_LOOKUP_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case RDM_INFO_LOOKUP_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default rdmInfoLookupReducer;
