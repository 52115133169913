import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";
import {ROLLOUT_LIST_STORE_STATE} from "../actions/RolloutListActionTypes";
import {RolloutsPageResponse} from "../../../api/x-control";

const defaultState: StoreServiceData<RolloutsPageResponse> = {
    loading: false,
    error: null,
    data: null
}

const rolloutListReducer = (state: StoreServiceData<RolloutsPageResponse> = defaultState, action: ServiceActionTypes<RolloutsPageResponse>) => {
    switch (action.type) {
        case ROLLOUT_LIST_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case ROLLOUT_LIST_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ROLLOUT_LIST_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default rolloutListReducer;
