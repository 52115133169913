import {DeploymentsPageResponse} from "../../../api/x-control";
import {StoreState, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

export const DEPLOYMENT_LIST_STORE_STATE: StoreState = {
    LOADING: "DEPLOYMENT_LIST_LOADING",
    ERROR: "DEPLOYMENT_LIST_ERROR",
    SUCCESS: "DEPLOYMENT_LIST_SUCCESS"
}

export interface DeploymentListLoading extends StoreServiceData<DeploymentsPageResponse>{
    type: typeof DEPLOYMENT_LIST_STORE_STATE.LOADING
}

export interface DeploymentListError extends StoreServiceData<DeploymentsPageResponse>{
    type: typeof DEPLOYMENT_LIST_STORE_STATE.ERROR
}

export interface DeploymentListSuccess extends StoreServiceData<DeploymentsPageResponse>{
    type: typeof DEPLOYMENT_LIST_STORE_STATE.SUCCESS
}

export type DeploymentListDispatchTypes = DeploymentListSuccess | DeploymentListError | DeploymentListLoading;
