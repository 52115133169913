import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {RdmInfo} from "../../../api/x-control";

export const RDM_INFO_LIST_STORE_STATE: StoreState = {
    LOADING: "RDM_INFO_LIST_LOADING",
    ERROR: "RDM_INFO_LIST_ERROR",
    SUCCESS: "RDM_INFO_LIST_SUCCESS"
}

interface RdmInfoListLoading extends StoreServiceData<RdmInfo[]>{
    type: typeof RDM_INFO_LIST_STORE_STATE.LOADING;
}
interface RdmInfoListError extends StoreServiceData<RdmInfo[]>{
    type: typeof RDM_INFO_LIST_STORE_STATE.LOADING;
}
interface RdmInfoListSuccess extends StoreServiceData<RdmInfo[]>{
    type: typeof RDM_INFO_LIST_STORE_STATE.LOADING;
}

export type RdmInfoListDispatchTypes = RdmInfoListSuccess | RdmInfoListError | RdmInfoListLoading;
