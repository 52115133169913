import React from "react";
import {StoreServiceData} from "../../../store/Helpers/Types/StoreServiceData";

const ConfirmationModal = ({onConfirm, onCancel, messages, confirmText, cancelText}: ConfirmationModalProps) => {
    return (
        <div className="pt-3 pb-2">
            {
                messages.length > 0 && messages.map((el: string) => {
                    return (
                        <p className="message" key={el}>
                            {el}
                        </p>
                    )
                })
            }
            <div className="button-wrapper pt-5">
                {
                    confirmText.length > 0 && //Can be disabled cheaply
                    <a
                        onClick={onConfirm}
                        className="interactive-text green-text button-text"
                    >
                        {confirmText}
                    </a>
                }
                <a
                    onClick={onCancel}
                    className="interactive-text button-text"
                >
                    {cancelText}
                </a>
            </div>
        </div>
    )
}

export default ConfirmationModal;

export interface ConfirmationModalProps {
    onConfirm: () => void;
    onCancel: () => void;
    messages: string[];
    cancelText: string;
    confirmText: string;
}
