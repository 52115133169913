import React from "react";
import {MetaMatch} from "../../../api/x-control";

const MetaMatchContent = ({name, value, inverse}: MetaMatch) => {

    const inverted = inverse ? "green-dot" : "red-dot";

    return (
        <div className="d-flex pt-2 pb-3">
            <p className="table-values col-sm-4">
                <b>{name}</b>: {value}
            </p>
            <div className="table-values col-sm-8">
                <p className="float-left">Inverse?:</p>  <span className={inverted} />
            </div>
        </div>
    )
}

export default MetaMatchContent;
