import React from "react";
import DeploymentListTable from "./DeploymentListTable";

const DeploymentList = () => {
    return (
        <div className="page-wrapper">
            <div className="background">
                <DeploymentListTable/>
            </div>
        </div>
    )
}

export default DeploymentList;
