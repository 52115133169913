import React, {useCallback, useEffect, useRef, useState} from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import {useDispatch} from "react-redux";
import {addDeviceOnDM, setDeviceOnDM} from "../../../../store/RdmInfo/actions/RdmInfoActions";
import DeviceMetaRow, {DeviceMetaRowProps} from "./DeviceMetaRow";
import {deepCopy} from "../../../../utils/copyUtils";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";
import {findDuplicateStrings, toArray} from "../../../../utils/sortingUtils";
import {useDMDevices} from "../../../Helpers/Hooks/useDMDevices";


const EditDeviceForm = (props: EditDeviceFormProps) => {
    const previousDeviceName = useRef(props.rdmDeviceInfo.deviceName);
    const [localDeviceInfo, setLocalDeviceInfo] = useState<RdmDeviceInfo>(props.rdmDeviceInfo);
    const [deviceMeta, setDeviceMeta] = useState(Object.entries(props.rdmDeviceInfo.meta))
    const [isUniqueDeviceName, setIsUniqueDeviceName] = useState<boolean>(true);
    const devices = useDMDevices();
    const dispatch = useDispatch();

    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    useEffect(() => {
        return () => setLocalDeviceInfo({deviceName: "", meta: {}})
    }, [])

    useEffect(() => {
        setLocalDeviceInfo(props.rdmDeviceInfo);
        setDeviceMeta(Object.entries(props.rdmDeviceInfo.meta));
    }, [props])


    /** Update Device Name */
    const changeDeviceName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const comments = event.target.value;

        setLocalDeviceInfo({
            ...props.rdmDeviceInfo,
            deviceName: comments
        })
    }

    useEffect(() => {
        const isUniqueDeviceName = validateDeviceName();

        setIsUniqueDeviceName(isUniqueDeviceName)

    }, [localDeviceInfo.deviceName])

    const validateDeviceName = () => {
        const deviceNames: string[] = [];
        const deviceList = toArray(devices);

        for(const device of deviceList) {
            deviceNames.push(device.deviceName);
        }

        //Form changes in the store when the save button is pressed.
        //The previousDeviceName wont change unless the form is reopened.
        //If existing name matches the localDeviceInfo name, we are editing the original, not trying to create a duplicate
        if(previousDeviceName.current === localDeviceInfo.deviceName) {
            return true;
        }

        const isADuplicate = deviceNames.indexOf(localDeviceInfo.deviceName)
        return isADuplicate < 0;
    }

    const saveDevice = () => {
        let errors: string[] = [];
        let isValid = true;

        const names: string[] = [];
        const values: string[] = [];

        for(const meta of deviceMeta) {
            names.push(meta[0]);
            values.push(meta[1]);
        }

        for(const value of values) {
            if(value.length === 0) {
                isValid = false;
                errors.push("One or more Meta Values are empty");
                break;
            }
        }

        for(const name of names) {
            if(name.length === 0) {
                isValid = false;
                errors.push("One or more Meta Names are empty");
                break;
            }
        }

        const uniqueDuplicates = Array.from(new Set(findDuplicateStrings(names)))

        if(uniqueDuplicates.length > 0) {
            isValid = false;
            errors.push("One or more Meta Names are not unique");
        }

        if(localDeviceInfo.deviceName.length === 0) {
            isValid = false;
            errors.push("Device name cannot be blank");
        }

        const isValidDeviceName = validateDeviceName();

        if(!isValidDeviceName) {
            isValid = false;
            errors.push("Device name is not unique");
        }
        if(!isValid) {
            showErrorModal(errors);
            return;
        }

        const rdmInfo: RdmDeviceInfo = {
            ...localDeviceInfo,
            meta: Object.fromEntries(deviceMeta)
        }

        //If it is a new device (defined in props) we push to device array
        if(props.isNewDevice) {
            dispatch(addDeviceOnDM(rdmInfo));
            props.onClose();
            return;
        }

        //Otherwise, we find the device and update it
        dispatch(setDeviceOnDM({
            previousName: previousDeviceName.current,
            rdmDeviceInfo: rdmInfo
        }))

        props.onClose();
    }

    const showErrorModal = (error: string[]) => {
        setModalProps({
            headerText: "Error",
            messages: error,
            onConfirm: () => null,
            cancelText: "Dismiss",
            confirmText: "",
            modalSize: ModalSize.small
        })
        toggle()
    }

    const onDeviceMetaChanged = useCallback(
        (deviceMetaRow: DeviceMetaRowProps, previousName: string, previousValue: string) => {
            const arr = [deviceMetaRow.name, deviceMetaRow.value];

            const index = deviceMeta.findIndex((el: string[]) => el[0] === previousName && el[1] === previousValue);
            if(index < 0) return;

            const metaArr = deepCopy(deviceMeta);
            metaArr[index] = arr;

            setDeviceMeta(metaArr);
        }, [deviceMeta]
    )

    const onRemoveMeta = useCallback(
        (previousName: string) => {
            const metaIndex = deviceMeta.findIndex((el: string[]) => el[0] === previousName);

            if(metaIndex < 0) return;
            deviceMeta.splice(metaIndex, 1);
            setDeviceMeta(toArray(deviceMeta));
        }, []
    )

    const addMeta = () => {
        const arr = deepCopy(deviceMeta);
        arr.push(["", ""]);
        setDeviceMeta(arr)
    }

    const cancel = () => {
        props.onClose()
    }

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col pl-1">
                    <p className="details">
                        Device Name
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col pl-1">
                    <input
                        type="text"
                        className="input-field lg mr-4"
                        value={localDeviceInfo.deviceName}
                        onChange={changeDeviceName}
                    />
                    {
                        localDeviceInfo.deviceName.length < 1 &&
                        <div className="error-text pl-3">
                            Device name field is required
                        </div>
                    }
                    {
                        !isUniqueDeviceName &&
                        <div className="error-text pl-3">
                            Device Name is not unique
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-1">
                <div className="col pl-1">
                    <p className="details">
                        Device Meta
                    </p>
                </div>
                <div className="col pl-1 pr-0">
                    <div className="float-right">
                        <a onClick={addMeta} className="interactive-text pr-3">
                            <span className="pl-2">+ Add Meta</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row medium-modal-table-contents-wrapper">
                <div className="col pl-0 pr-0">
                    <div className="medium-modal-table-contents">
                        <div className="table-contents-key">
                            <div className="row">
                                <div className="col-md-5">
                                    <p className="uppercasify table-keys">
                                        Name
                                    </p>
                                </div>
                                <div className="col-md-5">
                                    <p className="uppercasify table-keys">
                                        Value
                                    </p>
                                </div>
                                <div className="col-md-2">
                                    <p className="uppercasify table-keys">
                                        Controls
                                    </p>
                                </div>
                            </div>
                            {
                                deviceMeta.length > 0 && deviceMeta.map((el: string[], index) => {
                                    return (
                                        <DeviceMetaRow
                                            name={el[0]}
                                            value={el[1]}
                                            metaList={deviceMeta}
                                            onChange={onDeviceMetaChanged}
                                            key={index}
                                            onRemove={onRemoveMeta}
                                        />
                                    )
                                })
                            }
                            {
                                deviceMeta.length === 0 &&
                                <div className="align-middle">
                                    <h5 className="d-flex justify-content-center pt-2">
                                        No meta exists. Click 'Add Meta' to start
                                    </h5>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="float-right mt-2">
                        <a onClick={cancel} className="interactive-text pr-3">
                            <span className="pl-2">Cancel</span>
                        </a>
                        <span className="red-text pr-2">
                            |
                        </span>
                        <a onClick={saveDevice} className="interactive-text pr-3">
                            <span className="pl-2">Save Device</span>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default EditDeviceForm;

interface EditDeviceFormProps {
    rdmDeviceInfo: RdmDeviceInfo;
    onClose: () => void;
    isNewDevice: boolean;
}
