import React from "react";
import {RdmSiteName} from "../../../api/x-control";
import {routeNames} from "../../Navigation/routeNames";
import {useHistory} from "react-router-dom";

const SiteManagementTableItem = (props: RdmSiteName) => {
    const history = useHistory();

    const viewSite = () => {
        const sitePage = `${routeNames.siteManagementSite.path}/${props.siteId}`;
        //Push where we are navigating to to state so we can navigate back here when done!
        history.push({
            pathname: sitePage,
            state: {
                from: window.location.pathname
            }
        })
    }

    return (
        <React.Fragment>
            <div className="row pt-3 pb-3">
                <div className="col-md-4 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.siteName}
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.siteId}
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="align-self-center">
                        <p className="ml-auto table-values">
                            <span className="row ml-0 mr-0">
                                <a onClick={viewSite}>
                                    <span className="interactive-text">
                                        View Site
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
        </React.Fragment>
    )
}

export default SiteManagementTableItem
