import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock'
import {nanoid} from "nanoid";
import ReactTooltip from "react-tooltip";

const Modal = ({isShown, hide, modalContent, headerText, modalSize, refreshCall, refreshText}: ModalProps) => {

    const [modalClass, setModalClass] = useState<string>("styled-modal-sm drop-shadow-modal");
    const [wrapperClass, setWrapperClass] = useState<string>("wrapper-sm");
    const tooltipId = nanoid();
    const onKeyDown = (event: KeyboardEvent) => {
        if(event.keyCode === 27 && isShown) {
            hide();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);

        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        }
    }, [isShown])

    useEffect(() => {
        switch (modalSize) {
            case ModalSize.large:
                setWrapperClass('wrapper-lg')
                setModalClass("styled-modal-lg drop-shadow-modal")
                break;
            case ModalSize.medium:
                setWrapperClass('wrapper-md')
                setModalClass("styled-modal-md drop-shadow-modal")
                break;
            case ModalSize.small:
                setWrapperClass('wrapper-sm')
                setModalClass("styled-modal-sm drop-shadow-modal")
                break
        }
    }, [])

    let modalRoot = document.getElementById('modal-root');

    if (!modalRoot) {
        modalRoot = document.body;
    }

    const modal = (
        <div className="modal fadeIn">
            <div className="backdrop"/>
            <FocusLock>
                <div
                    className={wrapperClass}
                    aria-modal
                    aria-labelledby={headerText}
                    tabIndex={-1}
                    role="dialog"
                >
                    <div className={modalClass}>
                        <div className="pt-2 row ml-0 mr-0">
                            <p className="header-text pl-1 col-sm-11">
                                {headerText}
                                {
                                    refreshCall && (
                                        <React.Fragment>
                                            <svg
                                                className="refresh-icon ml-2 red-text interactive"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 64 64" x="0px" y="0px"
                                                data-tip
                                                data-for={tooltipId}
                                                onClick={() => refreshCall()}
                                            >
                                                <path fill="currentColor" d="M10,26.47a1.87,1.87,0,0,1-.46-1.93A23.65,23.65,0,0,1,55.65,32a1.89,1.89,0,0,1-3.77,0,19.87,19.87,0,0,0-38.73-6.27,1.89,1.89,0,0,1-2.39,1.2,1.85,1.85,0,0,1-.74-.46Z"/>
                                                <path fill="currentColor" d="M15.29,48.71A23.56,23.56,0,0,1,8.36,32a1.89,1.89,0,0,1,3.78,0,19.87,19.87,0,0,0,38.73,6.26,1.89,1.89,0,1,1,3.58,1.19,23.64,23.64,0,0,1-39.16,9.26Z"/>
                                                <path fill="currentColor" d="M46.17,28.21a1.89,1.89,0,0,1,2.53-2.8l4.65,3.8,3.4-5.09a1.89,1.89,0,0,1,3.14,2.1l-4.56,6.83a1.89,1.89,0,0,1-2.62.52l-.15-.11L46.3,28.34A1.59,1.59,0,0,0,46.17,28.21Z"/>
                                                <path fill="currentColor" d="M4.35,40.16a1.91,1.91,0,0,1-.23-2.39L8.68,31a1.89,1.89,0,0,1,2.62-.53l.15.11,6.25,5.13a1.89,1.89,0,1,1-2.39,2.92l-4.65-3.8-3.4,5.09a1.89,1.89,0,0,1-2.62.53A2.17,2.17,0,0,1,4.35,40.16Z"/>
                                            </svg>
                                            <ReactTooltip
                                                place="right"
                                                id={tooltipId}
                                                type="dark"
                                                effect="solid"
                                            >
                                                <span>
                                                    {refreshText}
                                                </span>
                                            </ReactTooltip>
                                        </React.Fragment>
                                    )
                                }
                            </p>
                            <a className="col-sm-1 pr-0" onClick={hide}>
                                <svg
                                    version="1.1"
                                    preserveAspectRatio="none"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 100 100"
                                    className="close-button float-right button-base "
                                >
                                    <path
                                        fill="currentColor"
                                        stroke="none"
                                        d=" M 84.25 24.25 Q 86.0037109375 22.4890625 86 20 86.0037109375 17.5109375 84.25 15.75 82.4890625
                                            13.9962890625 80 14 77.5109375 13.9962890625 75.75 15.75 L 50 41.5 24.25 15.75 Q 22.4890625
                                            13.9962890625 20 14 17.5109375 13.9962890625 15.75 15.75 13.9962890625 17.5109375 14 20 13.9962890625
                                            22.4890625 15.75 24.25 L 41.5 50 15.75 75.75 Q 13.9962890625 77.5109375 14 80 13.9962890625 82.4890625
                                            15.75 84.25 17.5109375 86.0037109375 20 86 22.4890625 86.0037109375 24.25 84.25 L 50 58.5 75.75 84.25 Q
                                            77.5109375 86.0037109375 80 86 82.4890625 86.0037109375 84.25 84.25 86.0037109375 82.4890625 86 80 86.0037109375
                                             77.5109375 84.25 75.75 L 58.5 50 84.25 24.25 Z"
                                    />
                                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">

                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div className="content">
                            {modalContent}
                        </div>
                    </div>
                </div>
            </FocusLock>
        </div>
    )

    return isShown ? ReactDOM.createPortal(modal, modalRoot) : null
}

export default Modal;



export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
    modalSize: ModalSize;
    refreshCall?: () => void;
    refreshText?: string;
}

export enum ModalSize {
    small = "SMALL",
    medium = "MEDIUM",
    large = "LARGE"
}

export interface XControlModalProps {
    headerText: string;
    onConfirm: () => void;
    messages: string[];
    confirmText: string;
    cancelText: string;
    modalSize: ModalSize;
}
