import React, {useEffect} from "react";
import { StoreServiceData } from "../../../../store/Helpers/Types/StoreServiceData";
import Loading from "./Loading";
import Error from "./Error";

const WithServiceState = (WrappedComponent: any) => (props: StoreServiceData<any>) => {
    return (
        <React.Fragment>
            {
                props.data && <WrappedComponent {...props.data}/>
            }
            <div>
                {
                    props.loading &&
                    <div className="loader-position-wrapper">
                        <Loading loadingText={"Loading"} />
                    </div>
                }
            </div>
            <div>
                {
                    props.error && props.error.length > 0 &&
                    <Error
                        error={props.error}
                    />
                }
            </div>
        </React.Fragment>
    )
}

export default WithServiceState;
