import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApiModel from "./store/ApiModel/ApiModel";
import {Auth0Provider} from "@auth0/auth0-react";
import {fetchConfig} from "./config/config";
import 'react-dates/initialize';

require('dotenv').config();

async function initReact() {
    const config = await fetchConfig();

    ApiModel.initialise({
        basePath: config.xcApiBaseUrl
    })

    ReactDOM.render(
        <Auth0Provider
            domain={config.auth0config.domain}
            clientId={config.auth0config.clientID}
            redirectUri={config.auth0config.redirectUri}
            audience={config.auth0config.audience}
            scope={config.auth0config.scope}
        >
            <App/>
        </Auth0Provider>,
        document.getElementById('root')
    );
}

initReact().catch(error => console.error('COULD NOT LOAD AUTH CONFIG, APPLICATION FAILED', error));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
