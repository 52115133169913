import React, {useEffect} from "react";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom'
import {RootStore} from "../../../store/Store";
import {createNewDeployment, getDeploymentOnService} from "../../../store/Deployment/actions/DeploymentActions";
import DeploymentTable from "./DeploymentTable";
import {useHistory} from 'react-router-dom'
import {routeNames} from "../../Navigation/routeNames";
const DeploymentTableWrapper = WithServiceState(DeploymentTable);


const Deployment = () => {
    const params: any = useParams();
    const deploymentStore = useSelector((state: RootStore) => state.deployment);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if(history.location.pathname === routeNames.deploymentCopy.path) {
            return;
        }
        const id = +params.id;

        if(!id) {
            dispatch(createNewDeployment())
            return;
        }

        dispatch(getDeploymentOnService(id));
    }, [])

    return (
        <div className="page-wrapper">
            <div className="background">
                <DeploymentTableWrapper {...deploymentStore}/>
            </div>
        </div>
    )
}

export default Deployment;
