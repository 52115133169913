import {AxiosPromise} from "axios";
import {Dispatch} from "redux";
import {StoreState} from "../Types/StoreServiceData";

export const messageResponses = {
    default: "No data returned by service",
    fetchError: "Unexpected error",
    deleteError: "Unexpected error deleting data",
    saveError: "Unexpected error saving data",
    200: "Success",
    204: "Data does not exist!",
    400: "Validation failed or a referenced entity could not be found",
    401: "Unauthenticated user - Please refresh token",
    403: "Unauthorised user - You are not allowed to view this resource",
    404: "404 - Not Found",
    406: "Assignment not present, entry not present, or assignment is not in a 'WaitingAccept' state",
    409: "Save error - there was an issue saving to the database"
};


/**
 * Fetch data from service and automatically set loading / error / data states.
 * Return true if data successfully fetched, false if any errors
 */
export async function fetchData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    fetchCall: () => AxiosPromise<T | undefined | null> | null
): Promise<boolean> {

    try {
        dispatch({
            type: storeState.LOADING
        })
        const result = await fetchCall();

        if(!result) return false;

        if (result.status === 401) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["401"]
            })
            return false;
        }

        if (result.status === 403) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["403"]
            })
            return false;
        }

        if (result.status === 404) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["404"]
            })
            return false;
        }

        if (result.status === 204) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["204"]
            })
            return false;
        }

        if (result.status === 200 && result.data) {
            dispatch({
                type: storeState.SUCCESS,
                data: result.data
            })
            return true;
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        })
        return false;
    } catch (error) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.type
        })
        console.error(error.response);
        return false;
    }
}

/**
 * Save data from service and automatically set loading / error / data states. Will set data to NULL if successful.
 * Return true if data successfully saved, false if any errors
 * */
export async function saveData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    saveCall: () => AxiosPromise,
    fetchCall?: () => AxiosPromise<T | undefined | null> | null,
): Promise<boolean> {
    try {
        dispatch({
            type: storeState.LOADING
        })

        const result = await saveCall();

        if (result.status === 400){
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["400"]
            })
            return false;
        }

        if (result.status === 401){
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["401"]
            })
            return false;
        }

        if (result.status === 403){
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["403"]
            })
            return false;
        }

        if (result.status === 409) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["409"]
            })
            return false;
        }

        if (result.status === 200 || result.status === 204) {
            dispatch({
                type: storeState.SUCCESS,
                data: result.data
            })

            if (fetchCall) await fetchData(storeState, dispatch, fetchCall);
            return true;
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        })
        return false;
    }
    catch (error) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.type
        })
        console.error(error);
        return false;
    }
}

/**
 * Delete data from service and automatically set loading / error / data states. Will set data to NULL if successful.
 * Return true if data successfully deleted, false if any errors
 */
export async function deleteData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    deleteCall: () => AxiosPromise,
    fetchCall?: () => AxiosPromise<T | undefined | null> | null,
): Promise<boolean> {
    try {
        dispatch({
            type: storeState.LOADING
        })
        const result = await deleteCall();

        if (result.status === 401){
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["401"]
            })
            return false;
        }

        if (result.status === 403){
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["403"]
            })
            return false;
        }

        if(result.status === 404) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["404"]
            })
            return false;
        }

        if (result.status === 200 || result.status === 204) {
            if(fetchCall) await fetchData(storeState, dispatch, fetchCall);
            return true;
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        })
        return false;
    } catch (error) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.type
        })
        console.error(error);
        return false;
    }
}
