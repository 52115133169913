import { SETTINGS_GROUPS_STORE_STATE } from "../actions/SettingsGroupsActionTypes";
import {SettingsGroupName} from "../../../api/x-control";
import { ServiceActionTypes, StoreServiceData } from "../../Helpers/Types/StoreServiceData";

const defaultState: StoreServiceData<SettingsGroupName[]> = {
    loading: false,
    error: null,
    data: []
}

const settingsGroupsReducer = (state: StoreServiceData<SettingsGroupName[]> = defaultState, action: ServiceActionTypes<SettingsGroupName[]>) => {
    switch (action.type) {
        case SETTINGS_GROUPS_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case SETTINGS_GROUPS_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case SETTINGS_GROUPS_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }

        default:
            return state;
    }
}

export default settingsGroupsReducer;
