import React, {useEffect} from 'react';
import Modal, {ModalSize} from "../../Modal/components/Modal";
import {useModal} from "../../Modal/hooks/useModal";
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchRollbacksByRolloutId,
    nullifyRollbacksListStore
} from "../../../store/RollbackList/actions/RollbackListActions";
import {RootStore} from "../../../store/Store";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import RollbackListContainer from "./RollbackListContainer";
import {routeNames} from "../../Navigation/routeNames";
import {getQueries} from "../../../utils/queryUtils";

const WrappedRollbackListContainer = WithServiceState(RollbackListContainer)

const RollbackListModal = () => {
    const {isShown, toggle} = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();

    const rolloutListStore = useSelector((state: RootStore) => state.rollbackList);

    // @ts-ignore
    useEffect(() => {
        dispatch(nullifyRollbacksListStore())
        fetchRollbacks();
        toggle();

        return () => dispatch(nullifyRollbacksListStore())
    }, [])

    const fetchRollbacks = () => {
        const rolloutId = +params.rolloutId;
        dispatch(fetchRollbacksByRolloutId(rolloutId))
    }

    const closeModal = () => {
        const routerState = history.location.state as any;
        const query = getQueries();
        if (!routerState) {
            history.push({
                pathname: `${routeNames.deploymentsList.path}/${+params.id}${routeNames.rolloutList.path}`,
                search: query
            })
            return;
        }
        history.push({
            pathname: routerState.from,
            search: query
        });
    }


    return (
        <Modal
            isShown={isShown}
            hide={() => closeModal()}
            headerText="ROLLBACK LIST"
            modalSize={ModalSize.large}
            refreshCall={() => fetchRollbacks()}
            refreshText={"Refresh Rollbacks"}
            modalContent={
                <WrappedRollbackListContainer
                    {...rolloutListStore}
                />
            }
        />
    )
}

export default RollbackListModal;
