import {RDM_INFO_LIST_STORE_STATE, RdmInfoListDispatchTypes} from "./RdmInfoListActionTypes";
import ApiModel from "../../ApiModel/ApiModel";
import {fetchData} from "../../Helpers/Utils/StoreFetchWrappers";
import {Dispatch} from 'redux';

export const fetchDataManagersForSite = (id: string) => async (dispatch: Dispatch<RdmInfoListDispatchTypes>) => {
    try {
        await fetchData(
            RDM_INFO_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getRdmApi().getDMsForSite(id)
        )
    }

    catch (e) {
        dispatch({
            type: RDM_INFO_LIST_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const nullifyDataManagers = () => async (dispatch: Dispatch<RdmInfoListDispatchTypes>) => {
    dispatch({
        type: RDM_INFO_LIST_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}

