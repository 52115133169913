import React, {useEffect} from "react";
import ReactTooltip from "react-tooltip";
import {DeploymentEntry} from "../../../../api/x-control";
import {nanoid} from "nanoid";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";

const DeploymentApprovalSection = (props: DeploymentEntry) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.approved) return;
        if(props.approvalUsers.length == 2) {
            const entry: DeploymentEntry = {
                ...props,
                approved: true
            }

            dispatch(setDeployment(entry));
        }
    }, [props])

    const tooltipId = nanoid();

    const approved = props.approved ? 'green-dot-lg interactive' : 'red-dot-lg interactive'
    const approvalTooltipText =
        props.approved ?
            `${props.name} has been approved!` :
            `${props.name} requires approval!`

    const tooltipTheme = props.approved ? 'tooltip-success-right' : 'tooltip-error-right';

    return (
        <React.Fragment>
            <div className="col-sm-9">
                <p className="details">
                    Approvals
                </p>
            </div>
            <div className="col-sm-2">
                <a data-tip data-for={tooltipId} className={approved}/>
                <ReactTooltip place="right" id={tooltipId} className={tooltipTheme} effect="solid">
                    <span>{approvalTooltipText}</span>
                </ReactTooltip>
            </div>
        </React.Fragment>
    )
}

export default DeploymentApprovalSection;
