import React, {useCallback, useEffect, useState} from "react";
import update from 'immutability-helper';
import { toArray } from "../../../utils/sortingUtils";
import {useDispatch, useSelector} from "react-redux";
import {DeploymentEntry, SettingsGroup} from "../../../api/x-control";
import {RootStore} from "../../../store/Store";
import {setDeployment} from "../../../store/Deployment/actions/DeploymentActions";
import DeploymentSettingsGroupCard from "./DeploymentSettingGroupCard";

const DeploymentGroupSettingsOptions = (props: SettingsGroup[]) => {
    const [cards, setCards] = useState(toArray(props));
    const [isDropped, setIsDropped] = useState(false);
    const dispatch = useDispatch();
    const deploymentStore = useSelector((state: RootStore) => state.deployment);

    useEffect(() => {
        setCards(toArray(props));
    }, [props])

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = cards[dragIndex]
            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            )
        },
        [cards]
    )

    const droppedCard = useCallback(
        (dropped: boolean) => {
            setIsDropped(dropped)
        },
        [isDropped]
    )

    const removeCard = useCallback(
        (index: number) => {
            setCards(
                update(cards, {
                    $splice: [
                        [index, 1]
                    ],
                }),
            )

        },
        [cards]
    )

    useEffect(() => {
        const deployment = deploymentStore.data;

        if(isDropped && deployment) {
            const entry: DeploymentEntry = {
                ...deployment,
                settingsGroups: cards
            }
            dispatch(setDeployment(entry))
        }
    }, [isDropped])

    return (
        <React.Fragment>
            {cards && cards.map((el: SettingsGroup, i: number) => {
                return(
                    <DeploymentSettingsGroupCard
                        {...el}
                        moveCard={moveCard}
                        removeCard={removeCard}
                        droppedCard={droppedCard}
                        index={i}
                        key={el.id}
                    />
                )
            })}
        </React.Fragment>
    )
}

export default DeploymentGroupSettingsOptions;
