import {SettingsGroup} from "../../../api/x-control";
import { StoreServiceData, StoreState } from "../../Helpers/Types/StoreServiceData";

export const SETTING_GROUP_STORE_STATE: StoreState = {
    SUCCESS: "SETTING_GROUP_SUCCESS",
    ERROR: "SETTING_GROUP_ERROR",
    LOADING: "SETTING_GROUP_LOADING",
}

export interface SettingGroupLoading extends StoreServiceData<SettingsGroup>{
    type: typeof SETTING_GROUP_STORE_STATE.LOADING;
}

export interface SettingGroupError extends StoreServiceData<SettingsGroup>{
    type: typeof SETTING_GROUP_STORE_STATE.ERROR;
}

export interface SettingGroupSuccess extends StoreServiceData<SettingsGroup>{
    type: typeof SETTING_GROUP_STORE_STATE.SUCCESS;
}

export type SettingGroupDispatchType = SettingGroupSuccess | SettingGroupError | SettingGroupLoading;
