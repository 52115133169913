import {DeploymentEntry, SettingsGroup, SettingsGroupName} from "../api/x-control";

//ToDo: Write Test for these.

export interface DDProps {
    value: string | number;
    label: string;
}

export function sortRawDataToDropDownProps(versions: any[]) {
    const dropDownProps: DDProps[] = [];

    for (const version of versions) {
        dropDownProps.push({
            value: version.id.toString(),
            label: version.name
        })
    }

    return dropDownProps;
}

export function toArray(object: any) {
    return Object.keys(object).map(function (index) {
        return object[index];
    });
}

export function getUniqueSettings(allSettings: SettingsGroupName[], deployment: DeploymentEntry) {
    const availableSettings: SettingsGroupName[] = [];

    for(const setting of allSettings) {

        const index = deployment.settingsGroups.findIndex((el: SettingsGroup) => el.id === setting.id);

        if(index > -1) continue;

        availableSettings.push(setting);
    }

    return availableSettings;
}

function getRandomNumber(bottom: number, top: number) {
    return Math.floor(Math.random() *  (1 + top - bottom)) + bottom;
}

export function findDuplicateStrings(stringArray: string[]) {
    return stringArray.filter((el: string, index: number) => stringArray.indexOf(el) != index && el.length > 0);
}
