import React, {useEffect, useState} from "react";
import PageHeader from "../PageHeader";
import {routeNames} from "../../Navigation/routeNames";
import {DateRangePicker} from 'react-dates'
import moment, {Moment} from "moment";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {getPagedRollouts, nullifyRollouts} from "../../../store/RolloutList/actions/RolloutListActions";
import ReactPaginate from "react-paginate";
import RolloutListTableItemsWrapper from "./RolloutListTableItemsWrapper";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";
import { useHistory } from "react-router-dom";
import {RolloutListEntry} from "../../../api/x-control";
import {useQuery} from "../../Helpers/Hooks/useQuery";
import {nanoid} from "nanoid";
import ReactTooltip from "react-tooltip";

const RolloutItemsWrapper = WithServiceState(RolloutListTableItemsWrapper);

const RolloutListTable = (props: RolloutListTableProps) => {
    const itemsPerPage = 10;
    const query = useQuery();

    const [startDate, setStartDate] = useState<Moment>();
    const [endDate, setEndDate] = useState<Moment>();
    const [focusedInput, setFocusedInput] = useState(null);

    const rolloutStore = useSelector((state: RootStore) => state.rolloutList);
    const [pageCount, setPageCount] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [rolloutEntries, setRolloutEntries] = useState<RolloutListEntry[]>([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const tooltipId = nanoid();
    // @ts-ignore
    useEffect(() => {
        //Query strings
        const mdi = query.get("MDI");
        const mde = query.get("MDE");

        const start: Moment = moment().subtract(7, "day")
        const end: Moment = moment();

        setStartDate(mdi ? moment.unix(+mdi) : start);
        setEndDate(mde ? moment.unix(+mde) : end);

        //Once component is destroyed. Remove from store
        return () => dispatch(nullifyRollouts())
    }, [])

    useEffect(() => {
        if(!rolloutStore.data) return;
        if(rolloutStore.loading) return;

        const rolloutList = rolloutStore.data;

        const pageTotal = Math.ceil(rolloutList.totalRollouts / itemsPerPage);

        setRolloutEntries(rolloutList.entries);
        if(rolloutList.entries.length > 0) {
            setRolloutEntries(rolloutList.entries);
        }

        setPageCount(pageTotal);
    }, [rolloutStore.data, rolloutStore.loading])

    useEffect(() => {
        if(startDate && endDate) {
            fetchData(startDate.unix(), endDate.unix(), 0)
        }
    }, [startDate, endDate])


    const onDatesChange = ({startDate, endDate}: any) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const onFocusChange = (e: any) => {
        setFocusedInput(e)
    }

    const changePage = (data: any) => {
        let selected = data.selected !== undefined ? data.selected : data;
        setPageNumber(selected);
        if(!startDate || !endDate) return;
        const start = startDate.startOf('day').unix();
        const end = endDate.endOf('day').unix();

        fetchData(start, end, selected);
    }


    const fetchData = (start: number, end: number, page: number) => {
        //Can't go ahead without dates
        if(!startDate) return;
        if(!endDate) return;
        history.push({
            search: `?MDI=${start}&MDE=${end}`
        })
        dispatch(getPagedRollouts({
            pageNum: page,
            numPerPage: itemsPerPage,
            deploymentId: props.id,
            minDateInclusive: start,
            maxDateExclusive: end
        }))
    }

    const backToDeploymentList = () => {
        dispatch(nullifyRollouts())
        history.replace(routeNames.deploymentsList.path)
    }

    return (
        <div className="h-100">
            <PageHeader
                actionName={routeNames.rolloutList.name}
            />
            <div className='table-background'>
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col pl-0">
                                    <div className="w-75">
                                        <h5 className="pt-4 pl-2 ml-3">
                                            Rollout List
                                            <svg
                                                className="refresh-icon ml-2 mb-1 red-text interactive"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 64 64" x="0px" y="0px"
                                                data-tip
                                                data-for={tooltipId}
                                                onClick={() => {
                                                    if (!startDate || !endDate) return;
                                                    fetchData(startDate.unix(), endDate.unix(), pageNumber)
                                                }}
                                            >
                                                <path fill="currentColor" d="M10,26.47a1.87,1.87,0,0,1-.46-1.93A23.65,23.65,0,0,1,55.65,32a1.89,1.89,0,0,1-3.77,0,19.87,19.87,0,0,0-38.73-6.27,1.89,1.89,0,0,1-2.39,1.2,1.85,1.85,0,0,1-.74-.46Z"/>
                                                <path fill="currentColor" d="M15.29,48.71A23.56,23.56,0,0,1,8.36,32a1.89,1.89,0,0,1,3.78,0,19.87,19.87,0,0,0,38.73,6.26,1.89,1.89,0,1,1,3.58,1.19,23.64,23.64,0,0,1-39.16,9.26Z"/>
                                                <path fill="currentColor" d="M46.17,28.21a1.89,1.89,0,0,1,2.53-2.8l4.65,3.8,3.4-5.09a1.89,1.89,0,0,1,3.14,2.1l-4.56,6.83a1.89,1.89,0,0,1-2.62.52l-.15-.11L46.3,28.34A1.59,1.59,0,0,0,46.17,28.21Z"/>
                                                <path fill="currentColor" d="M4.35,40.16a1.91,1.91,0,0,1-.23-2.39L8.68,31a1.89,1.89,0,0,1,2.62-.53l.15.11,6.25,5.13a1.89,1.89,0,1,1-2.39,2.92l-4.65-3.8-3.4,5.09a1.89,1.89,0,0,1-2.62.53A2.17,2.17,0,0,1,4.35,40.16Z"/>
                                            </svg>
                                            <ReactTooltip
                                                place="right"
                                                id={tooltipId}
                                                type="dark"
                                                effect="solid"
                                            >
                                                <span>
                                                    Refresh Rollout list
                                                </span>
                                            </ReactTooltip>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h5
                                    className="interactive-text pl-2 ml-3"
                                    onClick={backToDeploymentList}
                                >
                                    Back
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="ml-auto justify-content-end mt-3 pr-5 float-right">
                                <DateRangePicker
                                    startDateId="startDateID"
                                    startDate={startDate}
                                    endDate={endDate}
                                    endDateId="endDateID"
                                    onDatesChange={onDatesChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={onFocusChange}
                                    isOutsideRange={()=> false}
                                    displayFormat={() => "DD/MM/YYYY"}
                                />
                            </div>
                            <div className="ml-auto justify-content-end d-flex mt-4 pt-1 pr-3 float-right">
                                <p>
                                    Date Ranges:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-contents">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-sm-2">
                                <p className="uppercasify table-keys">
                                    Rollout ID
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Deployment Name
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Date
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Rollout State
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                        <RolloutItemsWrapper {...rolloutStore}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"/>
                    <div className="col-md-4">
                        {
                            rolloutEntries.length > 0 &&
                            <ReactPaginate
                                pageCount={pageCount}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination"}
                                activeClassName={"active-page"}
                                onPageChange={changePage}
                                forcePage={pageNumber}
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageClassName={"other-pages"}
                                breakClassName={"other-pages"}
                                nextClassName={"next-page"}
                                previousClassName={"previous-page"}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolloutListTable;

export interface RolloutListTableProps {
    id: number;
}
