import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {RollbackEntry} from "../../../api/x-control";
import {ROLLBACK_LIST_STORE_STATE} from "../actions/RollbackListActionTypes";

const defaultState: StoreServiceData<RollbackEntry[]> = {
    loading: false,
    error: null,
    data: []
}

const rollbackListReducer = (state: StoreServiceData<RollbackEntry[]> = defaultState, action: ServiceActionTypes<RollbackEntry[]>) => {
    switch (action.type) {
        case ROLLBACK_LIST_STORE_STATE.ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case ROLLBACK_LIST_STORE_STATE.LOADING:
            return {
                ...state,
                error: null,
                loading: true
            }
        case ROLLBACK_LIST_STORE_STATE.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                data: action.data
            }
        default:
            return state;
    }
}

export default rollbackListReducer;
