import {RDM_INFO_STORE_STATE} from "../actions/RdmInfoActionTypes";
import {RdmDeviceInfo, RdmInfo} from "../../../api/x-control";
import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";

const defaultState: StoreServiceData<RdmInfo> = {
    loading: false,
    error: null,
    data: null
}

const rdmInfoReducer = (state: StoreServiceData<RdmInfo> = defaultState, action: ServiceActionTypes<RdmInfo>) => {
    switch (action.type) {
        case RDM_INFO_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case RDM_INFO_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case RDM_INFO_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: processRdmInfoIncoming(action.data)
            }
        default:
            return state;
    }
}

export default rdmInfoReducer;

//Sort the devices in the DM alphabetically
function processRdmInfoIncoming(data: RdmInfo | null | undefined): RdmInfo | null | undefined {
    if(!data) return null;

    if(!data.devices) {
        return {
            ...data,
            devices: []
        };
    }

    if(data.devices.length === 0) {
        return {
            ...data,
            devices: []
        };
    }

    const sortedDevices = data.devices.sort((a: RdmDeviceInfo, b: RdmDeviceInfo) => {
        if (a.deviceName > b.deviceName) {
            return 1;
        }
        if (a.deviceName < b.deviceName) {
            return -1;
        }
        return 0;
    })

    return {
        ...data,
        devices: sortedDevices
    }
}
