import React, {useEffect, useState} from "react";
import {DeploymentEntry, DeploymentState} from "../../../../api/x-control";
import ReactTooltip from "react-tooltip";
import {nanoid} from "nanoid";
import {useModal} from "../../../Modal/hooks/useModal";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";

const DeploymentStateInfo = (props: DeploymentEntry) => {
    const tooltipId = nanoid();
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [toolTipContent, setToolTipContent] = useState<string>('')
    const [buttonClass, setButtonClass] = useState<string>('')
    const dispatch = useDispatch();

    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    useEffect(() => {
        if(isShown) {
            toggle();
        }

        switch (props.state) {
            case DeploymentState.UnderConstruction:
                if(!props.approved) {
                    setToolTipContent(`${props.name} is currently under construction. This button will be available to click when the deployment has been approved`);
                    setDisableButton(true);
                    setButtonClass('deployment-state-button under-construction')
                }
                else {
                    setToolTipContent(`${props.name} has approval, click to make this deployment active!`);
                    setDisableButton(false);
                    setButtonClass('deployment-state-button can-be-active')
                }
                break;
            case DeploymentState.Active:
                if(props.approved) {
                    setToolTipContent(`${props.name} is currently active. Click here to mark it as complete!`)
                    setButtonClass('deployment-state-button active')
                }
                break;
            case DeploymentState.Complete:
                setToolTipContent(`${props.name} is complete. This deployment cannot be reactivated and will be archived.`);
                setButtonClass('deployment-state-button complete')
                setDisableButton(true)
                break;
        }

    }, [props])

    const changeDeploymentState = () => {
        switch (props.state) {
            case DeploymentState.UnderConstruction:
                if(props.approved) {
                    setModalProps({
                        headerText: 'Active?',
                        messages: [`Are you sure you want to mark ${props.name} as active?`],
                        onConfirm: () => {
                            const entry: DeploymentEntry = {
                                ...props,
                                state: DeploymentState.Active
                            }
                            dispatch(setDeployment(entry));
                            toggle();
                        },
                        confirmText: "Yes",
                        cancelText: "No",
                        modalSize: ModalSize.small
                    })
                }
                toggle();
                break;
            case DeploymentState.Active:
                setModalProps({
                    headerText: 'Complete?',
                    messages: [`Are you sure you want to mark ${props.name} as complete?`],
                    onConfirm: () => {
                        const entry: DeploymentEntry = {
                            ...props,
                            state: DeploymentState.Complete
                        }
                        dispatch(setDeployment(entry));
                        toggle();
                    },
                    confirmText: "Yes",
                    cancelText: "No",
                    modalSize: ModalSize.small
                })
                toggle();
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Deployment State
                    </p>
                </div>
            </div>
            <div className="row mt-3">
                <div data-tip data-for={tooltipId} className="col mr-3 ml-2">
                    <button
                        className={buttonClass}
                        disabled={disableButton}
                        onClick={changeDeploymentState}
                    >
                        {props.state.split(/(?=[A-Z])+/g).join(" ")}
                    </button>
                </div>
                <ReactTooltip
                    place="right"
                    id={tooltipId}
                    type="dark"
                    effect="solid"
                >
                    <span>
                        {toolTipContent}
                    </span>
                </ReactTooltip>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default DeploymentStateInfo;

