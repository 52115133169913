import React, {useRef} from "react";
import { Helmet } from 'react-helmet';
import getConfig from "../../config/config";

const NoSideBarLayout = ({children}: LayoutProps) => {
    //UseRef because we don't need this to change on every refresh. It is essentially static
    const config = useRef(getConfig())

    return(
        <React.Fragment>
            <Helmet>
                <link rel="shortcut icon" href={config.current.favIcon} />
                <title>{config.current.brandName ? `${config.current.brandName} ` : ''}X-Control</title>
            </Helmet>
            <div className="main-panel-no-sidebar ml-0" id="main-panel">
                {children}
            </div>
        </React.Fragment>
    )
}

export default NoSideBarLayout;


export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: JSX.Element;
}
