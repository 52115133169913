import {MODAL_STORE_STATE, ModalDispatchTypes} from "./ModalActionTypes";
import {Dispatch} from 'redux'

export const toggleModal = (visible: boolean) => async (dispatch: Dispatch<ModalDispatchTypes>) => {
    dispatch({
        type: MODAL_STORE_STATE.SUCCESS,
        data: visible,
        loading: false,
        error: null
    })
}
