import {Dispatch} from 'redux';
import ApiModel from "../../ApiModel/ApiModel";
import {fetchData} from "../../Helpers/Utils/StoreFetchWrappers";
import Store from "../../Store";
import {ROLLBACK_REPORT_STORE_STATE, RollbackReportDispatchTypes} from "./RollbackReportActionTypes";

export const getReportForRollback = (request: RollbackReportRequest) => async (dispatch: Dispatch<RollbackReportDispatchTypes>) => {
    const {id, rollbackId} = request;
    try {
        const success = await fetchData(
            ROLLBACK_REPORT_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getRollbackReportCsvLink(id, rollbackId)
        )

        if (success) {
            const reportLink = Store.getState().rollbackReport.data;
            if(!reportLink) return;

            return reportLink;
        }
    }

    catch (e) {
        dispatch({
            type: ROLLBACK_REPORT_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const nullifyRollbackReportLink = () => async (dispatch: Dispatch<RollbackReportDispatchTypes>) => {
    dispatch({
        type: ROLLBACK_REPORT_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}

export interface RollbackReportRequest {
    /** Unique Id for rollout */
    id: number;
    /** Unique Id for rollback */
    rollbackId: number;
}
