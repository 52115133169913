import {StoreServiceData, ServiceActionTypes} from "../../Helpers/Types/StoreServiceData";
import {ROLLBACK_REPORT_STORE_STATE} from "../actions/RollbackReportActionTypes";

const defaultState: StoreServiceData<string> = {
    loading: false,
    error: null,
    data: null
}

const rollbackReportReducer = (state: StoreServiceData<string> = defaultState, action: ServiceActionTypes<string>) => {
    switch (action.type) {
        case ROLLBACK_REPORT_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case ROLLBACK_REPORT_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case ROLLBACK_REPORT_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default rollbackReportReducer;
