import React, {useEffect, useState} from "react";
import {DeviceSetting, MetaMatch, SettingMatch, SettingsGroup, SettingsGroupName} from "../../../../api/x-control";
import {useDispatch, useSelector} from "react-redux";
import {getSettingGroupById, setGroupDataToNull} from "../../../../store/SettingGroup/actions/SettingGroupActions";
import {RootStore} from "../../../../store/Store";
import MetaMatchContent from "../MetaMatchContent";
import SettingsMatchContent from "../SettingsMatchContent";
import DeviceSettingContent from "../DeviceSettingsContent";
import SettingsGroupRowDetailActions from "../SettingsGroupRowDetailActions";

const ViewSettingsGroupDetails = (props: SettingsGroupName) => {

    const [controlText, setControlText] = useState("Expand...");

    const [toggled, setToggled] = useState(false);
    const [settingGroup, setSettingGroup] = useState<SettingsGroup>();
    const dispatch = useDispatch();
    const settingGroupState = useSelector((state: RootStore) => state.settingGroup);
    
    
    useEffect(() => {
        const settingGroupFromStore = settingGroupState.data;

        //If we have the settingGroup locally, or the settingGroup from the store is null, we don't want to set it locally.
        if(!settingGroupFromStore) return;

        if(settingGroup?.id === props.id) return;

        setSettingGroup(settingGroupFromStore)
    }, [settingGroupState.data])
    
    const toggleRowView = (value: boolean) => {
        if (!settingGroup) {
            fetchSettingGroupData();
        }

        if(settingGroup?.id !== props.id) {
            setSettingGroup(undefined)
            fetchSettingGroupData();
        }

        setToggled(value = !value);

        const expandCollapse = toggled ? 'Expand...' : 'Collapse...';

        setControlText(expandCollapse);
    }

    const fetchSettingGroupData = () => {
        //If we have the setting group, don't bother getting it again
        dispatch(setGroupDataToNull());
        dispatch(getSettingGroupById(props.id));
    }
    
    return(
        <div>
            <div className="row pt-3 pb-3">
                <div className="col-md-1 d-flex">
                    <p className="table-values align-self-center fadeIn">
                        {props.name}
                    </p>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            settingGroup &&
                            settingGroup.settingsMatches.length === 0 &&
                            <p className="table-values align-self-center">
                                No Settings Matches Exist
                            </p>
                        }
                        {
                            settingGroup &&
                            settingGroup.settingsMatches.length > 0 &&
                            toggled &&
                            settingGroup.settingsMatches.map((el: SettingMatch, index: number) => {
                                return <SettingsMatchContent {...el} key={index} />
                            })
                        }
                        {
                            toggled &&
                            !settingGroup &&
                            settingGroupState.loading &&
                            <p className="table-values align-self-center">
                                Fetching Setting Matches
                            </p>
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            settingGroup &&
                            settingGroup.metaMatches.length === 0 &&
                            <p className="table-values align-self-center">
                                No Meta Matches Exist
                            </p>
                        }
                        {
                            settingGroup &&
                            settingGroup.metaMatches.length > 0 &&
                            toggled &&
                            settingGroup.metaMatches.map((el: MetaMatch, index: number) => {
                                return <MetaMatchContent {...el} key={index} />
                            })
                        }
                        {
                            toggled &&
                            !settingGroup &&
                            settingGroupState.loading &&
                            <p className="table-values align-self-center">
                                Fetching Meta Matches
                            </p>
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        {
                            toggled &&
                            settingGroup &&
                            settingGroup.settings.length === 0 &&
                            <p className="table-values align-self-center fadeIn">
                                No Settings Exist
                            </p>
                        }
                        {
                            settingGroup &&
                            settingGroup.settings.length > 0 &&
                            toggled &&
                            settingGroup.settings.map((el: DeviceSetting, index: number) => {
                                return <DeviceSettingContent {...el} key={index} />
                            })
                        }
                        {
                            toggled &&
                            !settingGroup &&
                            settingGroupState.loading &&
                            <p className="table-values align-self-center">
                                Fetching Settings
                            </p>
                        }
                        {
                            !toggled &&
                            <p className="table-values align-self-center">
                                (...)
                            </p>
                        }
                    </div>
                </div>
                <div className="col-md-2 d-flex">
                    <div className="align-self-center">
                        <p className="row ml-0 mr-0 table-values">
                            <span className="row ml-0 mr-0">
                                <a
                                    className="interactive-text"
                                    onClick={() => toggleRowView(toggled)}
                                >
                                    <span>
                                        {controlText}
                                    </span>
                                </a>
                                <SettingsGroupRowDetailActions {...props} />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-separator" />
        </div>
    )
}

export default ViewSettingsGroupDetails;
