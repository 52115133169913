import {Enum} from "../../utils/enumUtils";

export interface DropDownProps {
    changeOption: (id: number | string) => void;
    id: number | string;
}

export interface GenericTypeDropDownProps<E extends Enum<E>> extends DropDownProps {
    enumOptions: E;
}

export enum DataManagerApiLevelType {
    Basic = 1,
    Enhanced = 2
}
