import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";

export const ROLLBACK_REPORT_STORE_STATE: StoreState = {
    LOADING: "ROLLBACK_REPORT_LOADING",
    ERROR: "ROLLBACK_REPORT_ERROR",
    SUCCESS: "ROLLBACK_REPORT_SUCCESS"
}

interface RollbackReportLoading extends StoreServiceData<string>{
    type: typeof ROLLBACK_REPORT_STORE_STATE.LOADING;
}

interface RollbackReportError extends StoreServiceData<string>{
    type: typeof ROLLBACK_REPORT_STORE_STATE.ERROR;
}

interface RollbackReportSuccess extends StoreServiceData<string>{
    type: typeof ROLLBACK_REPORT_STORE_STATE.SUCCESS;
}

export type RollbackReportDispatchTypes = RollbackReportLoading | RollbackReportError | RollbackReportSuccess;
