import React, {useEffect, useState} from "react";
import {DeploymentListEntry, DeploymentsPageResponse} from "../../../api/x-control";
import DeploymentListTableItem from "./DeploymentListTableItem";


const DeploymentListTableItemsWrapper = (props: DeploymentsPageResponse) => {
    const [deploymentEntries, setDeploymentEntries] = useState<DeploymentListEntry[]>(props.entries);

    useEffect(() => {
        setDeploymentEntries(props.entries)
        if(props.entries.length > 0) {
            setDeploymentEntries(props.entries)
        }
    }, [props])

    return(
        <React.Fragment>
            {
                deploymentEntries.length > 0 &&
                deploymentEntries.map((el: DeploymentListEntry) => {
                    return <DeploymentListTableItem {...el} key={el.id} />
                })
            }
            {
                deploymentEntries.length === 0 &&
                <div className="align-middle">
                    <h5 className="d-flex justify-content-center pt-2">
                        There are no Deployments
                    </h5>
                    <h5 className="d-flex justify-content-center pt-2">
                        Click "Create Deployment" to get started!
                    </h5>
                </div>
            }
        </React.Fragment>
    )
}

export default DeploymentListTableItemsWrapper;
