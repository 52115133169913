import ApiModel from "../../ApiModel/ApiModel";
import {deleteData, fetchData} from "../../Helpers/Utils/StoreFetchWrappers";
import {Dispatch} from 'redux';
import {RDM_INFO_STORE_STATE, RdmInfoDispatchTypes} from "./RdmInfoActionTypes";
import {RdmDeviceInfo, RdmInfo, RdmSiteName} from "../../../api/x-control";
import { v4 as uuidv4 } from 'uuid';
import Store from "../../Store";
import {RDM_INFO_LIST_STORE_STATE} from "../../RdmInfoList/actions/RdmInfoListActionTypes";
import {deepCopy} from "../../../utils/copyUtils";
import {RDM_SITE_LIST_STORE_STATE} from "../../RdmSiteNameList/actions/RdmSiteListActionTypes";

export const nullifyDataManagerStore = () => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}

export const deleteDataManagerOnService = (dmId: string, siteId: string, emptySiteCallback: () => void) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    try {
        const deleted = await deleteData(
            RDM_INFO_STORE_STATE,
            dispatch,
            () => ApiModel.getRdmApi().deleteDmLookup(dmId)
        )

        if (deleted) {
            const success = await fetchData(
                RDM_INFO_LIST_STORE_STATE,
                dispatch,
                () => ApiModel.getRdmApi().getDMsForSite(siteId)
            )

            if (success) {
                const rdmList = Store.getState().rdmInfoList.data;
                if (!rdmList) {
                    emptySiteCallback();
                    return;
                }

                if(rdmList.length === 0) {
                    emptySiteCallback();
                    return;
                }
            }
        }
    }
    catch (e) {
        dispatch({
            type: RDM_INFO_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const getDataManager = (dmId: string, siteId: string) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    //Set Loading
    dispatch({
        type: RDM_INFO_STORE_STATE.LOADING,
        error: null,
        loading: true,
    })

    const site = deepCopy(Store.getState().rdmInfoList.data);
    //if, for whatever reason the dmlist is null. (Should never be)
    if(!site || site.length === 0) {
        await fetchData(
            RDM_INFO_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getRdmApi().getDMsForSite(siteId)
        )

        //Call function again
        // @ts-ignore
        Store.dispatch(getDataManager(dmId, siteId));
        return;
    }

    //Find dm within site.
    const dm = site.find((el: RdmInfo) => el.id === dmId);

    if(!dm) return;

    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: dm
    })
}

export const setDataManager = (rdmInfo: RdmInfo) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: rdmInfo
    })
}

export const createNewDataManager = (siteId?: string) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {

    const siteList = Store.getState().rdmSiteList.data;

    if(!siteList && siteId) {
        try {
            const success = await fetchData(
                RDM_SITE_LIST_STORE_STATE,
                dispatch,
                () => ApiModel.getRdmApi().getSiteNames()
            )

            if(success) {
                // @ts-ignore
                Store.dispatch(createNewDataManager(siteId))
                return;
            }
        }

        catch (e) {
            dispatch({
                type: RDM_SITE_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            })
        }
    }


    let rdmInfo: RdmInfo = {
        id: uuidv4(),
        name: "New Data Manager",
        siteName: "New Site Name",
        hostname: "New Host Name",
        namespace: "New Namespace",
        apiLevel: 1,
        source: "",
        username: "",
        password: "",
        devices: []
    }

    if(siteId && siteId.length > 0 && siteList) {
        const site = siteList.find((el: RdmSiteName) => el.siteId === siteId)

        if(!site) {
            rdmInfo = {
                ...rdmInfo,
                siteName: siteId
            }
        }
        else {
            rdmInfo = {
                ...rdmInfo,
                siteName: site.siteName
            }
        }
    }


    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: rdmInfo
    })
}

export const deleteDeviceFromDM = (deviceName: string) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    const dm = Store.getState().rdmInfo.data;
    if(!dm) return;
    if(!dm.devices) return;

    const index = dm.devices.findIndex((el: RdmDeviceInfo) => el.deviceName === deviceName);

    if(index < 0) return;

    dm.devices.splice(index, 1);

    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: dm
    })
}

export const setDeviceOnDM = (args: setDeviceArgs) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    const dm = Store.getState().rdmInfo.data;
    if(!dm) return;
    if(!dm.devices) return;

    const index = dm.devices.findIndex((el: RdmDeviceInfo) => el.deviceName === args.previousName);

    if(index < 0) return;

    dm.devices[index] = args.rdmDeviceInfo;

    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: dm
    })
}

export const addDeviceOnDM = (rdmInfo: RdmDeviceInfo) => async (dispatch: Dispatch<RdmInfoDispatchTypes>) => {
    const dm = Store.getState().rdmInfo.data;
    if(!dm) return;
    if(!dm.devices) return;

    dm.devices.push(rdmInfo)

    dispatch({
        type: RDM_INFO_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: dm
    })
}

interface setDeviceArgs {
    previousName: string;
    rdmDeviceInfo: RdmDeviceInfo;
}
