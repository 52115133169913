import React from "react";
import {useParams} from 'react-router-dom';
import RolloutListTable from "./RolloutListTable";

const RolloutList = () => {
    const params: any = useParams();

    return (
        <div className="page-wrapper">
            <div className="background">
                <RolloutListTable id={+params.id} />
            </div>
        </div>
    )
}

export default RolloutList;
