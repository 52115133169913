import {RDM_INFO_LIST_STORE_STATE} from "../actions/RdmInfoListActionTypes";
import {RdmInfo} from "../../../api/x-control";
import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

const defaultState: StoreServiceData<RdmInfo[]> = {
    loading: false,
    error: null,
    data: []
}

const rdmInfoListReducer = (state: StoreServiceData<RdmInfo[]> = defaultState, action: ServiceActionTypes<RdmInfo[]>) => {
    switch (action.type) {
        case RDM_INFO_LIST_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case RDM_INFO_LIST_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case RDM_INFO_LIST_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: processRdmListInfoIncoming(action.data)
            }
        default:
            return state;
    }
}

export default rdmInfoListReducer;

function processRdmListInfoIncoming(data: RdmInfo[] | undefined | null): RdmInfo[] | undefined | null {
    if (!data) return null;

    if (data.length === 0) return [];
    return data.sort((a: RdmInfo, b: RdmInfo) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }

        return 0;
    });
}
