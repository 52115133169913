import {DeploymentEntry, SettingsGroup} from "../../api/x-control";

export const ItemTypes = {
    MOVING_GROUP_SETTING: 'Moving Group Setting',
    GROUP_SETTING: 'Group Setting',
    VERSION_GROUP_SETTING: "Version Group Setting",
}

export interface DustbinState {
    accepts: string[];
    lastDroppedItem: any;
}

export interface DragItem {
    index: number;
    id: string | number;
    type: string;
    name: string;
}

export interface DropResult {
    allowedDropEffect: string
    dropEffect: string
    name: string,
    listId?: string | number;
}

export interface DustbinProps {
    accept: string[];
    lastDroppedItem?: DragItem;
    onDrop: (item: any) => void;
}

export interface DragAndDropContainerProps {
    dustbin: DustbinProps;
    deployment: DeploymentEntry;
}

export interface MoveableSettingsGroupCards extends SettingsGroup{
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    removeCard?: (index: number) => void;
    droppedCard?: (dropped: boolean) => void;
}


