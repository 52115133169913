import React, {useEffect, useState} from 'react';
import {Provider} from "react-redux";
import store from './store/Store';
import Routes from "./components/Navigation/Routes/Routes";
import Sidebar from "./components/Navigation/Sidebar/Sidebar";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useAuth0} from "@auth0/auth0-react";
import {BrowserRouter} from "react-router-dom";
import {fetchConfig} from "./config/config";
import Auth from "./store/Auth/Auth";
import axios from "axios";
import Login from "./components/Pages/Login/Login";
import { useClearCache } from 'react-clear-cache';

const App = () => {
    const {loginWithRedirect, user, isLoading, isAuthenticated, getIdTokenClaims, getAccessTokenSilently} = useAuth0();
    const { isLatestVersion, emptyCacheStorage } = useClearCache();


    if (!isLatestVersion) {
        emptyCacheStorage();
    }

    useEffect(() => {
        if (!isLatestVersion) {
            emptyCacheStorage();
        }
    }, [])

    useEffect(() => {
        if(!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading])

    return (
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </DndProvider>
        </Provider>
    )
}

export default App;



axios.interceptors.request.use((cfg) => {
    const token = localStorage.getItem("access_token")

    if (!token) return cfg;

    const fullToken = `Bearer ${token}`;

    axios.defaults.headers.common = {
        'Authorization': fullToken,
    };

    return cfg;
})

let lastError = 0;
let retryCount = 0;

axios.interceptors.response.use(response => response, async error => {

    const status = error.response ? error.response.status : null
    if (status !== 401) throw error;

    // Auto clear retry count if last error was over 10 seconds ago
    const now = Math.round((new Date()).getTime() / 1000);
    const delta = now - lastError;

    if (delta > 10000)
        retryCount = 0;
    lastError = now;

    // Only try a finite number of times
    if (++retryCount > 3) {
        throw error;
    }

    const token = Auth.getAccessToken();
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        error.config.headers['Authorization'] = 'Bearer ' + token;

        return await axios.request(error.config);
    } else {
        throw error;
    }
});

