import React, {useCallback, useEffect, useState} from "react";
import update from 'immutability-helper';
import {toArray, getUniqueSettings} from "../../../utils/sortingUtils";
import {RootStore} from "../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {ISettingsGroupCard, SettingGroupCardActionEnum} from "../../../store/Card/actions/CardActionTypes";
import {SettingsGroupName} from "../../../api/x-control";
import {MoveableSettingsGroupCards} from "../../DragAndDrop/DragAndDropUtils";
import SettingsGroupCard from "./SettingsGroupCard";

const SettingsGroupOptions = (props: SettingsGroupName[]) => {
    const [cards, setCards] = useState(toArray(props));

    const deploymentStore = useSelector((state: RootStore) => state.deployment);
    const cardStore = useSelector((state: RootStore) => state.card);

    useEffect(() => {
        const deployment = deploymentStore.data;
        if(!deployment) return;

        //Version contains no settings, return all cards as available
        if(deployment.settingsGroups.length === 0) {
            setCards(toArray(props));
            return;
        }

        const allSettings: SettingsGroupName[] = toArray(props);
        const availableSettings: SettingsGroupName[] = getUniqueSettings(allSettings, deployment)

        setCards(availableSettings);

    }, [props])

    useEffect(() => {
        const card = cardStore.data;

        if(!card) return;

        switch (card.action) {
            //Removed from deployment, add to this list.
            case SettingGroupCardActionEnum.Removed:
                //As the deployment settings groups are copies, not originals, we need to do this check. As it will show what was originally saved!

                const allSettings = toArray(props);
                const index = allSettings.findIndex((el: SettingsGroupName) => el.id === card.id);
                if(index < 0) {
                    //Fallback if the card cannot be found in the list. Push the group from the deployment
                    addCard(card);
                }
                else {
                    //Otherwise, we add the card with its updated name.
                    addCard({
                        ...card,
                        name: allSettings[index].name,
                        id: allSettings[index].id
                    })
                }

                break;
        }

    }, [cardStore.data])

    const removeCard = useCallback(
        (index: number) => {
            setCards(
                update(cards, {
                    $splice: [
                        [index, 1]
                    ],
                }),
            )

        },
        [cards]
    )

    const addCard = (card: ISettingsGroupCard) => {
        const cardSettings = cards;
        const { name, id } = card;

        const addedSetting: SettingsGroupName = {
            name,
            id,
        }

        const index = cardSettings.findIndex((el: SettingsGroupName) => el.id === addedSetting.id);

        //Stops a duplication bug from occurring when cancelling and then accessing the page again
        if(index > - 1) return;

        cardSettings.push(addedSetting);

        setCards(toArray(cardSettings));
    }

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = cards[dragIndex]
            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            )
        },
        [cards]
    )

    return (
        <React.Fragment>
            {cards && cards.map((el: MoveableSettingsGroupCards, i: number) => {
                return (
                    <SettingsGroupCard
                        {...el}
                        moveCard={moveCard}
                        removeCard={removeCard}
                        index={i}
                        key={el.id}
                    />
                )
            })}
        </React.Fragment>
    )
}

export default SettingsGroupOptions;
