import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";
import {DEPLOYMENT_STORE_STATE} from "../actions/DeploymentActionTypes";
import {DeploymentEntry} from "../../../api/x-control";
import {deepCopy} from "../../../utils/copyUtils";

const defaultState: StoreServiceData<DeploymentEntry> = {
    loading: false,
    error: null,
    data: null
}

const deploymentReducer = (state: StoreServiceData<DeploymentEntry> = defaultState, action: ServiceActionTypes<DeploymentEntry>) => {
    switch (action.type) {
        case DEPLOYMENT_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case DEPLOYMENT_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case DEPLOYMENT_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: processDeploymentIncoming(action.data)
            }
        default:
            return state;
    }
}

export default deploymentReducer;

export function processDeploymentIncoming(deployment: DeploymentEntry | undefined | null) {
    if(!deployment) return null;

    const copy: DeploymentEntry = deepCopy(deployment);

    if (!copy.settingsGroups){
        copy.settingsGroups = [];
    }

    if (!copy.approvalUsers) {
        copy.approvalUsers = [];
    }

    return copy;
}
