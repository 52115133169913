import {DeploymentEntry} from "../../../api/x-control";
import {StoreState, StoreServiceData} from "../../Helpers/Types/StoreServiceData";

export const DEPLOYMENT_STORE_STATE: StoreState = {
    LOADING: "DEPLOYMENT_LOADING",
    ERROR: "DEPLOYMENT_ERROR",
    SUCCESS: "DEPLOYMENT_SUCCESS"
}

export interface DeploymentLoading extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOYMENT_STORE_STATE.LOADING
}

export interface DeploymentError extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOYMENT_STORE_STATE.ERROR
}

export interface DeploymentSuccess extends StoreServiceData<DeploymentEntry>{
    type: typeof DEPLOYMENT_STORE_STATE.SUCCESS
}

export type DeploymentDispatchTypes = DeploymentSuccess | DeploymentLoading | DeploymentError;
