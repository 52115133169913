import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {RdmInfo} from "../../../api/x-control";

export const RDM_INFO_LOOKUP_STORE_STATE: StoreState = {
    LOADING: "RDM_INFO_LOOKUP_LOADING",
    ERROR: "RDM_INFO_LOOKUP_ERROR",
    SUCCESS: "RDM_INFO_LOOKUP_SUCCESS"
}

interface RdmInfoLookupLoading extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_LOOKUP_STORE_STATE.LOADING;
}
interface RdmInfoLookupError extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_LOOKUP_STORE_STATE.LOADING;
}
interface RdmInfoLookupSuccess extends StoreServiceData<RdmInfo>{
    type: typeof RDM_INFO_LOOKUP_STORE_STATE.LOADING;
}

export type RdmInfoLookupDispatchTypes = RdmInfoLookupLoading | RdmInfoLookupError | RdmInfoLookupSuccess;
