import React, {useEffect, useState} from "react";
import {RolloutsPageResponse, RolloutListEntry} from "../../../api/x-control";
import RolloutTableItem from "./RolloutListTableItem";

const RolloutListTableItemsWrapper = (props: RolloutsPageResponse) => {

    const [rolloutEntries, setRolloutEntries] = useState<RolloutListEntry[]>(props.entries);

    useEffect(() => {
        setRolloutEntries(props.entries)
        if(props.entries.length > 0) {
            setRolloutEntries(props.entries)
        }
    }, [props])

    return (
        <React.Fragment>
            {
                rolloutEntries.length > 0 &&
                rolloutEntries.map((el: RolloutListEntry, index: number) => {
                    return <RolloutTableItem {...el} key={index} />
                })
            }
            {
                rolloutEntries.length === 0 &&
                <div className="align-middle">
                    <h5 className="d-flex justify-content-center pt-2">
                        There are no Rollouts available at this time
                    </h5>
                </div>
            }
        </React.Fragment>
    )
}

export default RolloutListTableItemsWrapper;
