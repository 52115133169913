import React, {useRef} from "react";
import {DragItem, ItemTypes, MoveableSettingsGroupCards} from "../../DragAndDrop/DragAndDropUtils";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord} from "react-dnd";
import SettingsGroupDetailRow from "../SettingsGroup/SettingsGroupDetailRow";
import {DeploymentState, SettingsGroup} from "../../../api/x-control";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";

const DeploymentSettingsGroupCard = ({index, moveCard, id, droppedCard, ...props}: MoveableSettingsGroupCards) => {
    const settingGroup: SettingsGroup = {
        ...props,
        id
    }

    const deploymentStore = useSelector((state: RootStore) => state.deployment);

    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: ItemTypes.VERSION_GROUP_SETTING,
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if(!ref.current) {
                return;
            }
            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            const clientOffset = monitor.getClientOffset()

            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    })

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.VERSION_GROUP_SETTING, id, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        begin(monitor: DragSourceMonitor) {
            if(!droppedCard) return;
            droppedCard(false);
        },
        end(item, monitor: DragSourceMonitor) {
            if(!droppedCard) return;
            droppedCard(true);
        }
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <React.Fragment>
            {
                deploymentStore.data && deploymentStore.data.state !== DeploymentState.UnderConstruction &&
                <div className="disabled">
                    <SettingsGroupDetailRow settingsGroup={settingGroup} moveable={false} />
                </div>
            }
            {
                deploymentStore.data && deploymentStore.data.state === DeploymentState.UnderConstruction &&
                <div ref={ref} style={{opacity}} className="moveable">
                    <SettingsGroupDetailRow settingsGroup={settingGroup} moveable={true} />
                </div>
            }

        </React.Fragment>
    )
}

export default DeploymentSettingsGroupCard;
