import { Dispatch } from 'redux';
import { SETTINGS_GROUPS_STORE_STATE, SettingsGroupsDispatchTypes } from "./SettingsGroupsActionTypes";
import axios from 'axios';
import { fetchData } from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";

export const nullifySettingsGroups = () => async(dispatch: Dispatch<SettingsGroupsDispatchTypes>) => {
    dispatch({
        type: SETTINGS_GROUPS_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: []
    })
}

export const getSettingsGroups = () => async(dispatch: Dispatch<SettingsGroupsDispatchTypes>) => {
    try {
        await fetchData(
            SETTINGS_GROUPS_STORE_STATE,
            dispatch,
            () => ApiModel.getSettingsApi().getAllSettingsGroupNames()
        )
    }
    catch (e) {
        dispatch({
            type: SETTINGS_GROUPS_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}
