import React, {useEffect, useState} from "react";
import {SettingsGroupName} from "../../../../api/x-control";
import {toArray} from "../../../../utils/sortingUtils";
import PageHeader from "../../PageHeader";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from 'react-router-dom';
import ViewSettingsGroupDetails from "./ViewSettingsGroupDetails";

const SettingsGroupTable = (props: SettingsGroupName[]) => {

    const [settingsGroup, setSettingsGroups] = useState<SettingsGroupName[]>(toArray(props));

    useEffect(() => {
        setSettingsGroups((toArray(props)))
    }, [props])

    const history = useHistory();

    const createSettingGroup = () => {
        history.push({
            pathname: routeNames.settingsGroupCreate.path,
            state: {
                from: history.location.pathname
            }
        });
    }

    return (
        <div className="h-100">
            <PageHeader actionName={routeNames.settingsGroup.name} />
            <div className="table-background">
                <div className="table-header">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <h5 className="pt-4 pl-2 ml-3">
                                    {routeNames.settingsGroup.name}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-contents">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-md-1">
                                <p className="uppercasify table-keys">
                                    Name
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Settings Matches
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Meta Matches
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Settings
                                </p>
                            </div>
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                        {
                            settingsGroup.length > 0 && settingsGroup.map((el: SettingsGroupName) => {
                                return <ViewSettingsGroupDetails {...el} key={el.id} />
                            })
                        }
                        {
                            settingsGroup.length === 0 &&
                            <div className="align-middle">
                                <h5 className="d-flex justify-content-center pt-2">
                                    There are no settings groups
                                </h5>
                                <h5 className="d-flex justify-content-center pt-2">
                                    Click "Create Settings Group" below to create a group.
                                </h5>
                            </div>
                        }
                    </div>
                </div>
                <div className="float-right">
                    <a onClick={createSettingGroup} className="interactive-text pr-3">
                        <b>+</b> <span className="pl-2">Create Settings Group</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SettingsGroupTable;
