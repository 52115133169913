import React, {useEffect, useState} from "react";
import {RdmDeviceInfo} from "../../../../api/x-control";
import {nanoid} from "nanoid";

const DeviceMeta = (props: RdmDeviceInfo) => {

    const [metaKeys, setMetaKeys] = useState<string[]>([]);

    useEffect(() => {
        setMetaKeys(Object.keys(props.meta));
    }, [props])

    return (
        <React.Fragment>
            <p className="table-values align-self-center fadeIn w-100">
                {
                    metaKeys.length === 0 &&
                    <span className="ml-0 mr-0 row mt-1 mb-1">
                        No Device Meta Exists
                    </span>
                }
                {
                    metaKeys.length > 0 && metaKeys.map((el: string) => {
                        return (
                            <React.Fragment key={nanoid()}>
                                <span className="ml-0 mr-0 row mt-1 mb-1">
                                    <span>
                                        <b>{el}: </b> {props.meta[el]}
                                    </span>
                                </span>
                            </React.Fragment>
                        )
                    })
                }
            </p>
        </React.Fragment>
    )
}

export default DeviceMeta;
