import axios from 'axios';

export interface IConfig {
    xcApiBaseUrl: string;
    brandName: string;
    favIcon: string;
    logo: string;

    auth0config: IAuth0Config;
}

export interface IAuth0Config {
    domain: string;
    clientID: string;
    Algorithm: string;
    redirectUri: string;
    logoutRedirectUri: string;
    audience: string;
    responseType: string;
    scope: string;
}

let config: IConfig;

function formatConfig(cfg: IConfig): IConfig {
    cfg.auth0config.redirectUri = window.location.origin + cfg.auth0config.redirectUri;
    cfg.auth0config.logoutRedirectUri = window.location.origin + cfg.auth0config.logoutRedirectUri;

    return cfg;
}
/** app will not start until config has been gotten */
export async function fetchConfig(): Promise<IConfig> {
    const response = await axios.get('/config.json');

    config = formatConfig(response.data);

    return response.data;
}

export default (): IConfig => {
    return config;
};
