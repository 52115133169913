import {RdmInfo} from "../../../api/x-control";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {RootStore} from "../../../store/Store";

export const useDM = () => {
    const dm: RdmInfo = {
        id: uuidv4(),
        name: "",
        siteName: "",
        hostname: "",
        namespace: "",
        apiLevel: 1,
        source: "",
        username: "",
        password: "",
        devices: []
    }

    const rdmInfoStore = useSelector((state: RootStore) => state.rdmInfo);

    const rdm = rdmInfoStore.data;

    if(rdm) {
        return rdm;
    }

    return dm
}
