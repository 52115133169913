import React, {useEffect, useState} from "react";
import {DeploymentEntry, DeploymentState} from "../../../../api/x-control";
import moment, {Moment} from "moment";
import {ddmmyyyyRegex} from "../../../../utils/inputUtils";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import Select from "react-select";
import {DDProps} from "../../../../utils/sortingUtils";
import {SingleDatePicker} from "react-dates";

const DeploymentDelayExecutionDate = (props: DeploymentEntry) => {
    const dispatch = useDispatch();

    const [hourDropDownProps, setHourDropDownProps] = useState<DDProps[]>([])
    const [selectedHour, setSelectedHour] = useState<DDProps>({value: 0, label: ""})
    const minuteOptions = ["00", "10", "20", "30", "40", "50"];
    const [minuteDropDownProps, setMinuteDropDownProps] = useState<DDProps[]>([])
    const [selectedMinute, setSelectedMinute] = useState<DDProps>({value: 0, label: ""})

    //Date picker
    const [date, setDate] = useState<Moment | undefined>(props.delayExecution ? moment.unix(props.delayExecution) : undefined);
    const [focusedDate, setFocusedDate] = useState(null);

    //Generate Dropdown props upon mounting
    useEffect(() => {
        generateMinuteOptions();
        generateHourOptions();
    }, []);

    const generateMinuteOptions = () => {
        const minuteDropdownOptions: DDProps[] = [];

        for (const minute of minuteOptions) {
            minuteDropdownOptions.push({
                value: +minute,
                label: minute
            })
        }

        setMinuteDropDownProps(minuteDropdownOptions);
    };

    const generateHourOptions = () => {
            const hourDropdownOptions: DDProps[] = [];

            for (let i = 0; i < 24; ++i) {
                hourDropdownOptions.push({
                    value: i,        //00, 01 -> 08, 09  //10, 11 -> 23, 24
                    label: i < 10 ? `0${i.toString()}` : i.toString()
                })
            }
            setHourDropDownProps(hourDropdownOptions);
    };

    //Don't want to touch this effect unless all objects are populated.
    useEffect(() => {
        if (!minuteDropDownProps || minuteDropDownProps.length === 0) return;
        if (!hourDropDownProps || hourDropDownProps.length === 0) return;

        if (!props.delayExecution) return;
        const minute = moment.unix(props.delayExecution).get("minute");
        const minuteIndex = minuteDropDownProps.findIndex((el: DDProps) => +el.value === minute);
        const minuteSelection = minuteIndex < 0 ? minuteDropDownProps[0] :  minuteDropDownProps[minuteIndex];
        //We set the selected option based on if an index was found. If there was no index found, we set it as the first option as a fallback
        setSelectedMinute(minuteSelection);

        const hour = moment.unix(props.delayExecution).get('hour');
        const hourIndex = hourDropDownProps.findIndex((el: DDProps) => +el.value === hour);
        const hourSelection = hourIndex < 0 ? hourDropDownProps[0] : hourDropDownProps[hourIndex]
        //We set the selected option based on if an index was found. If there was no index found, we set it as the first option as a fallback
        setSelectedHour(hourSelection);
    }, [props])

    const onHourChange = (p: DDProps) => {
        if(!date) return;
        setSelectedHour(p)

        const minute = +selectedMinute.value;
        const execDate = moment.unix(date.unix()).startOf("day").add(+p.value, 'hour').add(minute, 'minute').unix();
        updateDelayExecutionTime(execDate);
    }

    const onMinuteChange = (p: DDProps) => {
        if(!date) return;
        setSelectedMinute(p);

        const hour = +selectedHour.value;
        const execDate = moment.unix(date.unix()).startOf("day").add(hour, 'hour').add(+p.value, 'minute').unix();
        updateDelayExecutionTime(execDate);
    }

    //Date picker
    const onDateChanged = (changedDate: Moment) => {
        //We force the date to the start of the day
        //Minutes will be added on afterwards
        const alteredDate = moment(changedDate).startOf("day");
        setDate(alteredDate)

        const execDate = moment.unix(alteredDate.unix()).startOf("day").add(+selectedHour.value, 'hour').add(+selectedMinute.value, 'minute').unix();
        updateDelayExecutionTime(execDate);
    };

    const updateDelayExecutionTime = (time: number) => {
        const entry: DeploymentEntry = {
            ...props,
            delayExecution: time
        }

        dispatch(setDeployment(entry));
    }

    const onFocusChange = ({focused}: any) => {
        setFocusedDate(focused);
    };

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col">
                    <p className="details">
                        Date
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col pl-4">
                    <SingleDatePicker
                        id="month-picker"
                        date={date}
                        onDateChange={onDateChanged}
                        focused={focusedDate}
                        onFocusChange={onFocusChange}
                        isOutsideRange={() => false}
                        displayFormat={() => "DD/MM/YYYY"}
                        numberOfMonths={1}
                        readOnly={true}
                        appendToBody={true}
                    />
                </div>
            </div>
            {
                date ? (
                    <React.Fragment>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="details">
                                    Hour
                                </p>
                            </div>
                            <div className="col">
                                <p className="details">
                                    Minute
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Select
                                    className="search-bar-sm pl-2"
                                    classNamePrefix="select-options"
                                    options={hourDropDownProps}
                                    onChange={onHourChange}
                                    isSearchable={false}
                                    value={selectedHour}
                                    noOptionsMessage={() => "No Hour Options Found"}
                                    maxMenuHeight={150}
                                    isDisabled={props.state !== DeploymentState.UnderConstruction}
                                />
                            </div>
                            <div className="col">
                                <Select
                                    className="search-bar-sm pl-2"
                                    classNamePrefix="select-options"
                                    options={minuteDropDownProps}
                                    onChange={onMinuteChange}
                                    isSearchable={false}
                                    value={selectedMinute}
                                    noOptionsMessage={() => "No Minute Options Found"}
                                    maxMenuHeight={150}
                                    isDisabled={props.state !== DeploymentState.UnderConstruction}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p className="error-text pl-2">Pick a valid date</p>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}

export default DeploymentDelayExecutionDate;
