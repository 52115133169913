import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";

export const ROLLOUT_REPORT_STORE_STATE: StoreState = {
    LOADING: "ROLLOUT_REPORT_LOADING",
    ERROR: "ROLLOUT_REPORT_ERROR",
    SUCCESS: "ROLLOUT_REPORT_SUCCESS"
}

export interface RolloutReportLoading extends StoreServiceData<string>{
    type: typeof ROLLOUT_REPORT_STORE_STATE.LOADING;
}

export interface RolloutReportError extends StoreServiceData<string>{
    type: typeof ROLLOUT_REPORT_STORE_STATE.ERROR;
}

export interface RolloutReportSuccess extends StoreServiceData<string>{
    type: typeof ROLLOUT_REPORT_STORE_STATE.SUCCESS;
}

export type RolloutReportDispatchTypes = RolloutReportSuccess | RolloutReportError | RolloutReportLoading;
