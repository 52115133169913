import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getSettingsGroups} from "../../../store/SettingsGroups/actions/SettingsGroupsActions";
import WithServiceState from "../../Helpers/HOCs/ServiceState/WithServiceState";

import {routeNames} from "../../Navigation/routeNames";
import {useModal} from "../../Modal/hooks/useModal";
import Modal, {ModalSize, XControlModalProps} from "../../Modal/components/Modal";
import ConfirmationModal from "../../Modal/components/ConfirmationModal";
import {RootStore} from "../../../store/Store";
import SettingsGroupOptions from "./SettingsGroupOptions";

const WrappedSettingsGroup = WithServiceState(SettingsGroupOptions);

const SettingsGroupContainer = () => {

    /** Settings Group */
    const dispatch = useDispatch();
    const history = useHistory();
    const settingsGroupState = useSelector((state: RootStore) => state.settingsGroups);

    /** Modal */
    const { isShown, toggle } = useModal();
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        confirmText: "",
        cancelText: "",
        modalSize: ModalSize.small
    });

    useEffect(() => {
        fetchSettingsGroups();
    }, [])

    const fetchSettingsGroups = () => {
        dispatch(getSettingsGroups());
    }

    const createSetting = () => {
        setModalProps({
            headerText: "Create Setting",
            messages: [`There are unsaved changes on this page. Navigating away from this page will discard any changes. Are you sure you want to continue`],
            onConfirm: () => { createSettingGroupPage() },
            confirmText: "Yes",
            cancelText: "No",
            modalSize: ModalSize.small
        })

        toggle();
    }

    const createSettingGroupPage = () => {
        //Push where we are navigating to to state so we can navigate back here when done!
        history.push({
            pathname: routeNames.settingsGroupCreate.path,
            state: {
                from: window.location.pathname
            }
        })
    }

    return (
        <div className="col-md-2 pl-0">
            <p className="column-header">
                Available Settings Groups
            </p>
            <div className="table-contents mt-0">
                <div className="table-contents-key">
                    <div className="row">
                        <div className="col">
                            <p className="uppercasify table-keys">
                                Settings Groups
                            </p>
                        </div>
                    </div>
                    <WrappedSettingsGroup
                        {...settingsGroupState}
                    />
                </div>
            </div>
            <div className="float-right">
                <a onClick={createSetting} className="interactive-text pr-3">
                    <b>+</b> <span className="pl-2">Create Setting</span>
                </a>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </div>
    )
}

export default SettingsGroupContainer;
