import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";

export const MODAL_STORE_STATE: StoreState = {
    LOADING: "MODAL_STORE_LOADING",
    ERROR: "MODAL_STORE_ERROR",
    SUCCESS: "MODAL_STORE_SUCCESS"
}

interface ModalLoading extends StoreServiceData<boolean>{
    type: typeof MODAL_STORE_STATE.LOADING
}
interface ModalError extends StoreServiceData<boolean>{
    type: typeof MODAL_STORE_STATE.ERROR
}
interface ModalSuccess extends StoreServiceData<boolean>{
    type: typeof MODAL_STORE_STATE.SUCCESS
}

export type ModalDispatchTypes = ModalLoading | ModalError | ModalSuccess;
