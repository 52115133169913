import React, {useEffect, useState} from "react";
import {DeploymentEntry, DeploymentState, ScheduleDay} from "../../../../api/x-control";
import {nanoid} from "nanoid";
import {useDispatch} from "react-redux";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import ReactTooltip from "react-tooltip";

const DeploymentScheduleDay = (props: DayToggleProps) => {
    const tooltipId = nanoid();
    const [toggled, setToggled] = useState<boolean>(false);
    const dispatch = useDispatch();

    const toolTipContent =
        toggled ?
            `${props.deployment.name} scheduled to run on ${props.day}` :
            `${props.deployment.name} scheduled to not run on ${props.day}`;

    const toolTipClass = toggled ? 'tooltip-success-right' : 'tooltip-error-right';

    useEffect(() => {
        //Check if the day exists in the array.
        const index = getIndexOfDayInArray();

        if(index < 0) {
            setToggled(false);
            return;
        }

        setToggled(true);

    }, [props])


    const toggleDay = (value: boolean) => {
        const {deployment, day} = props;
        //Null check. Should never hit.
        if (!deployment.schedule) return;

        const schedule = deployment.schedule;

        setToggled(value = !value);

        if(value) {
            switch (day) {
                case ScheduleDay.Monday:
                    schedule.days = [...schedule.days, ScheduleDay.Monday]
                    break;
                case ScheduleDay.Tuesday:
                    schedule.days = [...schedule.days, ScheduleDay.Tuesday]
                    break;
                case ScheduleDay.Wednesday:
                    schedule.days = [...schedule.days, ScheduleDay.Wednesday]
                    break;
                case ScheduleDay.Thursday:
                    schedule.days = [...schedule.days, ScheduleDay.Thursday]
                    break;
                case ScheduleDay.Friday:
                    schedule.days = [...schedule.days, ScheduleDay.Friday]
                    break;
                case ScheduleDay.Saturday:
                    schedule.days = [...schedule.days, ScheduleDay.Saturday]
                    break;
                case ScheduleDay.Sunday:
                    schedule.days = [...schedule.days, ScheduleDay.Sunday]
                    break;
            }

            const entry: DeploymentEntry = {
                ...deployment,
                schedule
            }

            dispatch(setDeployment(entry))
            return;
        }

        //If the value exists, we remove it here!
        const index = getIndexOfDayInArray();
        schedule.days.splice(index, 1);

        const entry: DeploymentEntry = {
            ...deployment,
            schedule
        }
        dispatch(setDeployment(entry))
    }

    const getIndexOfDayInArray = () => {
        const {day, deployment} = props;
        //Needed as type undefined exists.
        if (!deployment.schedule) return -1;

        //Check if the day exists in the array.
        return deployment.schedule.days.findIndex((el: ScheduleDay) => el === day);
    }

    return (
        <div className="row mt-1">
            <div className="col-sm-7">
                <p className="details">
                    { props.day }
                </p>
            </div>
            <div className="col-sm-4 pr-1">
                <div
                    data-tip
                    data-for={tooltipId}
                    className="float-right"
                >
                    <Toggle
                        checked={toggled}
                        onChange={() => toggleDay(toggled)}
                        size={ToggleSize.Small}
                        disabled={props.deployment.state !== DeploymentState.UnderConstruction}
                    />
                </div>
                <ReactTooltip
                    place="right"
                    id={tooltipId}
                    className={toolTipClass}
                    effect="solid"
                >
                    <span>{toolTipContent}</span>
                </ReactTooltip>
            </div>
        </div>
    )
}

export default DeploymentScheduleDay;

export interface DayToggleProps {
    day: string;
    deployment: DeploymentEntry;
}
