import React, {useEffect} from "react";
import {useModal} from "../../../Modal/hooks/useModal";
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getDataManager, nullifyDataManagerStore} from "../../../../store/RdmInfo/actions/RdmInfoActions";
import Modal, {ModalSize} from "../../../Modal/components/Modal";
import EditDataManagerTable from "../EditDataManagerTable";
import WithServiceState from "../../../Helpers/HOCs/ServiceState/WithServiceState";

const WrappedDataManagerTable = WithServiceState(EditDataManagerTable);

const EditSiteManagementDataManagerModal = () => {
    const {isShown, toggle} = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    const params: any = useParams();

    const rdmInfoStore = useSelector((state: RootStore) => state.rdmInfo);
    const modalStore = useSelector((state: RootStore) => state.modal);
    // @ts-ignore
    useEffect(() => {
        const siteId = params.siteId;
        const dmId = params.dmId;
        toggle();
        dispatch(getDataManager(dmId, siteId));

        return () => dispatch(nullifyDataManagerStore())
    }, [])

    const closeModal = () => {
        const routerState = history.location.state as any;
        history.replace(routerState.from);
    }

    useEffect(() => {
        const toggled = modalStore.data;
        
        if(toggled) {
            closeModal();
        }

    }, [modalStore.data, modalStore.loading])

    return (
        <Modal
            isShown={isShown}
            hide={() => closeModal()}
            headerText="DM Info"
            modalSize={ModalSize.large}
            modalContent={
                <WrappedDataManagerTable {...rdmInfoStore}/>
            }
        />
    )
}

export default EditSiteManagementDataManagerModal;
