import React, {useEffect, useState} from "react";
import {RdmDeviceInfo, RdmInfo} from "../../../../api/x-control";
import {nanoid} from "nanoid";
import DataManagerDeviceTableItem from "./DataManagerDeviceTableItem";
import {useDispatch, useSelector} from "react-redux";
import {toggleModal} from "../../../../store/Modal/actions/ModalActions";
import AddDeviceAction from "./AddDeviceAction";
import {useDM} from "../../../Helpers/Hooks/useDM";
import Modal, {ModalSize, XControlModalProps} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import ConfirmationModal from "../../../Modal/components/ConfirmationModal";
import {RootStore} from "../../../../store/Store";
import {saveDataManagerToSite} from "../../../../store/RdmInfoLookup/actions/RdmInfoLookupActions";
import {fetchDataManagersForSite} from "../../../../store/RdmInfoList/actions/RdmInfoListActions";
import {useHistory, useParams } from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {getSiteNamesFromService} from "../../../../store/RdmSiteNameList/actions/RdmSiteListActions";

const DataManagerDeviceTable = (props: RdmInfo) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const dataManager = useDM();
    const params: any = useParams()

    const dmStore = useSelector((state: RootStore) => state.rdmInfoLookup);

    /** Modal */
    const [modalProps, setModalProps] = useState<XControlModalProps>( {
        headerText: "",
        messages: [],
        onConfirm: () => null,
        cancelText: "",
        confirmText: "",
        modalSize: ModalSize.small
    });
    const { isShown, toggle } = useModal();

    // @ts-ignore
    useEffect(() => {
        return () => dispatch(toggleModal(false))
    }, [])

    const cancel = () => {
        dispatch(toggleModal(true))
    }

    const saveDM = () => {
        let errors: string[] = [];
        let isValid = true;

        if(dataManager.name.length === 0) {
            isValid = false;
            errors.push("DM Name cannot be blank")
        }
        if(dataManager.siteName.length === 0) {
            isValid = false;
            errors.push("Site Name cannot be blank")
        }
        if(dataManager.hostname.length === 0) {
            isValid = false;
            errors.push("Hostname cannot be blank")
        }
        if(dataManager.namespace.length === 0) {
            isValid = false;
            errors.push("Namespace cannot be blank")
        }
        if(!dataManager.source) {
            isValid = false;
            errors.push("Source cannot be blank")
        }

        //If any of the above aren't valid. We tell the user what issues have occurred
        if(!isValid) {
            setModalProps({
                headerText: "Error",
                messages: errors,
                onConfirm: () => null,
                cancelText: "Dismiss",
                confirmText: "",
                modalSize: ModalSize.small
            })

            toggle();
            return;
        }

        //Otherwise, we save DM to the service.
        dispatch(saveDataManagerToSite(dataManager, successfulSaveDMCallback, failedSaveDMCallback))
    }

    const successfulSaveDMCallback = () => {
        dispatch(toggleModal(true))

        //If the site Id exists in the url parameters, direct back to that list.
        if (params.siteId) {
            dispatch(fetchDataManagersForSite(params.siteId))
            return;
        }

        //Otherwise, direct back to main site management screen
        dispatch(getSiteNamesFromService());
        history.replace(routeNames.siteManagementSite.path);
    }

    const failedSaveDMCallback = () => {
        if(!dmStore.error) return;

        setModalProps({
            headerText: "Error",
            messages: [dmStore.error],
            onConfirm: () => null,
            cancelText: "Dismiss",
            confirmText: "",
            modalSize: ModalSize.small
        })

        toggle();
    }

    return (
        <React.Fragment>
            <div className="large-modal-table-contents dm">
                <div className="table-contents-key">
                    <div className="row">
                        <div className="col-md-3">
                            <p className="uppercasify table-keys">
                                Device Name
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="uppercasify table-keys">
                                Device Meta
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p className="uppercasify table-keys">
                                Controls
                            </p>
                        </div>
                    </div>
                    {
                        !props.devices &&
                        <div className="align-middle">
                            <h5 className="d-flex justify-content-center pt-2">
                                There are no devices on this DM. Click 'Add Devices' to start
                            </h5>
                        </div>
                    }
                    {
                        props.devices && props.devices.length === 0 &&
                        <div className="align-middle">
                            <h5 className="d-flex justify-content-center pt-2">
                                There are no devices on this DM. Click 'Add Devices' to start
                            </h5>
                        </div>
                    }
                    {
                        props.devices && props.devices.length > 0 && props.devices.map((el: RdmDeviceInfo) => {
                            return <DataManagerDeviceTableItem {...el} key={nanoid()} />
                        })
                    }
                </div>
            </div>
            <div className="float-right mt-2 pt-2">
                <AddDeviceAction />
                <a onClick={cancel} className="interactive-text pr-3">
                    <span className="pl-2">Cancel</span>
                </a>
                <span className="red-text pr-2">
                    |
                </span>
                <a onClick={saveDM} className="interactive-text pr-3">
                    <span className="pl-2">Save</span>
                </a>
            </div>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={modalProps.headerText}
                modalSize={modalProps.modalSize}
                modalContent={
                    <ConfirmationModal
                        onConfirm={modalProps.onConfirm}
                        onCancel={() => toggle()}
                        messages={modalProps.messages}
                        confirmText={modalProps.confirmText}
                        cancelText={modalProps.cancelText}
                    />
                }
            />
        </React.Fragment>
    )
}

export default DataManagerDeviceTable;
