import React from "react";
import {SettingMatch} from "../../../api/x-control";

const SettingsMatchContent = ({name, value, inverse, comparison}: SettingMatch) => {

    const inverted = inverse ? "green-dot" : "red-dot";

    return (
        <div className="d-flex pt-2 pb-3">
            <p className="table-values col-sm-4">
                <b>{name}</b>: {value}
            </p>
            <div className="table-values col-sm-4">
                <p className="float-left">Inverse?:</p>  <span className={inverted} />
            </div>
            <div className="table-values col-sm-4">
                <p className="float-left">
                    <b>Comparision</b>: {comparison?.split(/(?=[A-Z])+/g).join(" ")}
                </p>
            </div>
        </div>
    )
}

export default SettingsMatchContent;
