import React from "react";
import {ApproveUser, DeploymentEntry} from "../../../../api/x-control";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {nanoid} from "nanoid";

const DeploymentApprovalList = (props: DeploymentEntry) => {
    const tooltipId = nanoid();
    return (
        <React.Fragment>
            {
                props.approvalUsers.length === 0 &&
                    <p className="details">
                        There are no approvals
                    </p>
            }
            {
                props.approvalUsers.length > 0 && props.approvalUsers.map((el: ApproveUser, index: number) => {
                    return (
                        <div className="row mt-1" key={index}>
                            <div className="col-sm-9">
                                <p className="details overflow-text">
                                    {el.name}
                                </p>
                            </div>
                            <div className="col-sm-2">
                                <a data-tip data-for={tooltipId} className="green-dot-lg interactive"/>
                                <ReactTooltip
                                    place="right"
                                    id={tooltipId}
                                    className="tooltip-success-right"
                                    effect="solid"
                                >
                                    <span>
                                        {el.name} approved deployment on {moment.unix(el.date).format('LLL')}
                                    </span>
                                </ReactTooltip>
                            </div>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default DeploymentApprovalList;
