import {ServiceActionTypes, StoreServiceData} from "../../Helpers/Types/StoreServiceData";
import {DEPLOY_STORE_STATE} from "../actions/DeployActionTypes";
import {DeploymentEntry} from "../../../api/x-control";

const defaultState: StoreServiceData<DeploymentEntry> = {
    loading: false,
    error: null,
    data: null
}

const deployReducer = (state: StoreServiceData<DeploymentEntry> = defaultState, action: ServiceActionTypes<DeploymentEntry>) => {
    switch (action.type) {
        case DEPLOY_STORE_STATE.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case DEPLOY_STORE_STATE.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            }
        case DEPLOY_STORE_STATE.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            }
        default:
            return state;
    }
}

export default deployReducer;
