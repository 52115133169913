import React from "react";
import {RdmInfo} from "../../../api/x-control";
import DataManagerDetails from "./DataManagerDetails/DataManagerDetails";
import DataManagerDeviceTable from "./DataManagerDevices/DataManagerDeviceTable";

const EditDataManagerTable = (props: RdmInfo) => {

    return (
        <React.Fragment>
            <div className="h-100">
                <div className="row large-modal-table-contents-wrapper dm">
                    <div className="col-md-3 pl-1 pr-0">
                        <p className="column-header">
                            DM Details
                        </p>
                        <div className="large-modal-table-contents dm">
                            <DataManagerDetails {...props} />
                        </div>
                    </div>
                    <div className="col-md-9 pl-0 pr-1">
                        <p className="column-header">
                            DM Devices
                        </p>
                        <DataManagerDeviceTable {...props} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditDataManagerTable;
