import React, {useEffect, useState} from "react";
import {RollbackEntry} from "../../../api/x-control";
import {toArray} from "../../../utils/sortingUtils";
import {routeNames} from "../../Navigation/routeNames";
import RollbackListTableItem from "./RollbackListTableItem";

const RollbackListContainer = (props: RollbackEntry[]) => {
    const [rollbackEntries, setRollbackEntries] = useState<RollbackEntry[]>(toArray(props))

    useEffect(() => {
        setRollbackEntries(toArray(props))
    }, [props])

    return (
        <div className="h-100">
            <div className="row large-modal-table-contents-wrapper">
                <div className="large-modal-table-contents w-100 mt-3">
                    <div className="table-contents-key">
                        <div className="row">
                            <div className="col-md-2">
                                <p className="uppercasify table-keys">
                                    Rollback ID
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="uppercasify table-keys">
                                    Date Of Rollback
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className="uppercasify table-keys">
                                    Rollback State
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p className="uppercasify table-keys">
                                    Controls
                                </p>
                            </div>
                        </div>
                    </div>
                    {
                        rollbackEntries.length === 0 &&
                        <div className="align-middle">
                            <h5 className="d-flex justify-content-center pt-2">
                                There are no rollbacks for this rollout
                            </h5>
                        </div>
                    }
                    {
                        rollbackEntries.length > 0 && rollbackEntries.map((el: RollbackEntry) => {
                            return <RollbackListTableItem {...el} key={el.id} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default RollbackListContainer;
