import {StoreServiceData, StoreState} from "../../Helpers/Types/StoreServiceData";
import {RollbackEntry} from "../../../api/x-control";

export const ROLLBACK_STORE_STATE: StoreState = {
    LOADING: "ROLLBACK_LOADING",
    ERROR: "ROLLBACK_ERROR",
    SUCCESS: "ROLLBACK_SUCCESS"
}

interface RollbackLoading extends StoreServiceData<RollbackEntry>{
    type: typeof ROLLBACK_STORE_STATE.LOADING;
}
interface RollbackError extends StoreServiceData<RollbackEntry>{
    type: typeof ROLLBACK_STORE_STATE.ERROR;
}
interface RollbackSuccess extends StoreServiceData<RollbackEntry>{
    type: typeof ROLLBACK_STORE_STATE.SUCCESS;
}

export type RollbackDispatchTypes = RollbackLoading | RollbackSuccess | RollbackError;
