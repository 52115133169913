import React from "react";

const Footer = () => {
    return (
        <div className="toolbar-footer drop-shadow-negative-y">
            <div className="w-100">
                <p className="pr-4 float-right small-text">

                </p>
            </div>
        </div>
    )
}

export default Footer;

