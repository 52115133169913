import React from "react";
import {DeviceSetting} from "../../../api/x-control";

const DeviceSettingContent = ({name, value}: DeviceSetting) => {

    return (
        <p className="table-values align-self-center pt-2 pb-3">
            <b>{name}</b>: {value}
        </p>
    )
}

export default DeviceSettingContent;
