import {saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import {DeploymentsPageRequest} from "../../../api/x-control";
import ApiModel from "../../ApiModel/ApiModel";
import {Dispatch} from 'redux';
import {DEPLOYMENT_LIST_STORE_STATE, DeploymentListDispatchTypes} from "./DeploymentListActionTypes";

export const getPagedDeployments = (request: DeploymentsPageRequest) => async (dispatch: Dispatch<DeploymentListDispatchTypes>) => {
    try {
        await saveData(
            DEPLOYMENT_LIST_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().getDeploymentsPaged(request)
        )
    }

    catch (e) {
        dispatch({
            type: DEPLOYMENT_LIST_STORE_STATE.ERROR,
            error: e,
            loading: false
        })
    }
}

export const nullifyPagedDeployments = () => async (dispatch: Dispatch<DeploymentListDispatchTypes>) => {
    dispatch({
        type: DEPLOYMENT_LIST_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    })
}
