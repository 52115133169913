import React, {useState} from "react";
import {DeploymentEntry, DeploymentState} from "../../../../api/x-control";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import {useDispatch} from "react-redux";
import {setDeployment} from "../../../../store/Deployment/actions/DeploymentActions";
import ReactTooltip from "react-tooltip";
import {nanoid} from "nanoid";

const DeploymentReusableToggle = (props: DeploymentEntry) => {
    const tooltipId = nanoid();

    const [toggled, setToggled] = useState<boolean>(props.reusable);
    const dispatch = useDispatch();

    const toolTipContent =
        props.reusable ?
            `${props.name} can be reused in creating future deployments` :
            `${props.name} cannot be reused in creating future deployments`

    const toggleReusable = (value: boolean) => {
        setToggled(value = !value);

        const entry: DeploymentEntry = {
            ...props,
            reusable: value
        }

        dispatch(setDeployment(entry));
    }

    return (
        <React.Fragment>
            <div className="col-sm-7">
                <p className="details">
                    Reusable?
                </p>
            </div>
            <div className="col-sm-4 pr-1">
                <div
                    data-tip
                    data-for={tooltipId}
                    className="float-right"
                >
                    <Toggle
                        checked={toggled}
                        onChange={() => toggleReusable(toggled)}
                        size={ToggleSize.Small}
                        disabled={props.state !== DeploymentState.UnderConstruction}
                    />
                </div>
                <ReactTooltip
                    place="right"
                    id={tooltipId}
                    type="dark"
                    effect="solid"
                >
                    <span>{toolTipContent}</span>
                </ReactTooltip>
            </div>
        </React.Fragment>
    )
}

export default DeploymentReusableToggle;
