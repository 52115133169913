import React, {useEffect, useState} from "react";
import {DDProps} from "../../../utils/sortingUtils";
import {GenericTypeDropDownProps} from "../dropdownUtils";
import {Enum} from "../../../utils/enumUtils";
import Select from "react-select";

const ApiLevelDropdown = (props: GenericTypeDropDownProps<Enum<any>>) => {
    const [genericOptions, setGenericOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: 0, label: ""});

    useEffect(() => {
        const enumOptions = Object.values(props.enumOptions);
        const options: DDProps[] = [];

        for (const option of enumOptions) {
            //Checks for value that is number
            if(!isNaN(+option)) {
                // Pushes value as the number, get the index of the option in the enum as a string
                options.push({
                    value: option,
                    label: Object.values(props.enumOptions)[+option - 1].toString()
                })
            }
        }

        setGenericOptions(options);

        const index = options.findIndex((el: DDProps) => el.value === props.id);

        if(index < 0) {
            setSelectedOption(options[0])
            props.changeOption(options[0].value)
            return;
        }

        setSelectedOption(options[index])
        props.changeOption(options[index].value)

    }, [])

    const handleGenericOptionChange = (p: DDProps) => {
        props.changeOption(p.value);

        const index = genericOptions.findIndex((el: DDProps) => el.value === p.value);

        if(index < 0) {
            setSelectedOption(genericOptions[0])
            return;
        }

        setSelectedOption(genericOptions[index])
    }

    return (
        <React.Fragment>
            <Select
                className="search-bar-lg"
                classNamePrefix="select-options"
                options={genericOptions}
                onChange={handleGenericOptionChange}
                isSearchable={false}
                value={selectedOption}
                noOptionsMessage={() => "No Options Found"}
            />
        </React.Fragment>
    )
}

export default ApiLevelDropdown;
