import React, {useEffect} from "react";
import Modal, {ModalSize} from "../../../Modal/components/Modal";
import {useModal} from "../../../Modal/hooks/useModal";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {createNewSettingsGroup, nullifySettingGroup} from "../../../../store/SettingGroup/actions/SettingGroupActions";
import {RootStore} from "../../../../store/Store";
import EditSettingsGroupContainer from "../EditSettingsGroup/EditSettingsGroupContainer";
import WithServiceState from "../../../Helpers/HOCs/ServiceState/WithServiceState";

const WrappedSettingsGroupContainer = WithServiceState(EditSettingsGroupContainer);

const CreateSettingsGroupModal = () => {
    const {isShown, toggle} = useModal();
    const history = useHistory();
    const dispatch = useDispatch();

    const settingsGroupStoreState = useSelector((state: RootStore) => state.settingGroup);

    useEffect(() => {
        dispatch(nullifySettingGroup());
        toggle();
        fetchData();
    }, [])

    const closeModal = () => {
        const routerState = history.location.state as any;
        history.replace(routerState.from);
    }

    const fetchData = () => {
        dispatch(createNewSettingsGroup());
    }

    return (
        <Modal
            isShown={isShown}
            hide={() => closeModal()}
            headerText="CREATE SETTINGS GROUP"
            modalSize={ModalSize.large}
            modalContent={
                <WrappedSettingsGroupContainer
                    {...settingsGroupStoreState}
                />
            }
        />
    );
}

export default CreateSettingsGroupModal;
