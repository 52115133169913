import {Dispatch} from "redux";
import {DeployDispatchTypes, DEPLOY_STORE_STATE} from "./DeployActionTypes";
import {saveData} from "../../Helpers/Utils/StoreFetchWrappers";
import ApiModel from "../../ApiModel/ApiModel";

export const deployDeploymentById = (id: number, successCallback: () => void, errorCallback: () => void) => async (dispatch: Dispatch<DeployDispatchTypes>) => {
    try {
        const success = await saveData(
            DEPLOY_STORE_STATE,
            dispatch,
            () => ApiModel.getDeploymentsApi().deploy(id)
        )
        if(success) {
            successCallback();
        }
        else {
            errorCallback();
        }
    }
    catch (e) {
        dispatch({
            type: DEPLOY_STORE_STATE.ERROR,
            loading: false,
            error: e
        })
    }
}
